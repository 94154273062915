import {createSlice} from "@reduxjs/toolkit";

export const massScheduleSlice = createSlice({
    name: "massScheduleList",
    initialState: {
        value: [],
    },
    reducers: {
        setMassList: (state, action) => {
            state.value = action.payload;
            // console.log("STORE", state.value);
        },
    },
});

export const {setMassScheduleList} = massScheduleSlice.actions;

export default massScheduleSlice.reducer;


