import React, {useEffect, useState} from 'react';
import SectionHeading from "./SectionHeading";
import {Images} from "../constants/Images";
import {Modal} from "react-bootstrap";
import {toastSuccess} from "./toastSuccess";
import {toastError} from "./toastError";
import {ACCESS_TOKEN_KEY} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {
    setCoverPhotoList,
    addEmptyCoverPhoto,
    removeCoverPhotoByIndex,
    updateCoverPhotoAtIndex
} from "../store/coverPhotoSlice";

const AdminDeveloper = () => {

    const dispatch = useDispatch();
    const coverFromStore = useSelector((state) => state.coverPhotoList.value);
    const [loader, setLoader] = useState(false)
    const [allUsers, setAllUsers] = useState()
    const [parishInfo, setParishInfo] = useState()
    const [modalShown, setModalShown] = useState(false)
    const [user, setUser] = useState(null)
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getAllUsers()
        getCoverPhotos()
        getParishInfo()
    }, []);

    function handlePhotoEdit() {
        setLoader(true)
        const data = {
            _id: parishInfo._id,
            cover_photos: [...coverFromStore].sort((a, b) => {
                const orderA = parseInt(a.order, 10);
                const orderB = parseInt(b.order, 10);

                return orderA - orderB
            })
        }

        fetch(`${process.env.REACT_APP_API_BASE_URL}/editCoverPhotos`, {
            method: "POST",
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            }),
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setCoverPhotoList(data))
                toastSuccess("Titulné fotografie boli úspešne aktualizované")
            })
            .catch((error) => {
                toastError("Titulné fotografie sa nepodarilo aktualizovať")
                console.log(error)
            });

        setLoader(false)
    }

    function getParishInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getParishInfo`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setParishInfo(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function getAllUsers() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllUsers`, {
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllUsers(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function getCoverPhotos() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getCoverPhotos`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                dispatch(setCoverPhotoList(data))
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function handleModalOpen(user) {
        setModalShown(true)

        if (user !== null) {
            setUser(user)
        }
    }

    function handleModalClose() {
        setModalShown(false)
        setUser(null)
    }

    const ElementsListUsers = (props) => {

        return (
            <div className="el-list-section fa-flex-col scroll scroll2">
                {props.list.map((user, index) => (
                    <div className="fa-flex-row justify-content-between my-1" key={index}>
                        <div className={"el-list-item ps-1 background-color-1"}>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{`${user.userName}`}</h6>
                        </div>
                        <div className="fa-flex-row me-2">
                            <button className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => handleModalOpen(user)}>
                                <img
                                    src={Images.WRITING_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                    </div>
                ))}

            </div>
        );
    };

    const ModalUser = (props) => {

        const [loader, setLoader] = useState(false)
        const userId = user !== null ? user._id : ""
        const [userName, setUserName] = useState(user !== null ? user.userName : "")
        const [login, setLogin] = useState(user !== null ? user.login : "")
        const [userRights, setUserRights] = useState(user !== null ? user.userRights : "")

        function handleUserCreate(u_userName, u_login, u_userRights) {
            setLoader(true)

            const data = {
                userName: u_userName,
                login: u_login,
                userRights: u_userRights
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/newUser`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    setAllUsers(res)
                    setModalShown(false)
                    setUser(null)
                    toastSuccess("Používateľ bol úspešný vytvorený!")
                })
                .catch((error) => {
                    toastError("Používateľa sa nepodarilo vytvoriť")
                    console.log(error)
                });

            setLoader(false)
        }

        async function handleUserEdit(u_id, u_userName, u_login, u_userRights) {
            setLoader(true)
            const data = {
                user_id: u_id,
                userName: u_userName,
                login: u_login,
                userRights: u_userRights
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/editUser`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    setAllUsers(res)
                    setModalShown(false)
                    setUser(null)
                    toastSuccess("Používateľ bol úspešný vytvorený!")
                })
                .catch((error) => {
                    toastError("Používateľa sa nepodarilo vytvoriť")
                    console.log(error)
                });

            setLoader(false)
        }

        function handleUserDelete(u_id) {
            setLoader(true)

            fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteUserByID/${u_id}`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                }),
            })
                .then((response) => response.json())
                .then((res) => {
                    setAllUsers(res)
                    setModalShown(false)
                    setUser(null)
                    toastSuccess("Používateľ bol úspešný vymazaný!")
                })
                .catch((error) => {
                    toastError("Používateľa sa nepodarilo vymazať")
                    console.log(error)
                });

            setLoader(false)
        }

        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-users p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                    {user !== null ?
                        <>
                            <div className="fa-flex-row justify-content-end gap-2 w-100">
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleUserDelete(user._id)}>
                                    <img
                                        src={Images.BIN_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleModalClose()}>
                                    <img
                                        src={Images.CLOSE_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                            </div>
                            <h3 className="font-900 color-3">{"EDITOVANIE POUŽÍVATEĽA"}</h3>
                            <h4 className="font-300 color-3">{`${user.userName}`}</h4>
                        </>
                        :
                        <h3 className="font-900 color-3">{"VYTVORENIE POUŽÍVATEĽA"}</h3>
                    }

                    <div className="my-2">
                        <h6 className="font-400 color-3">Meno:</h6>
                        <input type="text"
                               id="firstname"
                               placeholder="Napr. Janko"
                               className="form-control admin-input-text-short"
                               value={userName}
                               onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className="mt-2">
                        <h6 className="font-400 color-3">Jedinečný login:</h6>
                        <input type="text"
                               id="login"
                               placeholder="Napr. xzs-W542xq"
                               className="form-control admin-input-text-short"
                               value={login}
                               onChange={(e) => setLogin(e.target.value)}
                        />
                    </div>
                    <div className="my-2">
                        <h6 className="font-400 color-3">Prístupové práva:</h6>
                        <input type="text"
                               id="userRights"
                               placeholder="Napr. mlpdesig"
                               className="form-control admin-input-text-short"
                               value={userRights}
                               onChange={(e) => setUserRights(e.target.value)}
                        />
                    </div>

                    {user !== null ?
                        <button
                            className="btn-primary mt-5"
                            disabled={!(loader === false)}
                            onClick={() => handleUserEdit(userId, userName, login, userRights)}>
                            {loader ? "Ukladám..." : "Uložiť"}
                        </button>
                        :
                        <button
                            className="btn-primary mt-5"
                            disabled={!(loader === false)}
                            onClick={() => handleUserCreate(userName, login, userRights)}>
                            {loader ? "Vytváram..." : "Vytvoriť"}
                        </button>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    // const CoverPhotos = ({photos}) => {
    //
    //     const [loader, setLoader] = useState(false)
    //     const [cover1, setCover1] = useState(coverPhotos[0])
    //     const [cover2, setCover2] = useState(coverPhotos[1])
    //     const [cover3, setCover3] = useState(coverPhotos[2])
    //     const [cover4, setCover4] = useState(coverPhotos[3])
    //     const [cover5, setCover5] = useState(coverPhotos[4])
    //     const [cover6, setCover6] = useState(coverPhotos[5])
    //     const [cover7, setCover7] = useState(coverPhotos[6])
    //     const [cover8, setCover8] = useState(coverPhotos[7])
    //     const [cover9, setCover9] = useState(coverPhotos[8])
    //     const [cover10, setCover10] = useState(coverPhotos[9])
    //     const [cover11, setCover11] = useState(coverPhotos[10])
    //     const [cover12, setCover12] = useState(coverPhotos[11])
    //     const [cover13, setCover13] = useState(coverPhotos[12])
    //     const [cover14, setCover14] = useState(coverPhotos[13])
    //     const [cover15, setCover15] = useState(coverPhotos[14])
    //
    //
    //     function handlePhotoEdit() {
    //         setLoader(true)
    //         const data = {
    //             _id: parishInfo._id,
    //             cover_photos: [cover1, cover2, cover3, cover4, cover5, cover6, cover7, cover8, cover9, cover10, cover11, cover12, cover13, cover14, cover15]
    //         }
    //
    //         fetch(`${process.env.REACT_APP_API_BASE_URL}/editCoverPhotos`, {
    //             method: "POST",
    //             headers: new Headers({
    //                 Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
    //                 Accept: "application/json",
    //                 "Content-Type": "application/json",
    //             }),
    //             body: JSON.stringify(data),
    //         })
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 setCoverPhotos(data)
    //                 toastSuccess("Titulné fotografie boli úspešne aktualizované")
    //             })
    //             .catch((error) => {
    //                 toastError("Titulné fotografie sa nepodarilo aktualizovať")
    //                 console.log(error)
    //             });
    //
    //         setLoader(false)
    //     }
    //
    //     return (
    //         <div className="fa-flex-col align-items-center w-100">
    //             <div>
    //                 <input type="text"
    //                        id="photo-id-1"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover1}
    //                        onChange={(e) => setCover1(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-2"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover2}
    //                        onChange={(e) => setCover2(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-3"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover3}
    //                        onChange={(e) => setCover3(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-4"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover4}
    //                        onChange={(e) => setCover4(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-5"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover5}
    //                        onChange={(e) => setCover5(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-6"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover6}
    //                        onChange={(e) => setCover6(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-7"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover7}
    //                        onChange={(e) => setCover7(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-8"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover8}
    //                        onChange={(e) => setCover8(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-9"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover9}
    //                        onChange={(e) => setCover9(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-10"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover10}
    //                        onChange={(e) => setCover10(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-11"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover11}
    //                        onChange={(e) => setCover11(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-12"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover12}
    //                        onChange={(e) => setCover12(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-13"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover13}
    //                        onChange={(e) => setCover13(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-14"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover14}
    //                        onChange={(e) => setCover14(e.target.value)}
    //                 />
    //                 <input type="text"
    //                        id="photo-id-15"
    //                        placeholder="Zadajte URL ID"
    //                        className="form-control admin-input-text my-3"
    //                        value={cover15}
    //                        onChange={(e) => setCover15(e.target.value)}
    //                 />
    //             </div>
    //             <button className="btn-primary font-700"
    //                     disabled={!(loader === false)}
    //                     onClick={() => handlePhotoEdit()}>
    //                 {loader ? "Aktualizujem..." : "Aktualizovať"}
    //             </ button>
    //         </div>
    //     )
    // }

    const CoverPhotos = ({photos}) => {

        const ImageItem = ({image, idx}) => {

            const [url, setUrl] = useState(image?.url ?? "");
            const [title, setTitle] = useState(image?.title ?? "");
            const [order, setOrder] = useState(image?.order ?? "99");
            const [visible, setVisible] = useState(image?.visible ?? false);
            const [changed, setChanged] = useState(false);

            return (
                <div
                    className="fa-flex-row justify-content-between align-items-center admin-cover-photo-frame rounded-2 p-2 gap-2">
                    {url?.length > 0 ?
                        <img className="admin-cover-photo-img" src={url} alt=""/>
                        :
                        <div className="admin-cover-photo-img h-100 bg-secondary"/>
                    }
                    <div className="fa-flex-col justify-content-evenly h-100 w-100 py-1">
                        <div className="fa-flex-row justify-content-between">
                            <>
                                <input type="text"
                                       id="photo-name-1"
                                       placeholder="Zadajte nadpis"
                                       className="form-control admin-input-text"
                                       value={title}
                                       onChange={(e) => {
                                           setChanged(true)
                                           setTitle(e.target.value)
                                       }}
                                />
                                <div className="fa-flex-row align-items-center justify-content-center me-2">
                                    <h6 className="font-400 color-3 mx-2 mt-2">zobraziť</h6>
                                    <input type="checkbox"
                                           className="form-check-input admin-input-check"
                                           checked={visible}
                                           onChange={() => {
                                               setChanged(true)
                                               setVisible(!visible)
                                           }}
                                    />
                                </div>
                            </>
                            <button className="admin-icon-btn mx-1"
                                    onClick={() => {
                                        dispatch(updateCoverPhotoAtIndex({
                                            index: idx, newObject: {
                                                "url": url,
                                                "title": title,
                                                "visible": visible,
                                                "order": order
                                            }
                                        }))
                                        toastSuccess(`Fotografia '${title}' bola uložená!`)
                                    }}>
                                <img
                                    src={Images.EDIT_BLUE}
                                    className="fa-icon-btn-bg ms-2"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                        <div className="fa-flex-row justify-content-between gap-2">
                            <div className="fa-flex-row w-100">
                                <input type="text"
                                       id="photo-id-1"
                                       placeholder="Zadajte URL obrázku"
                                       className="form-control admin-input-text w-75 me-2"
                                       value={url}
                                       onChange={(e) => {
                                           setChanged(true)
                                           setUrl(e.target.value)
                                       }}
                                />
                                <input type="number"
                                       id="photo-order"
                                       className="form-control admin-input-text w-25"
                                       value={order}
                                       onChange={(e) => {
                                           setChanged(true)
                                           setOrder(e.target.value)
                                       }}
                                />
                            </div>

                            <button className="admin-icon-btn mx-1"
                                    onClick={() => dispatch(removeCoverPhotoByIndex(idx))}>
                                <img
                                    src={Images.CLOSE_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                    </div>
                </div>
            )
        }


        return (
            <div className="fa-flex-col align-items-center w-100 gap-2">
                {photos.map((img, index) => (
                    <ImageItem image={img} idx={index} key={index}/>
                ))}

                <button className="btn-primary font-700 mt-3"
                        onClick={() => dispatch(addEmptyCoverPhoto())}
                >
                    Pridať
                </ button>
            </div>
        )
    }

    return (
        <div className="fa-flex-col" id='developer'>
            <ModalUser show={modalShown} onHide={() => handleModalClose()}/>
            <SectionHeading title={"Administrácia používateľov"} actualId='developer'/>
            <div className="fa-flex-row justify-content-between w-100">
                {allUsers && <ElementsListUsers list={allUsers}/>}
                <div className="fa-flex-col justify-content-between align-items-end">
                    <button className="btn-primary align-self-start font-700"
                            onClick={() => handleModalOpen(null)}>
                        Vytvoriť používateľa
                    </ button>
                    <a href="https://www.toplist.cz/stat/1834119/" className="">
                        <img src="https://toplist.cz/count.asp?id=1834119&logo=mc&start=3064&njs=1" border="0"
                             alt="TOPlist" width="88" height="60"/>
                    </a>
                </div>
            </div>

            <SectionHeading title={"Titulné fotografie"}/>
            <div className="fa-flex-row justify-content-between w-100">
                {coverFromStore && <CoverPhotos photos={coverFromStore}/>}
                <button className={`btn-primary align-self-start font-700`}
                        disabled={!(loader === false)}
                        onClick={() => handlePhotoEdit()}>
                    {loader ? "Aktualizujem..." : "Aktualizovať"}
                </ button>
            </div>


            {/*<div className="fa-flex-col align-items-center w-100">*/}
            {/*    {coverPhotos !== undefined ? <CoverPhotos photos={coverPhotos}/> : <div/>}*/}
            {/*</div>*/}
        </div>
    );
};

export default AdminDeveloper;