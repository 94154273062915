import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/App.css";
import "../assets/css/Actualities.css"
import "../assets/css/Header.css"
import {AppContainer} from "../components/AppContainer";
import {useNavigate, useParams} from "react-router-dom";
import {toastError} from "../components/toastError";
import {LoadingSpinner} from "../components/LoadingSpinner";
import TextEditor from "../components/TextEditor";
import {Images} from "../constants/Images";

const Article = () => {

    const params = useParams();
    const navigate = useNavigate();
    const [article, setArticle] = useState({})

    useEffect(() => {
        getArticleById(params.id)
    }, []);

    function getArticleById(id) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getArticleById/${id}`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setArticle(data)
            })
            .catch((error) => {
                toastError("Chyba pri zmene načítání článku!")
                return error;
            });
    }

    return (
        <AppContainer
            title={article !== undefined ? article.title : "Aktuality"}
            content={
                <div className="window-content-container">
                    {article !== undefined ?
                        <div className="fa-flex-col align-items-center">
                            <button className="btn-primary text-start font-700 pe-3 align-self-start"
                                    onClick={() => navigate(-1)}>
                                <img src={Images.LEFT_ARROW_WHITE} className="fa-icon-btn-sm me-2 my-1" alt="icon"/>
                                Vrátiť sa späť
                            </ button>

                            <div className="fa-flex-col w-100">
                                <div className="fa-flex-row justify-content-between w-100 mt-3">
                                    <div className="fa-flex-row align-items-center me-2">
                                        <img src={Images.USER_ONLY_BLUE} className="fa-icon-btn-sm me-2 mb-1"
                                             alt="icon"/>
                                        <h6 className="font-600 color-3 mt-1">{article.author}</h6>
                                    </div>
                                    <div className="fa-flex-row align-items-center me-2">
                                        <h6 className="font-600 color-3 me-1">Počet prečítaní:</h6>
                                        <h6 className="font-400-lato color-3">{` ${article.views}x`}</h6>
                                    </div>
                                </div>
                                <div className="article-line w-100"/>
                                <div className="fa-flex-row align-items-center me-2">
                                    <h6 className="font-400-lato color-3 fst-italic me-1">Naposledy upravené:</h6>
                                    <h6 className="font-400-lato color-3 fst-italic">{article.date}</h6>
                                </div>
                            </div>

                            <TextEditor value={article.editorHtml}/>
                            {article.images?.map((img, index) => (
                                img !== null > 0 && <img key={index} src={img} className="w-75 my-3" alt=""/>
                            ))
                            }
                        </div>
                        :
                        <div className="app-container mt-5">
                            <LoadingSpinner/>
                        </div>
                    }
                </div>
            }
        />
    )
};

export default Article;
