import {createSlice} from "@reduxjs/toolkit";

export const announcementsSlice = createSlice({
    name: "announcementsList",
    initialState: {
        value: {},
    },
    reducers: {
        setAnnouncementsList: (state, action) => {
            state.value = action.payload;
        },
        updateAnnouncementsListDayByIdx: (state, action) => {
            const {dayIdx, newMass} = action.payload;

            // Vytvorte hlbokú kópiu aktuálneho dňa
            const updatedDay = {...state.value.days[dayIdx]};

            // Nastavte aktualizované pole mass pre updatedDay
            updatedDay.mass.push(newMass)

            // Aktualizujte celý dátumový objekt pre daný deň
            // const updatedDays = [...state.value.days];
            // updatedDays[dayIdx] = updatedDay;

            // Nastavte aktualizovaný stav
            state.value.days[dayIdx] = updatedDay;

            console.log(state.value)
        },
    },
});

export const {setAnnouncementsList, updateAnnouncementsListDayByIdx} = announcementsSlice.actions;

export default announcementsSlice.reducer;