import React, {useEffect, useState} from 'react';
import SectionHeading from "./SectionHeading";
import {ACCESS_TOKEN_KEY} from "../constants";
import {toastSuccess} from "./toastSuccess";
import {toastInfo} from "./toastInfo";
import {toastError} from "./toastError";
import {Images} from "../constants/Images";
import {confirmAlertDialog} from "./confirmAlertDialog";
import {Modal} from "react-bootstrap";

const AdminPhotoGallery = () => {

    const [allPhotoGalleries, setAllPhotoGalleries] = useState()
    const [modalShown, setModalShown] = useState(false)
    const [photoGallery, setPhotoGallery] = useState(null)
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getPhotoGalleries()
    }, []);


    function getPhotoGalleries() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getPhotoGalleries`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllPhotoGalleries(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function setPhotoGalleryVisibility(p_id, visibility) {
        const data = {
            id: p_id,
            isVisible: visibility,
        };

        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/setPhotoGalleryVisibility`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((res) => {
                setAllPhotoGalleries(res)
                if (visibility)
                    toastSuccess("Zvolený album je odteraz viditeľný na webovej stránke!")
                else
                    toastInfo("Zvolený album už nie je odteraz viditeľný na webovej stránke!")
            })
            .catch((error) => {
                toastError("Chyba pri zmene viditeľnosti albumu! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                return error;
            });
    }

    function handleModalOpen(photoGallery) {
        setModalShown(true)

        if (photoGallery !== null) {
            setPhotoGallery(photoGallery)
        }
    }

    function handleModalClose() {
        setModalShown(false)
        setPhotoGallery(null)
    }

    const ElementsListPhotoGalleries = (props) => {

        return (
            <div className="el-list-section-bg fa-flex-col scroll scroll2">
                {props.list.map((photoGallery, index) => (
                    <div className="fa-flex-row justify-content-between my-1" key={index}>
                        <div
                            className={"el-list-item ps-1 " + (photoGallery.isVisible ? "background-color-7" : "background-color-1")}>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{photoGallery.title}</h6>
                            <h6 className="font-300 color-3 text-start mt-2 px-2">{photoGallery.category}</h6>
                        </div>
                        <div className="fa-flex-row me-2">
                            <button className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => handleModalOpen(photoGallery)}>
                                <img
                                    src={Images.WRITING_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                            <button
                                className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                onClick={() => setPhotoGalleryVisibility(photoGallery._id, !photoGallery.isVisible)}>
                                <img
                                    src={Images.EYE_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                    </div>
                ))}

            </div>
        );
    };

    const ModalPhotoGallery = (props) => {

        const [loader, setLoader] = useState(false)
        const photoGalleryId = photoGallery !== null ? photoGallery._id : ""
        const [category, setCategory] = useState(photoGallery !== null ? photoGallery.category : "")
        const [title, setTitle] = useState(photoGallery !== null ? photoGallery.title : "")
        const [imageUrl, setImageUrl] = useState(photoGallery !== null ? photoGallery.image_url : "")
        const [albumUrl, setAlbumUrl] = useState(photoGallery !== null ? photoGallery.album_url : "")


        function handlePhotoGalleryCreate(p_title, p_category, p_image_url, p_album_url) {
            setLoader(true)
            const data = {
                title: p_title,
                category: p_category,
                image_url: p_image_url,
                album_url: p_album_url,
            }

            if (p_title.length === 0 || p_category.length === 0 || p_image_url.length === 0 || p_album_url.length === 0) {
                toastError("Neboli vyplnené všetky povinné údaje!")
            } else {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/newPhotoGallery`, {
                    method: "POST",
                    headers: new Headers({
                        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }),
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((res) => {
                        getPhotoGalleries()
                        setModalShown(false)
                        setPhotoGallery(null)
                        toastSuccess("Album bol úspešne vytvorený! Avšak, na stránke bude viditeľný až po vašom schválení.")
                    })
                    .catch((error) => {
                        toastError("Album sa nepodarilo vytvoriť")
                        console.log(error)
                    });
            }

            setLoader(false)
        }

        function handlePhotoGalleryEdit(p_id, p_title, p_category, p_image_url, p_album_url) {
            setLoader(true)

            const data = {
                _id: p_id,
                title: p_title,
                category: p_category,
                image_url: p_image_url,
                album_url: p_album_url,
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/editPhotoGallery`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    getPhotoGalleries()
                    setModalShown(false)
                    setPhotoGallery(null)
                    toastSuccess("Album bol úspešne aktualizovaný! Avšak, na stránke bude viditeľný až po vašom schválení.")
                })
                .catch((error) => {
                    toastError("Album sa nepodarilo aktualizovať")
                    console.log(error)
                });

            setLoader(false)
        }

        function handlePhotoGalleryDelete(p_id) {
            setModalShown(false)
            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete zmazať album "${title}"?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/deletePhotoGalleryById/${p_id}`, {
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        })
                    })
                        .then((res) => {
                            return res.json();
                        })
                        .then((result) => {
                            getPhotoGalleries()
                            setPhotoGallery(null)
                            toastSuccess("Album bol úspešne vymazaný!")
                        })
                        .catch((error) => {
                            toastError("Chyba pri zmazaní albumu! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                            console.log(error)
                        });
                }
            )
        }

        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-text-editor p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                    {photoGallery !== null ?
                        <>
                            <div className="fa-flex-row justify-content-end gap-2 w-100">
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handlePhotoGalleryDelete(photoGallery._id)}>
                                    <img
                                        src={Images.BIN_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleModalClose()}>
                                    <img
                                        src={Images.CLOSE_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                            </div>
                            <div className="fa-flex-col align-items-center mb-4">
                                <h3 className="font-900 color-3">{"EDITOVANIE ALBUMU"}</h3>
                                <h4 className="font-300 color-3">{photoGallery.title}</h4>
                            </div>
                        </>
                        :
                        <h3 className="font-900 color-3 my-4">{"VYTVORENIE ALBUMU"}</h3>
                    }

                    <div className="fa-flex-col align-items-center my-3 w-100">
                        <div className="fa-flex-row justify-content-between align-items-center my-2 w-75">
                            <h5 className="font-400 color-3">Názov:</h5>
                            <input type="text"
                                   id="title"
                                   placeholder="Zadajte názov fotoalbumu"
                                   className="form-control w-75"
                                   value={title}
                                   maxLength={70}
                                   onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-between align-items-center my-2 w-75">
                            <h5 className="font-400 color-3">Kategória:</h5>
                            <input type="text"
                                   id="category"
                                   placeholder="Zadajte kategóriu/rok fotoalbumu"
                                   className="form-control w-75"
                                   value={category}
                                   maxLength={70}
                                   onChange={(e) => setCategory(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-between align-items-center my-2 w-75">
                            <h5 className="font-400 color-3">Titulná fotografia:</h5>
                            <input type="url"
                                   id="imageUrl"
                                   placeholder="Zadajte URL odkazu na titulnú fotografiu"
                                   className="form-control w-75"
                                   value={imageUrl}
                                   onChange={(e) => setImageUrl(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-between align-items-center mt-2 mb-1 w-75">
                            <h5 className="font-400 color-3">Link na fotoalbum:</h5>
                            <input type="url"
                                   id="albumUrl"
                                   placeholder="Zadajte link fotoalbumu"
                                   className="form-control w-75"
                                   value={albumUrl}
                                   onChange={(e) => setAlbumUrl(e.target.value)}
                            />
                        </div>
                    </div>

                    {photoGallery !== null ?
                        <button
                            className="btn-primary mt-3"
                            disabled={!(loader === false)}
                            onClick={() => handlePhotoGalleryEdit(photoGalleryId, title, category, imageUrl, albumUrl)}>
                            {loader ? "Ukladám..." : "Uložiť"}
                        </button>
                        :
                        <button
                            className="btn-primary mt-3"
                            disabled={!(loader === false)}
                            onClick={() => handlePhotoGalleryCreate(title, category, imageUrl, albumUrl)}>
                            {loader ? "Vytváram..." : "Vytvoriť"}
                        </button>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <div className="fa-flex-col" id='view-photogallery'>
            <ModalPhotoGallery show={modalShown} onHide={() => handleModalClose()}/>
            <SectionHeading title={"Fotoalbum"} actualId='view-photogallery'/>
            <div className="fa-flex-row justify-content-between w-100">
                {allPhotoGalleries && <ElementsListPhotoGalleries list={allPhotoGalleries}/>}
                <button className="btn-primary align-self-start font-700"
                        onClick={() => handleModalOpen(null)}>
                    Vytvoriť fotogalériu
                </ button>
            </div>
        </div>
    );
};

export default AdminPhotoGallery;