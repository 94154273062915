import React, {useEffect, useState} from 'react';
import SectionHeading from "./SectionHeading";
import {Images} from "../constants/Images";
import {toastSuccess} from "./toastSuccess";
import {Modal} from "react-bootstrap";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {toastInfo} from "./toastInfo";
import {toastError} from "./toastError";
import {confirmAlertDialog} from "./confirmAlertDialog";
import {getTodayDate} from "./getTodayDate";
import {ACCESS_TOKEN_KEY} from "../constants";

const AdminEditArticles = () => {

    const [loggedUser, setLoggedUser] = useState({})
    const [allArticles, setAllArticles] = useState()
    const [modalShown, setModalShown] = useState(false)
    const [article, setArticle] = useState(null)
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getUserInfo()
    }, []);


    function getUserInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((user) => {
                setLoggedUser(user)
                if (user !== undefined)
                    if (user.userRights.includes('m'))
                        fetch(`${process.env.REACT_APP_API_BASE_URL}/getArticles`, {})
                            .then((res) => {
                                return res.json();
                            })
                            .then((data) => {
                                setAllArticles(data)
                            })
                            .catch((error) => {
                                console.log(error)
                                return error;
                            });
                    else
                        fetch(`${process.env.REACT_APP_API_BASE_URL}/getArticlesByAuthorId/${user.id}`, {})
                            .then((res) => {
                                return res.json();
                            })
                            .then((data) => {
                                setAllArticles(data)
                            })
                            .catch((error) => {
                                console.log(error)
                                return error;
                            });
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function setArticleVisibility(a_id, visibility) {
        const data = {
            id: a_id,
            isVisible: visibility,
        };

        // PRIDAT confirm alert potvrdenie
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/setArticleVisibility`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((res) => {
                setAllArticles(res)
                if (visibility)
                    toastSuccess("Zvolený článok je odteraz viditeľný na webovej stránke!")
                else
                    toastInfo("Zvolený článok už nie je odteraz viditeľný na webovej stránke!")
            })
            .catch((error) => {
                toastError("Chyba pri zmene viditeľnosti článku! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                return error;
            });
    }

    function handleModalOpen(article) {
        setModalShown(true)

        if (article !== null) {
            setArticle(article)
        }
    }

    function handleModalClose() {
        setModalShown(false)
        setArticle(null)
    }

    const ElementsListUsers = (props) => {

        return (
            <div className="el-list-section-bg fa-flex-col scroll scroll2">
                {props.list.map((article, index) => (
                    <div className="fa-flex-row justify-content-between my-1" key={index}>
                        <div
                            className={"el-list-item ps-1 " + (article.isVisible ? "background-color-7" : "background-color-1")}>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{article.title}</h6>
                            <h6 className="font-300 color-3 text-start mt-2 px-2">{`${article.author} (${article.date})`}</h6>
                        </div>
                        <div className="fa-flex-row me-2">
                            <button className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => handleModalOpen(article)}>
                                <img
                                    src={Images.WRITING_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                            {loggedUser !== undefined ?
                                loggedUser.userRights.includes('m') &&
                                <button
                                    className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => setArticleVisibility(article._id, !article.isVisible)}>
                                    <img
                                        src={Images.EYE_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                                :
                                <div/>
                            }
                        </div>
                    </div>
                ))}

            </div>
        );
    };

    const ModalArticle = (props) => {

        const [loader, setLoader] = useState(false)
        const articleId = article !== null ? article._id : ""
        const date = article !== null ? article.date : getTodayDate()
        const [title, setTitle] = useState(article !== null ? article.title : "")
        const [editorHtml, setEditorHtml] = useState(article !== null ? article.editorHtml : "");
        const [imageUrl, setImageUrl] = useState(article !== null ? article.image_url : "")
        const [image1, setImage1] = useState(article !== null ? article.images[0] : "")
        const [image2, setImage2] = useState(article !== null ? article.images[1] : "")
        const [image3, setImage3] = useState(article !== null ? article.images[2] : "")
        const [image4, setImage4] = useState(article !== null ? article.images[3] : "")

        function handleChangeInEditor(html) {
            setEditorHtml(html)
        }

        function handleArticleCreate(a_title, a_editorHtml, a_image_url, a_images) {
            setLoader(true)
            const data = {
                title: a_title,
                editorHtml: a_editorHtml,
                author: loggedUser.userName,
                authorId: loggedUser.id,
                date: getTodayDate(),
                image_url: a_image_url,
                images: a_images,
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/newArticle`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    getUserInfo()
                    setModalShown(false)
                    setArticle(null)
                    toastSuccess("Článok bol úspešne vytvorený! Avšak, na stránke bude viditeľný až po schválení duchovným otcom.")
                })
                .catch((error) => {
                    toastError("Článok sa nepodarilo vytvoriť")
                    console.log(error)
                });

            setLoader(false)
        }

        function handleArticleEdit(a_id, a_title, a_editorHtml, a_image_url, a_images, a_views) {
            setLoader(true)

            const data = {
                _id: a_id,
                title: a_title,
                editorHtml: a_editorHtml,
                date: getTodayDate(),
                image_url: a_image_url,
                isVisible: false,
                views: a_views,
                images: a_images
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/editArticle`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    getUserInfo()
                    setModalShown(false)
                    setArticle(null)
                    toastSuccess("Článok bol úspešne aktualizovaný! Avšak, na stránke bude viditeľný až po schválení duchovným otcom.")
                })
                .catch((error) => {
                    toastError("Článok sa nepodarilo aktualizovať")
                    console.log(error)
                });

            setLoader(false)
        }

        function handleArticleDelete(a_id) {
            setModalShown(false)
            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete zmazať článok "${title}"?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteArticleById/${a_id}`, {
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        })
                    })
                        .then((res) => {
                            return res.json();
                        })
                        .then((result) => {
                            getUserInfo()
                            setArticle(null)
                            toastSuccess("Článok bol úspešne vymazaný!")
                        })
                        .catch((error) => {
                            toastError("Chyba pri zmazaní článku! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                            console.log(error)
                        });
                }
            )
        }

        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-text-editor p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                    {article !== null ?
                        <>
                            <div className="fa-flex-row justify-content-end gap-2 w-100">
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleArticleDelete(article._id)}>
                                    <img
                                        src={Images.BIN_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleModalClose()}>
                                    <img
                                        src={Images.CLOSE_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                            </div>
                            <div className="fa-flex-col align-items-center mb-4">
                                <h3 className="font-900 color-3">{"EDITOVANIE ČLÁNKU"}</h3>
                                <h4 className="font-300 color-3">{article.title}</h4>
                            </div>
                        </>
                        :
                        <h3 className="font-900 color-3 my-4">{"VYTVORENIE ČLÁNKU"}</h3>
                    }

                    <div className="fa-flex-col align-items-center my-3 w-100">
                        <div className="fa-flex-row justify-content-between align-items-center my-2 w-75">
                            <h5 className="font-400 color-3">Nadpis:</h5>
                            <input type="text"
                                   id="title"
                                   placeholder="Zadajte názov článku"
                                   className="form-control w-75"
                                   value={title}
                                   maxLength={70}
                                   onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-between align-items-center my-2 w-75">
                            <h5 className="font-400 color-3">Titulná fotografia:</h5>
                            <input type="url"
                                   id="imageUrl"
                                   placeholder="Zadajte URL odkazu na titulnú fotografiu"
                                   className="form-control w-75"
                                   value={imageUrl}
                                   onChange={(e) => setImageUrl(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-between align-items-center mt-2 mb-1 w-75">
                            <h5 className="font-400 color-3">Obrázky:</h5>
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 1"
                                   className="form-control w-75"
                                   value={image1}
                                   onChange={(e) => setImage1(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-end align-items-center my-1 w-75">
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 2"
                                   className="form-control w-75"
                                   value={image2}
                                   onChange={(e) => setImage2(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-end align-items-center my-1 w-75">
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 2"
                                   className="form-control w-75"
                                   value={image3}
                                   onChange={(e) => setImage3(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-end align-items-center my-1 w-75">
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 2"
                                   className="form-control w-75"
                                   value={image4}
                                   onChange={(e) => setImage4(e.target.value)}
                            />
                        </div>
                    </div>

                    <ReactQuill
                        className="text-editor"
                        theme={"snow"}
                        onChange={handleChangeInEditor}
                        value={editorHtml}
                        modules={
                            {
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{'align': []}],
                                    [{'list': 'ordered'}, {'list': 'bullet'}],
                                    [{'indent': '-1'}, {'indent': '+1'}],
                                    [{'size': ['large', 'huge']}],
                                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                                    [{'color': []}, {'background': []}],
                                    [{'font': []}],
                                    ['link', 'image', 'video'],

                                    ['clean']
                                ],
                                clipboard: {
                                    matchVisual: false,
                                }
                            }
                        }
                        // formats={Sacraments.formats}
                        bounds={'.app'}
                        placeholder={""}
                    />

                    {article !== null ?
                        <h6 className="font-300 color-3 fa-text-right mt-2">{`Naposledy upravené: ${date}`}</h6>
                        :
                        <h6 className="font-300 color-3 fa-text-right mt-2">{`Vytvorené: ${date}`}</h6>
                    }

                    {article !== null ?
                        <button
                            className="btn-primary mt-3"
                            disabled={!(loader === false)}
                            onClick={() => handleArticleEdit(articleId, title, editorHtml, imageUrl, [image1, image2, image3, image4], article.views)}>
                            {loader ? "Ukladám..." : "Uložiť"}
                        </button>
                        :
                        <button
                            className="btn-primary mt-3"
                            disabled={!(loader === false)}
                            onClick={() => handleArticleCreate(title, editorHtml, imageUrl, [image1, image2, image3, image4])}>
                            {loader ? "Vytváram..." : "Vytvoriť"}
                        </button>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <div className="fa-flex-col" id='edit-articles'>
            <ModalArticle show={modalShown} onHide={() => handleModalClose()}/>
            <SectionHeading title={"Editácia článkov"} actualId='edit-articles'/>
            <div className="fa-flex-row justify-content-between w-100">
                {allArticles && <ElementsListUsers list={allArticles}/>}
                <button className="btn-primary align-self-start font-700"
                        onClick={() => handleModalOpen(null)}>
                    Vytvoriť článok
                </ button>
            </div>
        </div>
    );
};

export default AdminEditArticles;