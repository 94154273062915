import React, {useEffect, useState} from "react";
import "../assets/css/Print.css"
import {renderTextWithLine} from "./renderTextWithLine";

export const WeekText = ({title, texts, cleaning, showHeader}) => {

    const [cleaningGroup, setCleaningGroup] = useState({})

    useEffect(() => {
        getGroupById()
    }, []);

    function getGroupById() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getCleningGroupById/${cleaning[0].groupId}`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setCleaningGroup(data)
            })
            .catch((error) => {
                console.log(error)
            });
    }


    return (
        <div className={`fa-flex-col ${showHeader ? 'p-week-text-print' : 'p-week-text'} px-3 mt-4`}>
            {showHeader ?
                <div className="mb-5">
                    <h2 className="font-700 color-3 text-center">{`OZNAMY`}</h2>
                    <h4 className="font-400 color-3 text-center">{`${title}`}</h4>
                </div>
                :
                <div/>
            }

            <div>
                <div className="fa-flex-row w-100">
                    <h5 className="font-700 color-3 me-2">{"Upratovanie kostola:".toUpperCase()}</h5>
                    <h5 className="font-400 color-3">{cleaning[0].dayAndTime}</h5>
                </div>

                {cleaningGroup !== undefined ?
                    <h5 className="color-3 fa-flex-col">
                        <span
                            className="font-700 color-3 w-100 my-2 text-decoration-underline">{`${cleaningGroup?.title ?? ""}`}</span>
                        <div className="d-flex flex-wrap justify-content-start">
                            {cleaningGroup.members !== undefined &&
                                cleaningGroup.members?.map((person, index) => (
                                    person?.length > 0 &&
                                    <span key={index} className="font-400 me-2 my-1">
                                        {`${person}${cleaningGroup.members[index + 1] !== undefined && cleaningGroup.members[index + 1]?.length > 0 ? ", " : ""}`}
                                    </span>
                                ))}
                        </div>
                    </h5>
                    : ""
                }
            </div>
            {texts.map((item, index) => (
                item.title?.length > 0 || item.content?.length > 0 ?
                    <div key={index} className="mt-4">
                        <h5 className="font-700 color-3">{`${item.title.toUpperCase()}`}</h5>
                        <h5 className="font-400 color-3">{renderTextWithLine(item.content)}</h5>
                    </div>
                    :
                    <div key={index}/>
            ))}
        </div>
    )
}