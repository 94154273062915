import React, {useEffect, useState} from 'react';
import {AppContainer} from "../components/AppContainer";
import TextEditor from "../components/TextEditor";
import {LoadingSpinner} from "../components/LoadingSpinner";

const Choir = () => {
    const [subpage, setSubpage] = useState()

    useEffect(() => {
        getSubpageByLink('zbor')
    }, []);

    function getSubpageByLink(link) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getSubpageByLink/${link}`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setSubpage(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }


    return (
        <AppContainer
            title={subpage !== undefined ? subpage.title : "Zbor"}
            content={
                <div className="window-content-container">
                    {subpage !== undefined ?
                        subpage.isVisible ?
                            <div className="fa-flex-col align-items-center">
                                <img src={subpage.images[0]} className="w-75 mt-1 mb-2" alt=""/>
                                <TextEditor value={subpage.editorHtml}/>
                                {subpage.images.map((img, index) => (
                                    img !== null > 0 && index !== 0 &&
                                    <img key={index} src={img} className="w-75 my-3" alt=""/>
                                ))
                                }
                            </div>
                            :
                            <div className="disable-page-box py-3 rounded-3">
                                <h4 className="font-900 color-3 text-center">Túto podstránku pre vás ešte
                                    pripravujeme!</h4>
                                <h5 className="font-700 color-3 text-center">Vrátťe sa neskôr, prosím 😉</h5>
                            </div>
                        :
                        <div className="app-container mt-5">
                            <LoadingSpinner/>
                        </div>
                    }
                </div>
            }
        />
    )
};

export default Choir;