import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/App.css";
import "../assets/css/Footer.css";
import "../assets/css/Icons.css";
import {Images} from "../constants/Images";
import {useNavigate} from "react-router-dom";
import {Modal} from "react-bootstrap";
import ContactsTab from "./ContactsTab";
import {SectionBanksAccounts} from "./SectionBanksAccounts";
import {toastSuccess} from "./toastSuccess";
import {ACCESS_TOKEN_KEY} from "../constants";

const Footer = () => {

    const navigate = useNavigate();
    const [modalLogin, setModalLogin] = useState(false)
    const [parishInfo, setParishInfo] = useState()
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null
            ? localStorage.getItem("theme")
            : "light"
    );

    useEffect(() => {
        getParishInfo()
    }, []);

    function login() {
        setModalLogin(true)
    }

    function logout() {
        localStorage.clear();
        window.location = "/";
    };

    function getParishInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getParishInfo`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setParishInfo(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    const Contacts = ({data}) => {
        return (
            <div className="fa-flex-col m-2 me-4" id="desktop-not-shown">
                <ContactsTab contactData={data} footer={true}/>
            </div>
        )
    }
    const OfficeHours = ({data}) => {
        return (
            <div className="office-hours-section fa-flex-col m-2 me-4">
                <h5 className="font-700 color-1">Úradné hodiny farského úradu</h5>
                {data.office_hours.map((item, index) => (
                    !item.visible ?
                        <div className="footer-hours-table fa-flex-row justify-content-between mt-2" key={index}>
                            <h6 className="font-300 color-1">{item.day}</h6>
                            <h6 className="font-400 color-1">{`${item.start_time} - ${item.end_time}`}</h6>
                        </div>
                        :
                        <div key={index}/>
                ))}
                <h6 className="font-700 color-1 mt-2 fa-flex-col">
                    <span>Zmena úradných hodín vyhradená!</span>
                    <span>(spovedanie, rekolekcie, pohreb a pod.)</span>
                </h6>
            </div>
        )
    }
    const ListOfLinks = () => {
        const LinkButton = (props) => {
            return (
                <a className="footer-btn-link my-2 mx-1 p-2 rounded" href={props.navigateUrl} target="">
                    <img src={props.source} className="fa-footer-list-icon" alt="icon"/>
                </a>
            )
        }

        return (
            <div className="footer-linklist-section mt-3">
                <LinkButton source={Images.LOGO_LUMEN} navigateUrl={"https://www.lumen.sk/"}/>
                <LinkButton source={Images.LOGO_DOMCEK} navigateUrl={"https://domcek.org/"}/>
                <LinkButton source={Images.LOGO_LUX} navigateUrl={"https://www.tvlux.sk/"}/>
                <LinkButton source={Images.LOGO_CHARITA} navigateUrl={"https://www.charita-ke.sk/"}/>
                <LinkButton source={Images.LOGO_MISIE} navigateUrl={"https://misijnediela.sk/"}/>
                <LinkButton source={Images.LOGO_ARCIDIECEZA} navigateUrl={"https://www.ke-arcidieceza.sk/"}/>
            </div>
        )
    }
    const ModalLogin = (props) => {
        const [username, setUsername] = useState("");
        const [errorMessage, setErrorMessage] = useState('');
        const handleLogin = (login) => {

            fetch(`${process.env.REACT_APP_API_BASE_URL}/loginUser/${login}`, {method: "POST"})
                .then((response) => response.json())
                .then((token) => {
                    localStorage.setItem(ACCESS_TOKEN_KEY, token);
                    navigate("/admin")
                    toastSuccess("Vitajte naspäť v admin panely našej farskej stránky 😉")
                })
                .catch((error) => {
                    setErrorMessage('Nesprávne používateľské ID. Skúste znova.');
                    console.log(error)
                });
        };


        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-login p-2">
                <Modal.Body className="fa-flex-col background-color-1 rounded-5 my-3">
                    <h3 className="font-900 color-3 text-center w-100 mt-2">Administrácia služieb</h3>
                    <h4 className="font-300 color-3 text-center w-100 mt-2">Prihlásenie do konta</h4>
                    <div className="mt-5 mx-3">
                        <h6 className="font-400 color-3">Používateľské meno:</h6>
                        <input type="text"
                               id="username"
                               placeholder="Zadajte používateľské meno"
                               className="form-control"
                               value={username}
                               onChange={(e) => setUsername(e.target.value)}
                        />
                        {errorMessage && <div className="text-danger">{errorMessage}</div>}
                        <button
                            className="btn-primary w-100 mt-4"
                            onClick={() => handleLogin(username)}>
                            Prihlásiť sa
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <>
            <ModalLogin show={modalLogin} onHide={() => setModalLogin(false)}/>
            <div className="footer-container px-2 pt-2 w-100">
                <div className="desktop-row-phone-col w-100 justify-content-between">
                    {parishInfo !== undefined &&
                        <>
                            <Contacts data={parishInfo}/>
                            <SectionBanksAccounts data={parishInfo} textColor={'color-1'} icon={Images.BANK_WHITE}/>
                            <OfficeHours data={parishInfo}/>
                        </>
                    }
                    <ListOfLinks/>
                </div>


                <div className="w-100 desktop-row-phone-col justify-content-between align-items-center mt-2">
                    {parishInfo !== undefined &&
                        <div className="footer-counter">
                            {/*<h6 className="color-1">*/}
                            {/*    <span className="font-700 me-1">Počet návštev dnes:</span>*/}
                            {/*    <span className="font-400-lato">{parishInfo.visitors}</span>*/}
                            {/*</h6>*/}
                            <h6 className="color-1">
                                <span className="font-700 me-1">Počet návštev celkovo:</span>
                                <span className="font-400-lato">{parishInfo.pageviews}</span>
                            </h6>
                        </div>
                    }
                    <a className="btn-primary"
                       href={"https://gdpr.kbs.sk/"} target="">
                        Ochrana osobných údajov
                    </a>
                </div>


                <div className="w-100 desktop-row-phone-col justify-content-between align-items-center mt-2">
                    <div className="d-flex align-self-end m-2">
                        <h6 className="font-300 color-1 text-start">
                            2023 Farnosť sv. Ondreja, Pečovská Nová Ves
                        </h6>
                    </div>
                    {localStorage.getItem(ACCESS_TOKEN_KEY) === null ?
                        <button className="btn-primary text-start font-700 phone-not-shown mb-1"
                                onClick={() => login()}>
                            <img src={Images.USER_WHITE} className="fa-icon-btn-sm me-2 mb-1" alt="icon"/>
                            Prihlásiť sa
                        </ button>
                        :
                        <button className="btn-primary text-start font-700 phone-not-shown mb-1"
                                onClick={() => logout()}>
                            <img src={Images.USER_WHITE} className="fa-icon-btn-sm me-2 mb-1" alt="icon"/>
                            Odhlásiť sa
                        </ button>
                    }
                </div>
            </div>
        </>
    )
};

export default Footer;
