import React, {useEffect, useState} from 'react';
import SectionHeading from "./SectionHeading";
import {toastSuccess} from "./toastSuccess";
import {toastInfo} from "./toastInfo";
import {toastError} from "./toastError";
import {Images} from "../constants/Images";
import {getTodayDate} from "./getTodayDate";
import {confirmAlertDialog} from "./confirmAlertDialog";
import {Modal} from "react-bootstrap";
import ReactQuill from "react-quill";
import {ACCESS_TOKEN_KEY} from "../constants";

const AdminEditSubpages = () => {

    const [allSubpages, setAllSubpages] = useState()
    const [modalSubpageShown, setModalSubpageShown] = useState(false)
    const [subpage, setSubpage] = useState(null)
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getAllSubpages()
    }, []);

    function getAllSubpages() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllSubpages`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllSubpages(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function setSubpageVisibility(s_id, visibility) {
        const data = {
            id: s_id,
            isVisible: visibility,
        };

        // PRIDAT confirm alert potvrdenie
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/setSubpageVisibility`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((res) => {
                setAllSubpages(res)
                if (visibility)
                    toastSuccess("Zvolená podstránka je odteraz viditeľná na webovej stránke!")
                else
                    toastInfo("Zvolená podstránka už nie je odteraz viditeľná na webovej stránke!")
            })
            .catch((error) => {
                toastError("Chyba pri zmene viditeľnosti podstránky! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                return error;
            });
    }

    function handleModalSubpageOpen(subpage) {
        setModalSubpageShown(true)

        if (subpage !== null) {
            setSubpage(subpage)
        }
    }

    function handleModalSubpageClose() {
        setModalSubpageShown(false)
        setSubpage(null)
    }

    const ElementsListSubpages = (props) => {

        return (
            <div className="el-list-section-bg fa-flex-col scroll scroll2">
                {props.list.map((subpage, index) => (
                    <div className="fa-flex-row justify-content-between my-1" key={index}>
                        <div
                            className={"el-list-item ps-1 " + (subpage.isVisible ? "background-color-7" : "background-color-1")}>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{subpage.title}</h6>
                            <h6 className="font-300 color-3 text-start mt-2 px-2">{`upravené: ${subpage.lastUpdated}`}</h6>
                        </div>
                        <div className="fa-flex-row me-2">
                            <button className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => handleModalSubpageOpen(subpage)}>
                                <img
                                    src={Images.WRITING_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                            <button
                                className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                onClick={() => setSubpageVisibility(subpage._id, !subpage.isVisible)}>
                                <img
                                    src={Images.EYE_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                    </div>
                ))}

            </div>
        );
    };

    const ModalSubpages = (props) => {

        const [loader, setLoader] = useState(false)
        const subpageId = subpage !== null ? subpage._id : ""

        const [title, setTitle] = useState(subpage !== null ? subpage.title : "")
        const [link, setLink] = useState(subpage !== null ? subpage.link : "")
        const [editorHtml, setEditorHtml] = useState(subpage !== null ? subpage.editorHtml : "");
        const [image1, setImage1] = useState(subpage !== null ? subpage.images[0] : "");
        const [image2, setImage2] = useState(subpage !== null ? subpage.images[1] : "");
        const [image3, setImage3] = useState(subpage !== null ? subpage.images[2] : "");
        const [image4, setImage4] = useState(subpage !== null ? subpage.images[3] : "");

        function handleChangeInEditor(html) {
            setEditorHtml(html)
        }

        function handleSubpageCreate(s_title, s_link, s_editorHtml, s_images) {
            setLoader(true)

            const data = {
                title: s_title,
                link: s_link,
                editorHtml: s_editorHtml,
                isVisible: false,
                lastUpdated: getTodayDate(),
                images: s_images,
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/newSubpage`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    setAllSubpages(res)
                    setModalSubpageShown(false)
                    setSubpage(null)
                    toastSuccess("Podstránka bola úspešne vytvorená!")
                })
                .catch((error) => {
                    toastError("Podstránku sa nepodarilo vytvoriť")
                    console.log(error)
                });

            setLoader(false)
        }

        function handleSubpageEdit(s_id, s_title, s_link, s_editorHtml, s_images) {
            setLoader(true)

            const data = {
                _id: s_id,
                title: s_title,
                link: s_link,
                editorHtml: s_editorHtml,
                isVisible: false,
                lastUpdated: getTodayDate(),
                images: s_images,
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/editSubpage`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    setAllSubpages(res)
                    setModalSubpageShown(false)
                    setSubpage(null)
                    toastSuccess("Podstránka bola úspešne aktualizovaná!")
                })
                .catch((error) => {
                    toastError("Podstránku sa nepodarilo aktualizovať")
                    console.log(error)
                });

            setLoader(false)
        }

        function handleSubpageDelete(s_id) {
            setModalSubpageShown(false)
            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete zmazať podstránku "${title}"?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteSubpageById/${s_id}`, {
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        })
                    })
                        .then((res) => {
                            return res.json();
                        })
                        .then((res) => {
                            setAllSubpages(res)
                            setSubpage(null)
                            toastSuccess("Podstránka bola úspešne vymazaná!")
                        })
                        .catch((error) => {
                            toastError("Chyba pri zmazaní podstránky! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                            console.log(error)
                        });
                }
            )
        }

        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-text-editor p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                    {subpage !== null ?
                        <>
                            <div className="fa-flex-row justify-content-end gap-2 w-100">
                                <button className="admin-icon-btn mx-1"
                                        disabled
                                        onClick={() => handleSubpageDelete(subpage._id)}>
                                    <img
                                        src={Images.BIN_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleModalSubpageClose()}>
                                    <img
                                        src={Images.CLOSE_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                            </div>
                            <div className="fa-flex-col align-items-center mb-4">
                                <h3 className="font-900 color-3">{"EDITOVANIE PODSTRÁNKY"}</h3>
                                <h4 className="font-300 color-3">{subpage.title}</h4>
                            </div>
                        </>
                        :
                        <h3 className="font-900 color-3 my-4">{"VYTVORENIE PODSTRÁNKY"}</h3>
                    }

                    <div className="fa-flex-col align-items-center my-3 w-100">
                        <div className="fa-flex-row justify-content-between align-items-center my-2 w-75">
                            <h5 className="font-400 color-3">Nadpis:</h5>
                            <input type="text"
                                   id="firstname"
                                   placeholder="Zadajte názov podstránky"
                                   className="form-control w-75"
                                   value={title}
                                   disabled
                                   maxLength={70}
                                   onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-between align-items-center my-2 w-75">
                            <h5 className="font-400 color-3">Link (jedinečné slovo):</h5>
                            <input type="text"
                                   id="firstname"
                                   disabled
                                   placeholder="Zadajte odkaz ktory bude v URL adrese pre danú podstránku"
                                   className="form-control w-75"
                                   value={link}
                                   onChange={(e) => setLink(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-between align-items-center mt-2 mb-1 w-75">
                            <h5 className="font-400 color-3">Obrázky:</h5>
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 1"
                                   className="form-control w-75"
                                   value={image1}
                                   onChange={(e) => setImage1(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-end align-items-center my-1 w-75">
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 2"
                                   className="form-control w-75"
                                   value={image2}
                                   onChange={(e) => setImage2(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-end align-items-center my-1 w-75">
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 2"
                                   className="form-control w-75"
                                   value={image3}
                                   onChange={(e) => setImage3(e.target.value)}
                            />
                        </div>
                        <div className="fa-flex-row justify-content-end align-items-center my-1 w-75">
                            <input type="url"
                                   id="posterUrl"
                                   placeholder="Zadajte URL odkazu na obrázok 2"
                                   className="form-control w-75"
                                   value={image4}
                                   onChange={(e) => setImage4(e.target.value)}
                            />
                        </div>
                    </div>

                    <ReactQuill
                        className="text-editor"
                        theme={"snow"}
                        onChange={handleChangeInEditor}
                        value={editorHtml}
                        modules={
                            {
                                toolbar: [
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{'align': []}],
                                    [{'list': 'ordered'}, {'list': 'bullet'}],
                                    [{'indent': '-1'}, {'indent': '+1'}],
                                    [{'size': ['large', 'huge']}],
                                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                                    [{'color': []}, {'background': []}],
                                    [{'font': []}],
                                    ['link', 'image', 'video'],

                                    ['clean']
                                ],
                                clipboard: {
                                    matchVisual: false,
                                }
                            }
                        }
                        bounds={'.app'}
                        placeholder={""}
                    />

                    {subpage !== null ??
                        <h6 className="font-300 color-3 fa-text-right w-75 mt-1">{`Naposledy upravené: ${subpage.lastUpdated}`}</h6>}

                    {subpage !== null ?
                        <button
                            className="btn-primary mt-3"
                            disabled={!(loader === false)}
                            onClick={() => handleSubpageEdit(subpageId, title, link, editorHtml, [image1, image2, image3, image4])}>
                            {loader ? "Ukladám..." : "Uložiť"}
                        </button>
                        :
                        <button
                            className="btn-primary mt-3"
                            disabled={!(loader === false)}
                            onClick={() => handleSubpageCreate(title, link, editorHtml, [image1, image2, image3, image4])}>
                            {loader ? "Vytváram..." : "Vytvoriť"}
                        </button>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <div className="fa-flex-col" id='edit-subpages'>
            <ModalSubpages show={modalSubpageShown} onHide={() => handleModalSubpageClose()}/>
            <SectionHeading title={"Editácia podstránok"} actualId='edit-subpages'/>
            <div className="fa-flex-row justify-content-between w-100">
                {allSubpages !== undefined ? <ElementsListSubpages list={allSubpages}/> : <div/>}
                <button className="btn-primary align-self-start font-700"
                        disabled
                        onClick={() => handleModalSubpageOpen(null)}>
                    Vytvoriť podstránku
                </ button>
            </div>
        </div>
    );
};

export default AdminEditSubpages;