const Images = {
    ONDREJ: require('../assets/img/ondrej.JPG'),
    KOSTOL_COVER: require('../assets/img/kostol_pnv_local.jpg'),
    KOSTOL_CROP: require('../assets/img/kostol_pnv_crop.JPG'),


    OLD_TYPICAL_PHONE_WHITE: require('../assets/img/old-typical-phone_white.png'),
    PHONE_WHITE: require('../assets/img/phone_white.png'),
    EMAIL_WHITE: require('../assets/img/email_white.png'),
    LOCATION_PIN_WHITE: require('../assets/img/location-pin_white.png'),
    HAND_WHITE: require('../assets/img/hand_blue.png'),
    PRINTER_WHITE: require('../assets/img/printer_whitew.png'),
    DOWNLOAD_PDF_WHITE: require('../assets/img/download-pdf_white.png'),
    BANK_WHITE: require('../assets/img/bank_white.png'),
    USER_WHITE: require('../assets/img/user_white.png'),
    RECORD_FULL_WHITE: require('../assets/img/record_full_white.png'),
    TABLES_BLUE: require('../assets/img/table_blue.png'),
    MENU_BLUE: require('../assets/img/menu_blue.png'),
    CLOSE_BLUE: require('../assets/img/close_blue.png'),
    DOWN_ARROW_BLUE: require('../assets/img/down-arrow-blue.png'),
    LEFT_ARROW_WHITE: require('../assets/img/left-arrow-white.png'),
    ADD_BLUE: require('../assets/img/add_blue.png'),
    EDIT_BLUE: require('../assets/img/edit_blue.png'),
    QUOTES_BLUE: require('../assets/img/quotes_blue.png'),
    WRITING_BLUE: require('../assets/img/writing_blue.png'),
    USER_BLUE: require('../assets/img/user_blue.png'),
    USER_ONLY_BLUE: require('../assets/img/user_only_blue.png'),
    EYE_BLUE: require('../assets/img/eye_blue.png'),
    BIN_WHITE: require('../assets/img/bin_white.png'),
    BIN_BLUE: require('../assets/img/bin_blue.png'),
    HAND_BLUE: require('../assets/img/hand_blue.png'),
    BANK_BLUE: require('../assets/img/bank_blue.png'),
    PRINTER_BLUE: require('../assets/img/printer_blue.png'),
    PRINTER_IMG_BLUE: require('../assets/img/printer_img_blue.png'),
    PRINTER_TEXT_BLUE: require('../assets/img/printer_text_blue.png'),
    CLEANING_BLUE: require('../assets/img/cleaning_blue.png'),
    LECTURER_BLUE: require('../assets/img/lecturer_blue.png'),
    PSALMITS_BLUE: require('../assets/img/psalmists_blue.png'),
    TOP_ARROW_WHITE: require('../assets/img/top-arrow-white.png'),
    PHOTOGALLERY_BLUE: require('../assets/img/photogallery_blue.png'),

    LOGO_LUMEN: require('../assets/img/logo_lumen.png'),
    LOGO_DOMCEK: require('../assets/img/logo_domcek.png'),
    LOGO_CHARITA: require('../assets/img/logo_charita.png'),
    LOGO_MISIE: require('../assets/img/logo_misie.png'),
    LOGO_LUX: require('../assets/img/logo_lux.png'),
    LOGO_ARCIDIECEZA: require('../assets/img/logo_arcidieceza.png'),
}

export {Images}
