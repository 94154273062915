import "../assets/css/App.css";
import React, {useState} from "react";

export const LoadingSpinner = () => {
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null
            ? localStorage.getItem("theme")
            : "light"
    );

    return (
        <div className="loader-container-app" data-theme={theme}>
            <div className="loader-app"/>
        </div>
    );
};
