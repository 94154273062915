import {configureStore} from "@reduxjs/toolkit";
import parishInfoSlice from "./parishInfoSlice";
import massScheduleSlice from "./massScheduleSlice";
import announcementsSlice from "./announcementsSlice";
import coverPhotoSlice from "./coverPhotoSlice";

export default configureStore({
    reducer: {
        parishInfoList: parishInfoSlice,
        massScheduleList: massScheduleSlice,
        announcementsList: announcementsSlice,
        coverPhotoList: coverPhotoSlice,
    },
});
