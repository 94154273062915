import "./assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Introduction from "./pages/Introduction";
import Actualities from "./pages/Actualities";
import History from "./pages/History";
import Sacraments from "./pages/Sacraments";
import PhotoGallery from "./pages/PhotoGallery";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Charbel from "./pages/Charbel";
import Choir from "./pages/Choir";
import Lecturers from "./pages/Lecturers";
import Ministers from "./pages/Ministers";
import ParishCouncil from "./pages/ParishCouncil";
import Psalmists from "./pages/Psalmists";
import Rossary from "./pages/Rossary";
import "react-toastify/dist/ReactToastify.css";
import AdminPanel from "./pages/AdminPanel";
import Andrew from "./pages/Andrew";
import Goretti from "./pages/Goretti";
import OurLadyHelpOfChristians from "./pages/OurLadyHelpOfChristians";
import Article from "./pages/Article";
import PageNotFound from "./pages/PageNotFound";
import Contact from "./pages/Contact";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Introduction/>}/>
                <Route path="/sarbel" element={<Charbel/>}/>
                <Route path="/zbor" element={<Choir/>}/>
                <Route path="/lektori" element={<Lecturers/>}/>
                <Route path="/ministranti" element={<Ministers/>}/>
                <Route path="/farska_rada" element={<ParishCouncil/>}/>
                <Route path="/zalmisti" element={<Psalmists/>}/>
                <Route path="/ruzenec" element={<Rossary/>}/>
                <Route path="/aktuality" element={<Actualities/>}/>
                <Route path="/aktuality/:id" element={<Article/>}/>
                <Route path="/historia" element={<History/>}/>
                <Route path="/sviatosti" element={<Sacraments/>}/>
                <Route path="/fotogaleria" element={<PhotoGallery/>}/>
                <Route path="/kontakt" element={<Contact/>}/>
                <Route path="/admin" element={<AdminPanel/>}/>

                <Route path="/ondrej" element={<Andrew/>}/>
                <Route path="/maria_goretti" element={<Goretti/>}/>
                <Route path="/pomocnica_krestanov" element={<OurLadyHelpOfChristians/>}/>
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
