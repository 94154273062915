import React, {useEffect, useState} from 'react';
import "../assets/css/Print.css"
import "../assets/css/App.css"
import {formatDate} from "./formatDate";
import {WeekText} from "./WeekText";
import {formatTime} from "./formatTime";
import {getCountOfMasses} from "./getCountOfMasses";
import {renderTextWithLine} from "./renderTextWithLine";

const AnnouncementsPDF = ({title, data, id, image, texts, cleaning, printText}) => {

    const [parishInfo, setParishInfo] = useState({})
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getParishInfo()
    }, []);

    function getParishInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getParishInfo`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setParishInfo(data)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const TableToPrint = ({data, id}) => {
        const PrintDay = ({days}) => {

            let pAnTblRowClass = getCountOfMasses(true, data) > 12 && getCountOfMasses(false, days) > 1 ? "p-an-tbl-row-sm" : "p-an-tbl-row"
            const MassDetail = ({mass}) => {
                return (
                    <div className={pAnTblRowClass}>
                        <div
                            className={`an-tbl-2 an-border ${mass.place !== "PNV" ? "an-color" : ""}`}>
                            <span className="font-700 p-fa-font-place">{mass.place}</span>
                        </div>
                        <div
                            className={`an-tbl-3 an-border p-1 ${mass.place !== "PNV" ? "an-color" : ""}`}>
                            <span className="p-fa-font-time">{mass.time}</span>
                        </div>
                        {mass.confession_start ?
                            <div className={`an-tbl-4 an-border p-1 ${mass.place !== "PNV" ? "an-color" : ""}`}>
                            <span className="font-700 p-fa-font-intention ps-2">
                                {mass.intention.length !== 0 ? mass.intention : ""}
                            </span>
                                <span className="p-fa-font-confession pe-2">
                                {`sv. zmierenia: ${formatTime(mass.confession_start)} - ${formatTime(mass.confession_end)}`}
                            </span>
                            </div>
                            :
                            <div
                                className={`an-tbl-4 justify-content-center an-border p-1 ${mass.place !== "PNV" ? "an-color" : ""}`}>
                            <span className="font-700 p-fa-font-intention ps-2">
                                {mass.intention.length !== 0 ? mass.intention : ""}
                            </span>
                            </div>
                        }
                    </div>
                )
            }

            return (
                days[0].feast_day === days[1].feast_day && days[0].feast_day === days[2].feast_day && days[0].feast_day === days[3].feast_day ?
                    <div>
                        <div className={pAnTblRowClass}>
                            <div className="an-tbl-1 an-border">
                                <span
                                    className="p-fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                            </div>
                            <div className="an-tbl-col">
                                {days[0].place && days[0].time && <MassDetail mass={days[0]}/>}
                                {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                                {days[3].place && days[3].time && <MassDetail mass={days[3]}/>}
                            </div>
                        </div>
                    </div>

                    :

                    days[0].feast_day === days[1].feast_day && days[0].feast_day === days[2].feast_day ?
                        <div>
                            <div className={pAnTblRowClass}>
                                <div className="an-tbl-1 an-border">
                                <span
                                    className="font-400 p-fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                </div>
                                <div className="an-tbl-col">
                                    {days[0].place && days[0].time && <MassDetail mass={days[0]}/>}
                                    {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                    {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                                </div>
                            </div>
                            {days[3].place && days[3].time &&
                                <div className={pAnTblRowClass}>
                                    <div className="an-tbl-1 an-border">
                                        <span
                                            className="font-400 p-fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                    </div>
                                    <MassDetail mass={days[3]}/>
                                </div>
                            }
                        </div>

                        :

                        days[0].feast_day === days[1].feast_day ?
                            <div>
                                <div className={pAnTblRowClass}>
                                    <div className="an-tbl-1 an-border">
                                <span
                                    className="font-400 p-fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                    </div>
                                    <div className="an-tbl-col">
                                        {days[0].place && days[0].time && <MassDetail mass={days[0]}/>}
                                        {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                    </div>
                                </div>
                                {days[2].place && days[2].time &&
                                    <div className={pAnTblRowClass}>
                                        <div className="an-tbl-1 an-border">
                                            <span
                                                className="font-400 p-fa-font-feast">{renderTextWithLine(days[2].feast_day)}</span>
                                        </div>
                                        <MassDetail mass={days[2]}/>
                                    </div>
                                }
                                {days[3].place && days[3].time &&
                                    <div className={pAnTblRowClass}>
                                        <div className="an-tbl-1 an-border">
                                            <span
                                                className="font-400 p-fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                        </div>
                                        <MassDetail mass={days[3]}/>
                                    </div>
                                }
                            </div>

                            :

                            days[1].place && days[2].place && days[3].place && days[1].feast_day === days[2].feast_day && days[1].feast_day === days[3].feast_day ?
                                <div>
                                    {days[0].place && days[0].time &&
                                        <div className={pAnTblRowClass}>
                                            <div className="an-tbl-1 an-border">
                                                <span
                                                    className="font-400 p-fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                            </div>
                                            <MassDetail mass={days[0]}/>
                                        </div>
                                    }
                                    <div className={pAnTblRowClass}>
                                        <div className="an-tbl-1 an-border">
                                <span
                                    className="font-400 p-fa-font-feast">{renderTextWithLine(days[1].feast_day)}</span>
                                        </div>
                                        <div className="an-tbl-col">
                                            {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                            {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                                            {days[3].place && days[3].time && <MassDetail mass={days[3]}/>}
                                        </div>
                                    </div>
                                </div>

                                :

                                days[1].place && days[2].place && days[1].feast_day === days[2].feast_day ?
                                    <div>
                                        {days[0].place && days[0].time &&
                                            <div className={pAnTblRowClass}>
                                                <div className="an-tbl-1 an-border">
                                                    <span
                                                        className="font-400 p-fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                                </div>
                                                <MassDetail mass={days[0]}/>
                                            </div>
                                        }
                                        <div className={pAnTblRowClass}>
                                            <div className="an-tbl-1 an-border">
                                <span
                                    className="font-400 p-fa-font-feast">{renderTextWithLine(days[1].feast_day)}</span>
                                            </div>
                                            <div className="an-tbl-col">
                                                {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                                {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                                            </div>
                                        </div>
                                        {days[3].place && days[3].time &&
                                            <div className={pAnTblRowClass}>
                                                <div className="an-tbl-1 an-border">
                                                    <span
                                                        className="font-400 p-fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                                </div>
                                                <MassDetail mass={days[3]}/>
                                            </div>
                                        }
                                    </div>

                                    :

                                    <div>
                                        {days[0].place && days[0].time &&
                                            <div className={pAnTblRowClass}>
                                                <div className="an-tbl-1 an-border">
                                                    <span
                                                        className="font-400 p-fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                                </div>
                                                <MassDetail mass={days[0]}/>
                                            </div>
                                        }
                                        {days[1].place && days[1].time &&
                                            <div className={pAnTblRowClass}>
                                                <div className="an-tbl-1 an-border">
                                                    <span
                                                        className="font-400 p-fa-font-feast">{renderTextWithLine(days[1].feast_day)}</span>
                                                </div>
                                                <MassDetail mass={days[1]}/>
                                            </div>
                                        }
                                        {days[2].place && days[2].time &&
                                            <div className={pAnTblRowClass}>
                                                <div className="an-tbl-1 an-border">
                                                    <span
                                                        className="font-400 p-fa-font-feast">{renderTextWithLine(days[2].feast_day)}</span>
                                                </div>
                                                <MassDetail mass={days[2]}/>
                                            </div>
                                        }
                                        {days[3].place && days[3].time &&
                                            <div className={pAnTblRowClass}>
                                                <div className="an-tbl-1 an-border">
                                                    <span
                                                        className="font-400 p-fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                                </div>
                                                <MassDetail mass={days[3]}/>
                                            </div>
                                        }
                                    </div>
            )
        }

        return (
            <div className="p-an-table an-border-thick">
                <div className="fa-flex-row an-border-thick background-color-an-1">
                    <div className="p-an-tbl-header-day an-border p-1">
                        <span className="font-700 p-fa-font-an-header">Dátum</span>
                    </div>
                    <div className="an-tbl-mass">
                        <div className="p-an-tbl-header-row">
                            <div className="an-tbl-1 an-border p-1">
                                <span className="font-700 p-fa-font-an-header ">Liturgický prehľad</span>
                            </div>
                            <div className="p-an-tbl-header-row">
                                <div
                                    className="an-tbl-2 an-border p-1">
                                    <span className="font-700 p-fa-font-an-header">Miesto</span>
                                </div>
                                <div
                                    className="an-tbl-3 an-border">
                                    <span className="font-700 p-fa-font-an-header">Čas</span>
                                </div>
                                <div className="an-tbl-4 an-border justify-content-center">
                                <span className="font-700 p-fa-font-an-header">
                                    Úmysel svätej omše
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {data.map((day, index) => (
                    !day.empty ?
                        <div className="fa-flex-row an-border-thick" key={index}>
                            <div className="p-an-tbl-day an-border p-1">
                                <span className="font-700 p-fa-font-day">{day.dayname}</span>
                                <span
                                    className="p-fa-font-date">{formatDate(day.date)}</span>
                            </div>
                            <div className="an-tbl-mass">
                                <PrintDay days={day.mass} name={day.dayname}/>
                            </div>
                        </div>
                        :
                        <div className="fa-flex-row an-border-thick" key={index}>
                            <div className="p-an-tbl-day an-border">
                                <span className="font-700 p-fa-font-day">{day.dayname}</span>
                                <span className="font-700 p-fa-font-date">{formatDate(day.date)}</span>
                            </div>
                            <div className="an-tbl-mass an-border"/>
                        </div>
                ))}
            </div>
        );
    }

    return (
        printText === "table" || printText === "with-header" ?
            <div className="fa-flex-col mx-3 pt-4" id={`announcements-table-${id}`}>
                {printText === "with-header" && <div className="fa-flex-col justify-content-center mt-1 mb-3 mx-2">
                    <h3 className="font-900 color-2 text-center">RÍMSKOKATOLÍCKA FARNOSŤ SV. ONDREJA</h3>
                    <h4 className="font-700 color-2 text-center">PEČOVSKÁ NOVÁ VES</h4>
                    {parishInfo &&
                        <>
                            {/*<h5 className="font-400 color-2 text-center">{`${parishInfo.address}; 082 56`}</h5>*/}
                            <div className="fa-flex-row justify-content-around mt-2">
                                <div className="fa-flex-row">
                                    <h5 className="font-700 color-2 me-1">mobil:</h5>
                                    <h5 className="font-400 color-2">{parishInfo.priest_phone_number}</h5>
                                </div>
                                <div className="fa-flex-row">
                                    <h5 className="font-700 color-2 me-1">email:</h5>
                                    <h5 className="font-400 color-2">{parishInfo.email}</h5>
                                </div>
                            </div>
                        </>
                    }
                </div>}
                {image.length > 0 ?
                    <img src={image}
                         alt="oznamy"
                         className="w-100"/>
                    :
                    <TableToPrint data={data} id={id}/>
                }
                <h5 className="font-900 color-2 text-center mt-3">www.farnostpecovska.sk</h5>
            </div>
            :
            <div className="fa-flex-col mx-3 pt-4" id={`announcements-table-${id}`}>
                <WeekText title={title} texts={texts} cleaning={cleaning} showHeader={true}/>
            </div>
    );
};

export default AnnouncementsPDF;