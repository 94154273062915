import React from 'react';
import {AppContainer} from "../components/AppContainer";

const PageNotFound = () => {
    return (
        <AppContainer
            title={"Stránka sa nenašla"}
            content={
                <div className="window-content-container"/>
            }
        />
    );
};

export default PageNotFound;