import React from 'react';
import "../assets/css/AnnouncementsMass.css"
import {formatDate} from "./formatDate";
import {formatTime} from "./formatTime";
import {renderTextWithLine} from "./renderTextWithLine";

const AnnouncementsTable = ({data}) => {


    const PrintDay = ({days}) => {

        const MassDetail = ({mass}) => {
            return (
                <div className="an-tbl-row">
                    <div
                        className={`an-tbl-2 an-border p-1 ${mass.place !== "PNV" ? "an-color" : ""}`}>
                        <span className="font-700 fa-font-place">{mass.place}</span>
                    </div>
                    <div
                        className={`an-tbl-3 an-border p-1 ${mass.place !== "PNV" ? "an-color" : ""}`}>
                        <span className="fa-font-time">{mass.time}</span>
                    </div>
                    {mass.confession_start ?
                        <div className={`an-tbl-4 an-border p-1 ${mass.place !== "PNV" ? "an-color" : ""}`}>
                            <span className="fa-font-intention ps-2">
                                {mass.intention.length !== 0 ? mass.intention : ""}
                            </span>
                            <span className="font-400 fa-font-confession pe-2">
                                {`sv. zmierenia: ${formatTime(mass.confession_start)} - ${formatTime(mass.confession_end)}`}
                            </span>
                        </div>
                        :
                        <div
                            className={`an-tbl-4 justify-content-center an-border p-1 ${mass.place !== "PNV" ? "an-color" : ""}`}>
                            <span className="fa-font-intention ps-2">
                                {mass.intention.length !== 0 ? mass.intention : ""}
                            </span>
                        </div>
                    }
                </div>
            )
        }

        return (
            days[0].feast_day === days[1].feast_day && days[0].feast_day === days[2].feast_day && days[0].feast_day === days[3].feast_day ?
                <div>
                    <div className="an-tbl-row">
                        <div className="an-tbl-1 an-border p-2">
                                <span
                                    className="font-400 fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                        </div>
                        <div className="an-tbl-col">
                            {days[0].place && days[0].time && <MassDetail mass={days[0]}/>}
                            {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                            {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                            {days[3].place && days[3].time && <MassDetail mass={days[3]}/>}
                        </div>
                    </div>
                </div>

                :

                days[0].feast_day === days[1].feast_day && days[0].feast_day === days[2].feast_day ?
                    <div>
                        <div className="an-tbl-row">
                            <div className="an-tbl-1 an-border p-2">
                                <span
                                    className="font-400 fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                            </div>
                            <div className="an-tbl-col">
                                {days[0].place && days[0].time && <MassDetail mass={days[0]}/>}
                                {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                            </div>
                        </div>
                        {days[3].place && days[3].time &&
                            <div className="an-tbl-row">
                                <div className="an-tbl-1 an-border p-1">
                                    <span
                                        className="font-400 fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                </div>
                                <MassDetail mass={days[3]}/>
                            </div>
                        }
                    </div>

                    :

                    days[0].feast_day === days[1].feast_day ?
                        <div>
                            <div className="an-tbl-row">
                                <div className="an-tbl-1 an-border p-2">
                                <span
                                    className="font-400 fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                </div>
                                <div className="an-tbl-col">
                                    {days[0].place && days[0].time && <MassDetail mass={days[0]}/>}
                                    {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                </div>
                            </div>
                            {days[2].place && days[2].time &&
                                <div className="an-tbl-row">
                                    <div className="an-tbl-1 an-border p-2">
                                        <span
                                            className="font-400 fa-font-feast">{renderTextWithLine(days[2].feast_day)}</span>
                                    </div>
                                    <MassDetail mass={days[2]}/>
                                </div>
                            }
                            {days[3].place && days[3].time &&
                                <div className="an-tbl-row">
                                    <div className="an-tbl-1 an-border p-2">
                                        <span
                                            className="font-400 fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                    </div>
                                    <MassDetail mass={days[3]}/>
                                </div>
                            }
                        </div>

                        :

                        days[1].place && days[2].place && days[3].place && days[1].feast_day === days[2].feast_day && days[1].feast_day === days[3].feast_day ?
                            <div>
                                {days[0].place && days[0].time &&
                                    <div className="an-tbl-row">
                                        <div className="an-tbl-1 an-border p-2">
                                            <span
                                                className="font-400 fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                        </div>
                                        <MassDetail mass={days[0]}/>
                                    </div>
                                }
                                <div className="an-tbl-row">
                                    <div className="an-tbl-1 an-border p-2">
                                <span
                                    className="font-400 fa-font-feast">{renderTextWithLine(days[1].feast_day)}</span>
                                    </div>
                                    <div className="an-tbl-col">
                                        {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                        {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                                        {days[3].place && days[3].time && <MassDetail mass={days[3]}/>}
                                    </div>
                                </div>
                            </div>

                            :

                            days[1].place && days[2].place && days[1].feast_day === days[2].feast_day ?
                                <div>
                                    {days[0].place && days[0].time &&
                                        <div className="an-tbl-row">
                                            <div className="an-tbl-1 an-border p-2">
                                                <span
                                                    className="font-400 fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                            </div>
                                            <MassDetail mass={days[0]}/>
                                        </div>
                                    }
                                    <div className="an-tbl-row">
                                        <div className="an-tbl-1 an-border p-2">
                                <span
                                    className="font-400 fa-font-feast">{renderTextWithLine(days[1].feast_day)}</span>
                                        </div>
                                        <div className="an-tbl-col">
                                            {days[1].place && days[1].time && <MassDetail mass={days[1]}/>}
                                            {days[2].place && days[2].time && <MassDetail mass={days[2]}/>}
                                        </div>
                                    </div>
                                    {days[3].place && days[3].time &&
                                        <div className="an-tbl-row">
                                            <div className="an-tbl-1 an-border p-2">
                                                <span
                                                    className="font-400 fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                            </div>
                                            <MassDetail mass={days[3]}/>
                                        </div>
                                    }
                                </div>

                                :

                                <div>
                                    {days[0].place && days[0].time &&
                                        <div className="an-tbl-row">
                                            <div className="an-tbl-1 an-border p-2">
                                                <span
                                                    className="font-400 fa-font-feast">{renderTextWithLine(days[0].feast_day)}</span>
                                            </div>
                                            <MassDetail mass={days[0]}/>
                                        </div>
                                    }
                                    {days[1].place && days[1].time &&
                                        <div className="an-tbl-row">
                                            <div className="an-tbl-1 an-border p-2">
                                                <span
                                                    className="font-400 fa-font-feast">{renderTextWithLine(days[1].feast_day)}</span>
                                            </div>
                                            <MassDetail mass={days[1]}/>
                                        </div>
                                    }
                                    {days[2].place && days[2].time &&
                                        <div className="an-tbl-row">
                                            <div className="an-tbl-1 an-border p-2">
                                                <span
                                                    className="font-400 fa-font-feast">{renderTextWithLine(days[2].feast_day)}</span>
                                            </div>
                                            <MassDetail mass={days[2]}/>
                                        </div>
                                    }
                                    {days[3].place && days[3].time &&
                                        <div className="an-tbl-row">
                                            <div className="an-tbl-1 an-border p-2">
                                                <span
                                                    className="font-400 fa-font-feast">{renderTextWithLine(days[3].feast_day)}</span>
                                            </div>
                                            <MassDetail mass={days[3]}/>
                                        </div>
                                    }
                                </div>
        )
    }

    return (
        <div className="an-border-thick">
            <div className="fa-flex-row an-border-thick background-color-an-1">
                <div className="an-tbl-header-day an-border p-1">
                    <span className="font-700 fa-font-an-header">Dátum</span>
                </div>
                <div className="an-tbl-mass">
                    <div className="an-tbl-header-row">
                        <div className="an-tbl-1 an-border p-1">
                            <span className="font-700 fa-font-an-header ">Liturgický prehľad</span>
                        </div>
                        <div className="an-tbl-header-row">
                            <div
                                className="an-tbl-2 an-border p-1">
                                <span className="font-700 fa-font-an-header">Miesto</span>
                            </div>
                            <div
                                className="an-tbl-3 an-border">
                                <span className="font-700 fa-font-an-header">Čas</span>
                            </div>
                            <div className="an-tbl-4 an-border justify-content-center">
                                <span className="font-700 fa-font-an-header">
                                    Úmysel svätej omše
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {data.map((day, index) => (
                !day.empty ?
                    <div className="fa-flex-row an-border-thick" key={index}>
                        <div className="an-tbl-day an-border p-1">
                            <span className="font-700 fa-font-day">{day.dayname}</span>
                            <span className="font-700 fa-font-date">{formatDate(day.date)}</span>
                        </div>
                        <div className="an-tbl-mass">
                            <PrintDay days={day.mass} name={day.dayname}/>
                        </div>
                    </div>
                    :
                    <div className="fa-flex-row an-border-thick" key={index}>
                        <div className="an-tbl-day an-border">
                            <span className="font-700 fa-font-day">{day.dayname}</span>
                            <span className="font-700 fa-font-date">{formatDate(day.date)}</span>
                        </div>
                        <div className="an-tbl-mass an-border"/>
                    </div>
            ))}
        </div>
    );
};

export default AnnouncementsTable;