import React, {useEffect, useState} from "react";
import "../assets/css/App.css";
import "../assets/css/Header.css"
import {Fade} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Navbar from "../components/Navbar.js";

function Header(props) {

    const [coverPhotos, setCoverPhotos] = useState([])

    useEffect(() => {
        getCoverPhotos()
    }, []);

    function getCoverPhotos() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getCoverPhotos`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setCoverPhotos(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function SlideImages() {
        return (
            props.title === undefined ?
                <Fade cssClass={"header-fade-container"} duration={5000} pauseOnHover={false} arrows={false}
                      autoplay={true}>
                    {coverPhotos !== undefined ? coverPhotos.map((item, index) => (
                                item?.url?.length > 0 && item?.visible &&
                                <div className="each-slide-fade" key={index}>
                                    <img
                                        src={item.url} className="slide-img-cover" alt="cover_image"/>
                                </div>
                            )
                        )
                        :
                        <div className="each-slide-fade">
                            <img src={"https://i.ibb.co/Y2YBHcx/titulna-1.jpg"} className="slide-img-cover"
                                 alt="cover_image"/>
                        </div>
                    }
                </Fade>
                :
                <div className="header-img-container position-relative">
                    <img src={"https://i.ibb.co/d7SGM9B/titulna-3.jpg"} alt="cover_image" className="slide-img-cover"/>
                    <div className="overlay-box p-2">
                        <h1 className="font-800 color-1 w-100 text-center">{props.title}</h1>
                    </div>
                </div>
        )
    }

    return (
        <div className="header-container fa-flex-col w-100">
            <Navbar/>
            <SlideImages/>
        </div>
    );
};

export default Header;