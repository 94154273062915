import {createSlice} from "@reduxjs/toolkit";

export const coverPhotoSlice = createSlice({
    name: "coverPhotoList",
    initialState: {
        value: [],
    },
    reducers: {
        setCoverPhotoList: (state, action) => {
            state.value = action.payload;
        },
        addEmptyCoverPhoto: (state) => {
            state.value.push(
                {
                    "url": "",
                    "title": "",
                    "visible": false,
                    "order": "99"
                }
            );
        },
        removeCoverPhotoByIndex: (state, action) => {
            const indexToRemove = action.payload;
            if (indexToRemove >= 0 && indexToRemove < state.value.length) {
                state.value.splice(indexToRemove, 1);
            }
        },
        updateCoverPhotoAtIndex: (state, action) => {
            const {index, newObject} = action.payload;
            if (index >= 0 && index < state.value.length) {
                state.value[index] = newObject;
            }
        },
    },
});

export const {
    setCoverPhotoList,
    addEmptyCoverPhoto,
    removeCoverPhotoByIndex,
    updateCoverPhotoAtIndex
} = coverPhotoSlice.actions;

export default coverPhotoSlice.reducer;


