import React, {useEffect, useState} from 'react';
import {AppContainer} from "../components/AppContainer";
import "../assets/css/Admin.css"
import "../assets/css/App.css"
import {useNavigate} from "react-router-dom";
import AdminMassSchedule from "../components/AdminMassSchedule";
import AdminLecturersSchedule from "../components/AdminLecturersSchedule";
import AdminPsalmistsSchedule from "../components/AdminPsalmistsSchedule";
import AdminDeveloper from "../components/AdminDeveloper";
import AdminEditArticles from "../components/AdminEditArticles";
import AdminPhotoGallery from "../components/AdminPhotoGallery";
import AdminEditInformation from "../components/AdminEditInformation";
import AdminEditSubpages from "../components/AdminEditSubpages";
import {ACCESS_TOKEN_KEY} from "../constants";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {Images} from "../constants/Images";

const AdminPanel = (props) => {

    const navigate = useNavigate();
    const [accessRights, setAccessRights] = useState()
    const [userName, setUserName] = useState("")
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null
            ? localStorage.getItem("theme")
            : "light"
    );

    useEffect(() => {
        if (localStorage.getItem(ACCESS_TOKEN_KEY) === null) navigate("/");
        getUserInfo()

    }, []);

    function getUserInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setUserName(data.userName)
                setAccessRights(data.userRights)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    const MainButtons = () => {

        const AdminBtn = (props) => {

            const style = props.disabled ? 'btn-secondary btn-admin-panel mx-3 my-2 disabled' : 'btn-secondary btn-admin-panel mx-3 my-2'
            const sections = [
                'mass-schedule',
                'lecturers-schedule',
                'psalmists-schedule',
                'developer',
                'edit-articles',
                'view-photogallery',
                'edit-information',
                'edit-subpages'
            ]
            const handleSectionChange = (sectionId) => {
                for (let i = 0; i < sections.length; i++) {
                    if (sectionId === sections[i]) {
                        document.getElementById(sections[i]).style.display = "flex"
                    } else {
                        document.getElementById(sections[i]).style.display = "none"
                    }
                }
                document.getElementById('admin-buttons').style.display = "none"
            };

            return (
                <button className={style}
                        onClick={() => handleSectionChange(props.id)}>
                    <div className="fa-flex-row align-items-center justify-content-start">
                        <img
                            src={props.icon}
                            className="fa-icon-big me-4"
                            alt="icon"
                        />
                        <div className="">
                            <h5 className="font-700 color-3 text-start mt-1">{props.title}</h5>
                            <h5 className="font-400 color-3 text-start">{props.subtitle}</h5>
                        </div>
                    </div>
                </ button>
            )
        }
        return (
            <div className="fa-flex-col" id="admin-buttons">
                {accessRights !== undefined ?
                    <>
                        <h5 className="font-600 color-3 w-100 text-center mt-3">{`Vitajte späť ${userName}!`}</h5>
                        {new Date().toISOString().split('T')[0] === "2023-12-15" ||
                        new Date().toISOString().split('T')[0] === "2023-12-15" ||
                        new Date().toISOString().split('T')[0] === "2023-12-15" ?
                            <div
                                className="fa-flex-col w-100 align-items-center justify-content-center bg-success py-2">
                                <h5 className="font-700 color-3 text-center mt-1">{`Aplikácia bola aktualizovaná! Aktuálna verzia: 1.2.2 (15.12.2023)`}</h5>
                                <h6 className="font-400 color-3 text-center">{`formátovanie umyslov - možnosť nového alebo prázdneho riadku`}</h6>
                                <h6 className="font-400 color-3 text-center">{`oprava liturgických spomienok v advente/vianoce`}</h6>
                            </div>
                            :
                            <div/>
                        }
                        <div className="btn-admin-panel-section justify-content-center align-items-center my-4">
                            <AdminBtn title={"ROZPIS"} subtitle={"sv. omší"} icon={Images.TABLES_BLUE}
                                      id={'mass-schedule'}
                                      disabled={!accessRights.includes('m')}
                            />
                            <AdminBtn title={"ROZPIS"} subtitle={"lektorov"} icon={Images.TABLES_BLUE}
                                      id={'lecturers-schedule'}
                                      disabled={!accessRights.includes('l')}
                            />
                            <AdminBtn title={"ROZPIS"} subtitle={"žalmistov"} icon={Images.TABLES_BLUE}
                                      id={'psalmists-schedule'}
                                      disabled={!accessRights.includes('p')}
                            />
                            <AdminBtn title={"ADMIN"} subtitle={"aplikácie"} icon={Images.USER_BLUE}
                                      id={'developer'}
                                      disabled={!accessRights.includes('d')}
                            />
                            <AdminBtn title={"EDITÁCIA"} subtitle={"článkov"} icon={Images.EDIT_BLUE}
                                      id={'edit-articles'}
                                      disabled={!accessRights.includes('e')}
                            />
                            <AdminBtn title={"EDITÁCIA"} subtitle={"podstránok"} icon={Images.WRITING_BLUE}
                                      id={'edit-subpages'}
                                      disabled={!accessRights.includes('s')}
                            />
                            <AdminBtn title={"EDITÁCIA"} subtitle={"informácií"} icon={Images.QUOTES_BLUE}
                                      id={'edit-information'}
                                      disabled={!accessRights.includes('i')}/>
                            <AdminBtn title={"FOTOGALÉRIA"} subtitle={"farnosti"} icon={Images.PHOTOGALLERY_BLUE}
                                      id={'view-photogallery'}
                                      disabled={!accessRights.includes('g')}/>
                        </div>
                    </>
                    :
                    <div className="app-container mt-5">
                        <LoadingSpinner/>
                    </div>
                }
            </div>
        )
    }

    return (
        <AppContainer
            title={"Administrátorske rozhranie"}
            content={
                <div className="window-content-container-full mb-3" id="phone-not-shown">
                    <MainButtons/>
                    <AdminMassSchedule/>
                    <AdminLecturersSchedule/>
                    <AdminPsalmistsSchedule/>
                    <AdminDeveloper/>
                    <AdminEditArticles/>
                    <AdminPhotoGallery/>
                    <AdminEditInformation/>
                    <AdminEditSubpages/>
                </div>
            }
        />
    );
};

export default AdminPanel;