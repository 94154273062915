import "../assets/css/App.css";
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import React from "react";

export const confirmAlertDialog = (
    title,
    message,
    theme,
    buttonsType,
    clickYes
) => {
    confirmAlert({
        customUI: ({onClose}) => {
            return (
                <div
                    className="custom-dialog-container p-4 mx-4"
                    data-theme={theme}
                >
                    <h4 className="font-700 text-center h-25">{title}</h4>
                    <h5 className="font-400 text-center h-50 w-75 mt-2 mb-2">
                        {message}
                    </h5>
                    <div className="h-25 mt-5 d-flex justify-content-center gap-3">
                        {buttonsType === "Ok" ? (
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Ok
                            </button>
                        ) : (
                            <>
                                <button
                                    className="btn-primary"
                                    onClick={() => {
                                        clickYes();
                                        onClose();
                                    }}
                                >
                                    Áno
                                </button>
                                <button className="btn-primary" onClick={() => onClose()}>
                                    Nie
                                </button>
                            </>
                        )}
                    </div>
                </div>
            );
        },
    });
};
