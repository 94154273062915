export const getNextDate = (start_date, next) => {
    if (start_date !== "" && start_date !== undefined) {
        const startDateObj = new Date(start_date);
        const monday = new Date(startDateObj)
        const sunday = new Date(startDateObj)
        sunday.setDate(monday.getDate() + next)

        return sunday.toISOString().slice(0, 10)
    } else {
        return ""
    }
}