import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/App.css";
import "../assets/css/Icons.css";
import {AppContainer} from "../components/AppContainer";
import {Images} from "../constants/Images";
import {useNavigate} from "react-router-dom";
import ContactsTab from "../components/ContactsTab";
import ButtonIcon from "../components/ButtonIcon";
import AnnouncementsTable from "../components/AnnouncementsTable";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {Modal} from "react-bootstrap";
import AnnouncementsPDF from "../components/AnnouncementsPDF";
import {useReactToPrint} from "react-to-print";
import {toastError} from "../components/toastError";
import {SectionBanksAccounts} from "../components/SectionBanksAccounts";
import {WeekText} from "../components/WeekText";
import {toastInfo} from "../components/toastInfo";
import {LoadingSpinner} from "../components/LoadingSpinner";

function Introduction() {

    const navigate = useNavigate();
    const [allAnnouncements, setAllAnnouncements] = useState([])
    const [parishInfo, setParishInfo] = useState()
    const [modalFinanceShown, setModalFinanceShown] = useState(false)
    const [modalPDFShown, setModalPDFShown] = useState(false)
    const [modalPDFIdx, setModalPDFIdx] = useState(null)
    const [modalPDFType, setModalPDFType] = useState(null)
    const [articles, setArticles] = useState([])
    const [photoGalleries, setPhotoGalleries] = useState([])
    const [printText, setPrintText] = useState("")
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getVisibleAnnouncements()
        getParishInfo()
        getVisibleArticles()
        getLastPhotoGalleries()
        updateCounter("all")
    }, []);

    function getTodayMassFromAllVisible(data) {
        const todayDate = new Date().toISOString().split('T')[0];
        for (const item of data) {
            for (const day of item.days) {
                if (day.date === todayDate) {
                    return day;
                }
            }
        }

        return null;
    }

    function getLastPhotoGalleries() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getLastPhotoGalleries`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setPhotoGalleries(data)
            })
            .catch((error) => {
                toastError("Chyba pri načitávaní obrázkov!")
                return error;
            });
    }

    function updateCounter(type) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/updateCounter/${type}`, {})
            .then((res) => {
                return res.json();
            })
    }

    function updateNumOfPrinted1(id) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/updateAnnouncementsNumOfPrinted1/${id}`, {method: "POST"})
            .then((res) => {
                return res.json();
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function updateNumOfPrinted2(id) {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/updateAnnouncementsNumOfPrinted2/${id}`, {method: "POST"})
            .then((res) => {
                return res.json();
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }


    function getVisibleAnnouncements() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getVisibleAnnouncements`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllAnnouncements(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function getParishInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getParishInfo`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setParishInfo(data)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    function getVisibleArticles() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getVisibleArticles`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setArticles(data)
            })
            .catch((error) => {
                toastError("Chyba pri načitávaní článkov!")
                return error;
            });
    }

    function InfoPanel() {
        const ActualityItem = ({article}) => {
            return (
                <div className="actuality-item fa-flex-row mx-2 my-1" onClick={() => {
                    navigate("/aktuality/" + article._id)
                }}>
                    <div className="fa-flex-col align-items-center justify-content-start me-3">
                        <img
                            src={Images.RECORD_FULL_WHITE}
                            className="fa-icon-small"
                            alt="icon"
                            id="phone-not-shown"
                        />
                    </div>
                    <div className="overflow-hidden mt-1 mb-0 w-100">
                        <h5 className="font-700 color-1">{article.title}</h5>
                        <h6 className="desktop-row-phone-col justify-content-between color-1">
                            <span className="font-300">{article.author + " " + article.date}</span>
                            <span className="font-300 mt-2">{"Počet prečítaní: " + article.views}</span>
                        </h6>
                    </div>
                </div>
            )
        }

        return (
            <>
                <div className="desktop-row-phone-col justify-content-center">
                    <div className="contacts-tab fa-flex-col pt-4" id="phone-not-shown">
                        {parishInfo &&
                            <ContactsTab contactData={parishInfo}
                                         massData={getTodayMassFromAllVisible(allAnnouncements)} footer={false}/>}
                    </div>
                    <div className="actuality-tab fa-flex-col pt-4 pb-1">
                        <h3 className="font-800 color-1 text-center w-100">Aktuality</h3>

                        <div className="fa-flex-col justify-content-between h-100">
                            <div className="fa-flex-col mt-1 me-3 ms-3">
                                {articles.length > 0 ?
                                    articles.slice(0, 5).map((article, index) =>
                                        <ActualityItem key={index} article={article}/>
                                    )
                                    :
                                    <div/>
                                }
                            </div>

                            {articles.length > 5 &&
                                <div className="btn-text text-center font-500 color-1 my-2"
                                     onClick={() => navigate("/aktuality")}>
                                    Zobraziť archív aktualít
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="fa-flex-col align-items-center my-4">
                    <h6 className="font-700 color-3 text-center w-75">
                        Iba s vašou pomocou môžeme naďalej rozvíjať našu farnosť.
                        Každý váš milodar nám pomáha budovať našu farnosť a zabezpečiť jej udržateľnosť.
                        Vďaka vám môžeme pokračovať v našej službe pre vás.
                    </h6>
                    <div className="fa-flex-row mt-4 justify-content-center">
                        <ButtonIcon icon={Images.HAND_BLUE} title="Chcem prispieť"
                                    action={() => setModalFinanceShown(!modalFinanceShown)}
                                    border={""}
                                    backgroundIcon={'background-color-1'}
                                    backgroundText={'background-color-3'}
                        />
                    </div>
                </div>
            </>
        )
    }

    function GalleryPanel() {

        const Gallery = ({photoGallery}) => {

            function handleAlbumOpen(g_id, g_views, url) {
                const data = {
                    views: g_views
                }

                fetch(`${process.env.REACT_APP_API_BASE_URL}/updatePhotoGalleryViews/${g_id}`, {
                    method: "POST",
                    headers: new Headers({
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }),
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((res) => {
                        getLastPhotoGalleries()
                        window.open(url, "_blank", "noreferrer");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

            return (
                <div className="fa-flex-col justify-content-between photoGallery-item rounded-4 m-3 p-1"
                     onClick={() => handleAlbumOpen(photoGallery._id, photoGallery.views, photoGallery.album_url)}
                >
                    <div className="photoGallery-img-container">
                        <img
                            src={photoGallery.image_url.length > 0 ? photoGallery.image_url : "https://i.ibb.co/z24Mq1P/titulna-6.jpg"}
                            className="photoGallery-image"/>
                    </div>
                    <div className="photoGallery-content-container mx-1 mt-2">
                        <span className="font-700 color-3 photogallery-title">{photoGallery.title}</span>
                        <div className="fa-flex-row align-items-center justify-content-end">
                            <img src={Images.EYE_BLUE} className="fa-icon-btn-sm me-2" alt="icon"/>
                            <span className="font-400-lato color-3">{`${photoGallery.views}x`}</span>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className='fa-flex-col'>
                <h2 className="font-900-lato color-3 text-center mb-4">Farská fotogaléria</h2>
                <div className='photoGallery-section justify-content-center'>
                    {photoGalleries.length > 0 &&
                        photoGalleries.map((gallery, index) => (
                            <Gallery key={index} photoGallery={gallery}/>
                        ))
                    }
                </div>
                <div className="btn-text text-center font-550 color-3 my-2"
                     onClick={() => navigate("/fotogaleria")}>
                    Zobraziť viac fotografií
                </div>
            </div>
        )
    }

    function MassSchedule() {
        const WeekSchedule = ({announcements}) => {

            return (
                <div className="mb-5">
                    <h1 className="font-900-lato color-3 text-center mb-4">{announcements.title}</h1>
                    <div className="announcements-tab">
                        {announcements.image_url.length > 5 ?
                            <img src={announcements.image_url}
                                 alt="oznamy"
                                 className="w-100"/>
                            :
                            <AnnouncementsTable data={announcements.days}/>
                        }
                        <WeekText title={announcements.title} texts={announcements.texts}
                                  cleaning={announcements.cleaning} showHeader={false} print={false}/>
                        <div className="fa-flex-row mt-4 justify-content-center gap-3 phone-not-shown">
                            {/*<ButtonIcon icon={Images.DOWNLOAD_PDF_WHITE}*/}
                            {/*            title={"Stiahnuť PDF"}*/}
                            {/*            action={() => {*/}
                            {/*                setModalPDFShown(!modalPDFShown)*/}
                            {/*                setModalPDFIdx(announcements._id)*/}
                            {/*                setModalPDFType("download")*/}
                            {/*            }}*/}
                            {/*            disableCondition={null}/>*/}
                            <ButtonIcon icon={Images.PRINTER_WHITE} title="Vytlačiť sv. omše"
                                        action={() => {
                                            setModalPDFShown(!modalPDFShown)
                                            setModalPDFIdx(announcements._id)
                                            setModalPDFType("print")
                                            setPrintText('with-header')
                                            updateNumOfPrinted1(announcements._id)
                                        }}
                                        disableCondition={null}
                            />
                            <ButtonIcon icon={Images.PRINTER_WHITE} title="Vytlačiť oznamy"
                                        action={() => {
                                            setModalPDFShown(!modalPDFShown)
                                            setModalPDFIdx(announcements._id)
                                            setModalPDFType("print")
                                            setPrintText('text')
                                            updateNumOfPrinted2(announcements._id)
                                        }}
                                        disableCondition={null}
                            />
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="fa-flex-col align-items-center mt-5 w-100">
                {/*<h2 className="font-900 color-3 text-center">Farské oznamy</h2>*/}
                {allAnnouncements.map((item, index) => (
                    item.isVisible ?
                        <WeekSchedule key={index} announcements={item}/>
                        :
                        <div/>
                ))}
            </div>
        )
    }

    const ModalPdf = (props) => {

        const [loader, setLoader] = useState(false)


        function announcementsById(element) {
            const announcement = allAnnouncements.find(item => item._id === modalPDFIdx)
            if (announcement !== undefined)
                if (element === "title")
                    return announcement.title
                else if (element === "days")
                    return announcement.days
                else if (element === "image_url")
                    return announcement.image_url
                else if (element === "texts")
                    return announcement.texts
                else if (element === "cleaning")
                    return announcement.cleaning

            return []
        }

        function countMass() {
            const tableDays = announcementsById('days');
            let count = 0;

            for (const day of tableDays) {
                if (day && day.empty !== true) {
                    for (const mass of day.mass) {
                        if (mass && mass.place && mass.time) {
                            count++;
                        }
                    }
                } else count++
            }
            return count === 0 || count > 15 ? 15 : count;
        }

        const handlePdfDownload = () => {
            const capture = document.querySelector(`#announcements-table-${modalPDFIdx}`)
            setLoader(true)
            html2canvas(capture).then((canvas) => {
                const imgData = canvas.toDataURL('.img/png')
                const doc = new jsPDF('p', 'mm', 'a4')
                const pageWidth = doc.internal.pageSize.getWidth()
                const pageHeight = doc.internal.pageSize.getHeight()
                const componentWidth = pageWidth * 0.95
                const componentHeight = pageHeight * (countMass() * 0.075)
                doc.addImage(imgData, 'PNG', pageWidth * 0.025, pageHeight * 0.01, componentWidth, componentHeight)
                doc.setProperties({
                    title: 'Farnosť Pečovská Nová Ves',
                    subject: 'Oznamy',
                    author: 'Jozef Kandráč',
                    keywords: 'react',
                    creator: 'M&D'
                });
                doc.save('farske_oznamy.pdf')
                setLoader(false)
                setModalPDFShown(false)
            })
        }

        const handlePrint = useReactToPrint({
            content: () => document.querySelector(`#announcements-table-${modalPDFIdx}`),
            documentTitle: 'farske_oznamy',
            onAfterPrint: () => toastInfo("Tlač oznamov bola ukončená!"),
            onPrintError: () => toastError("Oznami sa nepodarilo vytlačiť!")
        })


        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-pdf p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                    {announcementsById('image_url').length > 5 ?
                        <AnnouncementsPDF title={announcementsById('title')} data={announcementsById('days')}
                                          id={modalPDFIdx}
                                          image={announcementsById('image_url')} texts={announcementsById('texts')}
                                          cleaning={announcementsById('cleaning')}
                                          printText={printText}/>
                        :
                        <AnnouncementsPDF title={announcementsById('title')} data={announcementsById('days')}
                                          id={modalPDFIdx}
                                          image={""} texts={announcementsById('texts')}
                                          cleaning={announcementsById('cleaning')}
                                          printText={printText}/>
                    }
                    <div className="fa-flex-row justify-content-center mt-3">
                        {modalPDFType === "download" ?
                            <ButtonIcon icon={Images.DOWNLOAD_PDF_WHITE}
                                        title={loader ? "Sťahujem..." : "Stiahnuť PDF"}
                                        action={() => handlePdfDownload()}
                                        disableCondition={!(loader === false)}/>
                            :
                            <ButtonIcon icon={Images.PRINTER_WHITE} title="Vytlačiť"
                                        action={() => handlePrint()}
                                        disableCondition={!(loader === false)}/>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    const ModalFinance = (props) => {

        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-finance p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-4">
                    <>
                        <div className="fa-flex-row justify-content-end gap-2 w-100">
                            <button className="admin-icon-btn mx-1"
                                    onClick={() => setModalFinanceShown(false)}>
                                <img
                                    src={Images.CLOSE_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                        <h3 className={`font-700 text-center mx-2 mb-5 color-3}`}>Čísla účtov</h3>
                    </>
                    {parishInfo !== undefined &&
                        <SectionBanksAccounts data={parishInfo} textColor={'color-3'} icon={Images.BANK_BLUE}/>
                    }
                    <h5 className={`font-900 text-center mx-2 mt-5 mb-4 color-3`}>ĎAKUJEME!</h5>
                </Modal.Body>
            </Modal>
        )
    }


    return (
        <AppContainer
            content={
                <div className="window-content-container">
                    <ModalPdf show={modalPDFShown} onHide={() => setModalPDFShown(false)}/>
                    <ModalFinance show={modalFinanceShown} onHide={() => setModalFinanceShown(false)}/>
                    {parishInfo !== undefined ?
                        <>
                            <InfoPanel/>
                            <MassSchedule/>
                            <GalleryPanel/>
                        </>
                        :
                        <div className="app-container mt-5">
                            <LoadingSpinner/>
                        </div>
                    }
                </div>
            }
        />
    );
}

export default Introduction;