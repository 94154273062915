import "../assets/css/App.css";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import Footer from "./Footer";
import Header from "./Header";
import {ToastContainer} from "react-toastify";
import ScrollToTop from "react-scroll-to-top";
import {Images} from "../constants/Images";

export const AppContainer = (props) => {
    const navigate = useNavigate();
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null
            ? localStorage.getItem("theme")
            : "light"
    );
    return (
        <div className="app-container fa-flex-col" data-theme={theme}>
            <ScrollToTop
                top={250}
                smooth={true}
                component={<img src={Images.TOP_ARROW_WHITE} className="fa-icon-btn-bg mb-1"/>}
                className="scroll-to-top-btn mb-5 phone-not-shown"
            />
            <Header title={props.title}/>
            <div className="my-3">
                {props.content}
            </div>
            <ToastContainer/>
            <Footer/>
        </div>
    );
};
