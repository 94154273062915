export const getCountOfMasses = (week, data) => {

    let count = 0;

    if (week) {
        if (data !== undefined) {
            for (const day of data) {
                if (day && day.empty !== true) {
                    for (const mass of day.mass) {
                        if (mass && mass.place && mass.time) {
                            count++;
                        }
                    }
                } else count++
            }
        }

    } else {
        if (data !== undefined) {
            for (const mass of data) {
                if (mass && mass.place && mass.time) {
                    count++;
                }
            }
        }
    }

    return count;

};