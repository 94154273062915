import React from 'react';
import 'react-quill/dist/quill.bubble.css'

const TextEditor = ({value}) => {

    function convertReactQuillToHTML(reactQuillHTML) {
        if (reactQuillHTML)
            return reactQuillHTML
                .replace(/<p class="ql-align-(\w+)">/g, (match, alignment) => `<p style="text-align: ${alignment};">`)
                .replace(/<span class="ql-size-(\w+)" style="color: (.*?);">/g, (match, size, color) => `<span style="font-size: ${size}; color: ${color};">`)
                .replace(/<strong class="ql-size-(\w+)" style="color: (.*?);">/g, (match, size, color) => `<span style="font-size: ${size}; font-weight: 700; color: ${color};">`)
                .replace(/<em class="ql-size-(\w+)" style="color: (.*?);">/g, (match, size, color) => `<em style="font-size: ${size}; color: ${color};">`)
                .replace(/<h3 class="ql-align-center">/g, '<h3 style="text-align: center;">')
                .replace(/<h([1-6]) class="ql-align-center">/g, (match, level) => `<h${level} style="text-align: center;">`);

    }

    return (
        <div className="w-100">
            <div dangerouslySetInnerHTML={{__html: convertReactQuillToHTML(value)}}/>
        </div>
        // <div>
        //     <ReactQuill
        //         className="background-color-1 mt-3"
        //         value={value}
        //         readOnly={true}
        //         theme={"bubble"}
        //     />
        // </div>
    );
};

export default TextEditor;