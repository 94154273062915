import React, {useEffect, useState} from 'react';
import SectionHeading from "./SectionHeading";
import {confirmAlertDialog} from "./confirmAlertDialog";
import {toastSuccess} from "./toastSuccess";
import {toastError} from "./toastError";
import {ACCESS_TOKEN_KEY} from "../constants";
import {Images} from "../constants/Images";
import {Modal} from "react-bootstrap";
import Select from "react-select";

const AdminEditInformation = () => {

    const [parishInfo, setParishInfo] = useState()
    const [allPeople, setAllPeople] = useState([])
    const [allGroups, setAllGroups] = useState()
    const [modalPeopleShown, setModalPeopleShown] = useState(false)
    const [modalGroupsShown, setModalGroupsShown] = useState(false)
    const [person, setPerson] = useState(null)
    const [group, setGroup] = useState(null)
    const [cleaningOptions, setCleaningOptions] = useState([{value: "", label: ""}]);
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getAllPeople()
        getAllGroups()
        getParishInfo()
    }, []);

    function getAllPeople() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllPeople`, {
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllPeople(data)
                updateCleaningOptions(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function getAllGroups() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllCleningGroups`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllGroups(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function getParishInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getParishInfo`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setParishInfo(data)
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    }

    function updateCleaningOptions(data) {
        let temp = []
        for (let i = 0; i < data.length; i++) {
            data[i].isCleaner && temp.push(
                {value: data[i].name, label: data[i].name}
            )
        }
        setCleaningOptions(temp)
    }

    const ParishInfoForm = ({content}) => {

        const [changesMessage, setChangesMessage] = useState("")
        const defaultChangeMessage = `Informácie boli upravené, nezabudnite ich uložiť!`
        const [nameOfPriest, setNameOfPriest] = useState(content.name_of_priest ?? "")
        const [priestPhoneNumber, setPriestPhoneNumber] = useState(content.priest_phone_number ?? "")
        const [parishPhoneNumber, setParishPhoneNumber] = useState(content.parish_phone_number ?? "")
        const [email, setEmail] = useState(content.email ?? "")
        const [address, setAdress] = useState(content.address ?? "")
        const [accountNumberPnv, setAccountNumberPnv] = useState(content.account_number_pnv ?? "")
        const [accountNumberMilpos, setAccountNumberMilpos] = useState(content.account_number_milpos ?? "")

        const [monStart, setMonStart] = useState(content.office_hours[0].start_time ?? "")
        const [monEnd, setMonEnd] = useState(content.office_hours[0].end_time ?? "")
        const [monVisible, setMonVisible] = useState(content.office_hours[0].visible ?? false)

        const [tueStart, setTueStart] = useState(content.office_hours[1].start_time ?? "")
        const [tueEnd, setTueEnd] = useState(content.office_hours[1].end_time ?? "")
        const [tueVisible, setTueVisible] = useState(content.office_hours[1].visible ?? false)

        const [wedStart, setWedStart] = useState(content.office_hours[2].start_time ?? "")
        const [wedEnd, setWedEnd] = useState(content.office_hours[2].end_time ?? "")
        const [wedVisible, setWedVisible] = useState(content.office_hours[2].visible ?? false)

        const [thuStart, setThuStart] = useState(content.office_hours[3].start_time ?? "")
        const [thuEnd, setThuEnd] = useState(content.office_hours[3].end_time ?? "")
        const [thuVisible, setThuVisible] = useState(content.office_hours[3].visible ?? false)

        const [friStart, setFriStart] = useState(content.office_hours[4].start_time ?? "")
        const [friEnd, setFriEnd] = useState(content.office_hours[4].end_time ?? "")
        const [friVisible, setFriVisible] = useState(content.office_hours[4].visible ?? false)

        function handleSaveParishInfo() {
            const data = {
                name_of_priest: nameOfPriest,
                priest_phone_number: priestPhoneNumber,
                parish_phone_number: parishPhoneNumber,
                email: email,
                address: address,
                account_number_pnv: accountNumberPnv,
                account_number_milpos: accountNumberMilpos,
                office_hours: [
                    ["Pondelok", monStart, monEnd, monVisible],
                    ["Utorok", tueStart, tueEnd, tueVisible],
                    ["Streda", wedStart, wedEnd, wedVisible],
                    ["Štvrtok", thuStart, thuEnd, thuVisible],
                    ["Piatok", friStart, friEnd, friVisible],
                ]
            };

            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete upraviť základné informácie o farnosti? Informácie budú aktualizované na celej webovej stránke!`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/editParishInfo`, {
                        method: "POST",
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        }),
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setParishInfo(data)
                            toastSuccess("Informácie boli úspešne aktualizované!")
                        })
                        .catch((error) => {
                            toastError("Aktualizovanie informácií sa nepodarilo! Kontaktujte správcu webu alebo skúste akciu zopakovať.")
                            console.log(error)
                        });
                }
            )
        }

        return (
            <div className="fa-flex-col justify-content-center align-items-center mt-4">
                <div className="fa-flex-row parish-info-section my-1">
                    <h5 className="font-400 color-3">Farár:</h5>
                    <input type="text"
                           id={"parish-info-1"}
                           name="name_of_priest"
                           className="form-control admin-input-text"
                           value={nameOfPriest}
                           onChange={(e) => {
                               setNameOfPriest(e.target.value)
                               setChangesMessage(defaultChangeMessage)
                           }}
                    />
                </div>
                {/*<div className="fa-flex-row parish-info-section my-1">*/}
                {/*    <h5 className="font-400 color-3">Tel. farské číslo:</h5>*/}
                {/*    <input type="text"*/}
                {/*           id={"parish-info-2"}*/}
                {/*           name="parish_phone_number"*/}
                {/*           className="form-control admin-input-text"*/}
                {/*           value={parishPhoneNumber}*/}
                {/*           onChange={(e) => {*/}
                {/*               setParishPhoneNumber(e.target.value)*/}
                {/*               setChangesMessage(defaultChangeMessage)*/}
                {/*           }}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="fa-flex-row parish-info-section my-1">
                    <h5 className="font-400 color-3">Tel. číslo kňaza:</h5>
                    <input type="text"
                           id={"parish-info-3"}
                           name="priest_phone_number"
                           className="form-control admin-input-text"
                           value={priestPhoneNumber}
                           onChange={(e) => {
                               setPriestPhoneNumber(e.target.value)
                               setChangesMessage(defaultChangeMessage)
                           }}
                    />
                </div>
                <div className="fa-flex-row parish-info-section my-1">
                    <h5 className="font-400 color-4">E-mail:</h5>
                    <input type="text"
                           id={"parish-info-1"}
                           name="email"
                           className="form-control admin-input-text"
                           value={email}
                           onChange={(e) => {
                               setEmail(e.target.value)
                               setChangesMessage(defaultChangeMessage)
                           }}
                    />
                </div>
                <div className="fa-flex-row parish-info-section my-1">
                    <h5 className="font-400 color-3">Adresa:</h5>
                    <input type="text"
                           id={"parish-info-5"}
                           name="address"
                           className="form-control admin-input-text"
                           value={address}
                           onChange={(e) => {
                               setAdress(e.target.value)
                               setChangesMessage(defaultChangeMessage)
                           }}
                    />
                </div>
                <div className="fa-flex-row parish-info-section mb-1 mt-5">
                    <h5 className="font-400 color-3">Číslo účtu PNV:</h5>
                    <input type="text"
                           id={"parish-info-6"}
                           name="account_number_pnv"
                           className="form-control admin-input-text"
                           value={accountNumberPnv}
                           onChange={(e) => {
                               setAccountNumberPnv(e.target.value)
                               setChangesMessage(defaultChangeMessage)
                           }}
                    />
                </div>
                <div className="fa-flex-row parish-info-section my-1">
                    <h5 className="font-400 color-3">Číslo účtu Milpoš:</h5>
                    <input type="text"
                           id={"parish-info-7"}
                           name="account_number_milpos"
                           className="form-control admin-input-text"
                           value={accountNumberMilpos}
                           onChange={(e) => {
                               setAccountNumberMilpos(e.target.value)
                               setChangesMessage(defaultChangeMessage)
                           }}
                    />
                </div>


                <div className="fa-flex-col mb-1 mt-5">
                    <h5 className="font-700 color-3">Úradné hodiny farského úradu:</h5>
                    <div className="fa-flex-row parish-info-section my-1">
                        <h5 className="font-400 color-3 mt-1">{`${content.office_hours[0].day}:`}</h5>
                        <div className="fa-flex-row align-items-center">
                            <h6 className="font-400 color-3 mx-2 mt-2">nestránkový deň</h6>
                            <input type="checkbox"
                                   id={"parish-info-8-1"}
                                   name="mon-start"
                                   className="form-check-input admin-input-check me-2"
                                   checked={monVisible}
                                   onChange={(e) => {
                                       setMonVisible(!monVisible)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">od</h6>
                            <input type="time"
                                   id={"parish-info-8-2"}
                                   name="mon-start"
                                   className="form-control admin-input-time me-2"
                                   value={monStart}
                                   disabled={monVisible}
                                   onChange={(e) => {
                                       setMonStart(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">do</h6>
                            <input type="time"
                                   id={"parish-info-8-3"}
                                   name="mon-end"
                                   className="form-control admin-input-time"
                                   value={monEnd}
                                   disabled={monVisible}
                                   onChange={(e) => {
                                       setMonEnd(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                        </div>
                    </div>

                    <div className="fa-flex-row parish-info-section my-1">
                        <h5 className="font-400 color-3 mt-1">{`${content.office_hours[1].day}:`}</h5>
                        <div className="fa-flex-row align-items-center">
                            <h6 className="font-400 color-3 mx-2 mt-2">nestránkový deň</h6>
                            <input type="checkbox"
                                   id={"parish-info-9-1"}
                                   name="tue-visible"
                                   className="form-check-input admin-input-check me-2"
                                   checked={tueVisible}
                                   onChange={(e) => {
                                       setTueVisible(!tueVisible)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">od</h6>
                            <input type="time"
                                   id={"parish-info-9-2"}
                                   name="tue-start"
                                   className="form-control admin-input-time me-2"
                                   value={tueStart}
                                   disabled={tueVisible}
                                   onChange={(e) => {
                                       setTueStart(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">do</h6>
                            <input type="time"
                                   id={"parish-info-9-3"}
                                   name="tue-end"
                                   className="form-control admin-input-time"
                                   value={tueEnd}
                                   disabled={tueVisible}
                                   onChange={(e) => {
                                       setTueEnd(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                        </div>
                    </div>

                    <div className="fa-flex-row parish-info-section my-1">
                        <h5 className="font-400 color-3 mt-1">{`${content.office_hours[2].day}:`}</h5>
                        <div className="fa-flex-row align-items-center">
                            <h6 className="font-400 color-3 mx-2 mt-2">nestránkový deň</h6>
                            <input type="checkbox"
                                   id={"parish-info-10-1"}
                                   name="wed-visible"
                                   className="form-check-input admin-input-check me-2"
                                   checked={wedVisible}
                                   onChange={(e) => {
                                       setWedVisible(!wedVisible)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">od</h6>
                            <input type="time"
                                   id={"parish-info-10-2"}
                                   name="wed-start"
                                   className="form-control admin-input-time me-2"
                                   value={wedStart}
                                   disabled={wedVisible}
                                   onChange={(e) => {
                                       setWedStart(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">do</h6>
                            <input type="time"
                                   id={"parish-info-10-3"}
                                   name="wed-end"
                                   className="form-control admin-input-time"
                                   value={wedEnd}
                                   disabled={wedVisible}
                                   onChange={(e) => {
                                       setWedEnd(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                        </div>
                    </div>

                    <div className="fa-flex-row parish-info-section my-1">
                        <h5 className="font-400 color-3 mt-1">{`${content.office_hours[3].day}:`}</h5>
                        <div className="fa-flex-row align-items-center">
                            <h6 className="font-400 color-3 mx-2 mt-2">nestránkový deň</h6>
                            <input type="checkbox"
                                   id={"parish-info-11-1"}
                                   name="thu-visible"
                                   className="form-check-input admin-input-check me-2"
                                   checked={thuVisible}
                                   onChange={(e) => {
                                       setThuVisible(!thuVisible)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">od</h6>
                            <input type="time"
                                   id={"parish-info-11-2"}
                                   name="thu-start"
                                   className="form-control admin-input-time me-2"
                                   value={thuStart}
                                   disabled={thuVisible}
                                   onChange={(e) => {
                                       setThuStart(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">do</h6>
                            <input type="time"
                                   id={"parish-info-11-3"}
                                   name="thu-end"
                                   className="form-control admin-input-time"
                                   value={thuEnd}
                                   disabled={thuVisible}
                                   onChange={(e) => {
                                       setThuEnd(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                        </div>
                    </div>

                    <div className="fa-flex-row parish-info-section my-1">
                        <h5 className="font-400 color-3 mt-1">{`${content.office_hours[4].day}:`}</h5>
                        <div className="fa-flex-row align-items-center">
                            <h6 className="font-400 color-3 mx-2 mt-2">nestránkový deň</h6>
                            <input type="checkbox"
                                   id={"parish-info-12-1"}
                                   name="fri-visible"
                                   className="form-check-input admin-input-check me-2"
                                   checked={friVisible}
                                   onChange={(e) => {
                                       setFriVisible(!friVisible)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">od</h6>
                            <input type="time"
                                   id={"parish-info-12-2"}
                                   name="fri-start"
                                   className="form-control admin-input-time me-2"
                                   value={friStart}
                                   disabled={friVisible}
                                   onChange={(e) => {
                                       setFriStart(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                            <h6 className="font-400 color-3 mx-2 mt-2">do</h6>
                            <input type="time"
                                   id={"parish-info-12-3"}
                                   name="fri-end"
                                   className="form-control admin-input-time"
                                   value={friEnd}
                                   disabled={friVisible}
                                   onChange={(e) => {
                                       setFriEnd(e.target.value)
                                       setChangesMessage(defaultChangeMessage)
                                   }}
                            />
                        </div>
                    </div>
                </div>

                <button className="btn-primary font-700 pe-3 mt-5"
                        onClick={() => handleSaveParishInfo()}>
                    Uložiť
                </ button>
                {changesMessage && <div className="text-danger my-2">{changesMessage}</div>}
            </div>
        )
    }

    function handleModalPeopleOpen(person) {
        setModalPeopleShown(true)

        if (person !== null) {
            setPerson(person)
        }
    }

    function handleModalPeopleClose() {
        setModalPeopleShown(false)
        setPerson(null)
    }

    const ElementsListPeople = (props) => {

        return (
            <div className="el-list-section fa-flex-col scroll scroll2">
                {props.list.map((person, index) => (
                    <div className="fa-flex-row justify-content-between my-1" key={index}>
                        <div className={"el-list-item ps-1 background-color-1"}>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{`${person.name}`}</h6>
                            <div className="fa-flex-row">
                                {person.isCleaner &&
                                    <img
                                        src={Images.CLEANING_BLUE}
                                        className="fa-icon-btn-bg mx-2"
                                        alt="icon"
                                    />
                                }

                                {person.isLecturer &&
                                    <img
                                        src={Images.LECTURER_BLUE}
                                        className="fa-icon-btn-bg mx-2"
                                        alt="icon"
                                    />
                                }

                                {person.isPsalmists &&
                                    <img
                                        src={Images.PSALMITS_BLUE}
                                        className="fa-icon-btn-bg mx-2"
                                        alt="icon"
                                    />
                                }
                            </div>
                        </div>
                        <div className="fa-flex-row me-2">
                            <button className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => handleModalPeopleOpen(person)}>
                                <img
                                    src={Images.WRITING_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                    </div>
                ))}

            </div>
        );
    };

    const ModalPeople = (props) => {

        const [loader, setLoader] = useState(false)
        const personId = person !== null ? person._id : ""
        const [name, setName] = useState(person !== null ? person.name : "")
        const [email, setEmail] = useState(person !== null ? person.email : "")
        const [phone, setPhone] = useState(person !== null ? person.phone : "")
        const [isCleaner, setIsCleaner] = useState(person !== null ? person.isCleaner : false)
        const [isPsalmists, setIsPsalmists] = useState(person !== null ? person.isPsalmists : false)
        const [isLecturer, setIsLecturer] = useState(person !== null ? person.isLecturer : false)

        function handlePeopleCreate(p_name, p_email, p_phone, p_cleaner, p_psalmists, p_lecturer) {
            setLoader(true)

            const data = {
                name: p_name,
                email: p_email,
                phone: p_phone,
                isCleaner: p_cleaner,
                isPsalmists: p_psalmists,
                isLecturer: p_lecturer,
            }

            if (p_name.length > 0) {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/addPeople`, {
                    method: "POST",
                    headers: new Headers({
                        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }),
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((res) => {
                        setAllPeople(res)
                        setModalPeopleShown(false)
                        setPerson(null)
                        updateCleaningOptions(res)
                        toastSuccess("Nová osoba pre služby bola úspešne vytvorená!")
                    })
                    .catch((error) => {
                        toastError("Novú osobu sa nepodarilo vytvoriť")
                        console.log(error)
                    });
            } else toastError("Neboli zadané všetky potrebné údaje pre vytvorenie osoby!")

            setLoader(false)
        }

        function handlePeopleEdit(p_id, p_name, p_email, p_phone, p_cleaner, p_psalmists, p_lecturer) {
            setLoader(true)
            const data = {
                id: p_id,
                name: p_name,
                email: p_email,
                phone: p_phone,
                isCleaner: p_cleaner,
                isPsalmists: p_psalmists,
                isLecturer: p_lecturer,
            }

            if (p_name.length > 0) {
                setModalPeopleShown(false)
                confirmAlertDialog(
                    "UPOZORNENIE",
                    `Naozaj si prajete upraviť osobu "${name}"?`,
                    theme,
                    "YesNo",
                    () => {
                        fetch(`${process.env.REACT_APP_API_BASE_URL}/editPeople`, {
                            method: "POST",
                            headers: new Headers({
                                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            }),
                            body: JSON.stringify(data),
                        })
                            .then((response) => response.json())
                            .then((res) => {
                                setAllPeople(res)
                                setModalPeopleShown(false)
                                setPerson(null)
                                updateCleaningOptions(res)
                                toastSuccess("Osoba bola úspešne upravená!")
                            })
                            .catch((error) => {
                                toastError("Osobu sa nepodarilo upraviť! Skúste akciu zopakovať alebo kontaktujte správcu aplikácie.")
                                console.log(error)
                            });
                    }
                )
            } else toastError("Neboli zadané všetky potrebné údaje pre vytvorenie osoby!")

            setLoader(false)
        }

        function handlePeopleDelete(p_id) {
            setModalPeopleShown(false)
            setLoader(true)

            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete zmazať osobu "${name}"?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/deletePeopleById/${p_id}`, {
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        })
                    })
                        .then((response) => response.json())
                        .then((res) => {
                            setAllPeople(res)
                            setModalPeopleShown(false)
                            setPerson(null)
                            updateCleaningOptions(res)
                            toastSuccess("Osoba bola úspešne vymazaná!")
                        })
                        .catch((error) => {
                            toastError("Osobu sa nepodarilo vymazať! Skúste akciu zopakovať alebo kontaktujte správcu webu.")
                            console.log(error)
                        });
                }
            )

            setLoader(false)
        }

        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-users p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                    {person !== null ?
                        <>
                            <div className="fa-flex-row justify-content-end gap-2 w-100">
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handlePeopleDelete(person._id)}>
                                    <img
                                        src={Images.BIN_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleModalPeopleClose()}>
                                    <img
                                        src={Images.CLOSE_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                            </div>
                            <h3 className="font-900 color-3">{"EDITOVANIE OSOBY"}</h3>
                            <h4 className="font-300 color-3">{`${person.name}`}</h4>
                        </>
                        :
                        <h3 className="font-900 color-3">{"VYTVORENIE OSOBY"}</h3>
                    }

                    <div className="mt-4 mb-2">
                        <h6 className="font-400 color-3">Meno a priezvisko:</h6>
                        <input type="text"
                               id="firstname"
                               placeholder="Napr. Janko Hraško"
                               className="form-control admin-input-text-short"
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="my-2">
                        <h6 className="font-400 color-3">Email:</h6>
                        <input type="email"
                               id="login"
                               placeholder="Napr. janko@mail.com"
                               className="form-control admin-input-text-short"
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="my-2">
                        <h6 className="font-400 color-3">Telefónne číslo:</h6>
                        <input type="text"
                               id="phoneNum"
                               placeholder="Napr. 0905 123 456"
                               className="form-control admin-input-text-short"
                               value={phone}
                               onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                    <div className="fa-flex-row my-2 mt-4">
                        <div className="fa-flex-col align-items-center mx-3">
                            <div className="fa-flex-row align-items-center mx-3">
                                <img
                                    src={Images.CLEANING_BLUE}
                                    className="fa-icon-medium mx-2"
                                    alt="icon"
                                />
                                <input type="checkbox"
                                       id="cleaning"
                                       className="form-check-input admin-input-check m-2"
                                       checked={isCleaner}
                                       onChange={(e) => setIsCleaner(!isCleaner)}
                                />
                            </div>
                            <h6 className="font-400 color-3 mt-3">upratovanie</h6>
                        </div>

                        <div className="fa-flex-col align-items-center mx-3">
                            <div className="fa-flex-row align-items-center mx-3">
                                <img
                                    src={Images.LECTURER_BLUE}
                                    className="fa-icon-medium mx-2"
                                    alt="icon"
                                />
                                <input type="checkbox"
                                       id="cleaning"
                                       className="form-check-input admin-input-check m-2"
                                       checked={isLecturer}
                                       onChange={(e) => setIsLecturer(!isLecturer)}
                                />
                            </div>
                            <h6 className="font-400 color-3 mt-3">lektor</h6>
                        </div>

                        <div className="fa-flex-col align-items-center mx-3">
                            <div className="fa-flex-row align-items-center mx-3">
                                <img
                                    src={Images.PSALMITS_BLUE}
                                    className="fa-icon-medium mx-2"
                                    alt="icon"
                                />
                                <input type="checkbox"
                                       id="cleaning"
                                       className="form-check-input admin-input-check m-2"
                                       checked={isPsalmists}
                                       onChange={(e) => setIsPsalmists(!isPsalmists)}
                                />
                            </div>
                            <h6 className="font-400 color-3 mt-3">žalmista</h6>
                        </div>


                    </div>

                    {person !== null ?
                        <button
                            className="btn-primary mt-5"
                            disabled={!(loader === false)}
                            onClick={() => handlePeopleEdit(personId, name, email, phone, isCleaner, isPsalmists, isLecturer)}>
                            {loader ? "Ukladám..." : "Uložiť"}
                        </button>
                        :
                        <button
                            className="btn-primary mt-5"
                            disabled={!(loader === false)}
                            onClick={() => handlePeopleCreate(name, email, phone, isCleaner, isPsalmists, isLecturer)}>
                            {loader ? "Vytváram..." : "Vytvoriť"}
                        </button>
                    }
                </Modal.Body>
            </Modal>
        )
    }

    function handleModalGroupsOpen(group) {
        setModalGroupsShown(true)

        if (group !== null) {
            setGroup(group)
        }
    }

    function handleModalGroupsClose() {
        setModalGroupsShown(false)
        setGroup(null)
    }

    const ElementsListGroups = (props) => {

        return (
            <div className="el-list-section fa-flex-col scroll scroll2">
                {props.list.map((group, index) => (
                    <div className="fa-flex-row justify-content-between my-1" key={index}>
                        <div className={"el-list-item ps-1 background-color-1"}>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{`${group.title}`}</h6>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{`Počet členov: ${group.members.filter(item => typeof item === 'string' && item.trim() !== '').length}`}</h6>
                        </div>
                        <div className="fa-flex-row me-2">
                            <button className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => handleModalGroupsOpen(group)}>
                                <img
                                    src={Images.WRITING_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                        </div>
                    </div>
                ))}

            </div>
        );
    };

    const ModalGroups = (props) => {

        const [loader, setLoader] = useState(false)
        const groupId = group !== null ? group._id : ""
        const [title, setTitle] = useState(group !== null ? group.title : "")
        const [member_1, setMember_1] = useState({
            value: group !== null ? group.members[0] : "",
            label: group !== null ? group.members[0] : ""
        })
        const [member_2, setMember_2] = useState({
            value: group !== null ? group.members[1] : "",
            label: group !== null ? group.members[1] : ""
        })
        const [member_3, setMember_3] = useState({
            value: group !== null ? group.members[2] : "",
            label: group !== null ? group.members[2] : ""
        })
        const [member_4, setMember_4] = useState({
            value: group !== null ? group.members[3] : "",
            label: group !== null ? group.members[3] : ""
        })
        const [member_5, setMember_5] = useState({
            value: group !== null ? group.members[4] : "",
            label: group !== null ? group.members[4] : ""
        })
        const [member_6, setMember_6] = useState({
            value: group !== null ? group.members[5] : "",
            label: group !== null ? group.members[5] : ""
        })
        const [member_7, setMember_7] = useState({
            value: group !== null ? group.members[6] : "",
            label: group !== null ? group.members[6] : ""
        })
        const [member_8, setMember_8] = useState({
            value: group !== null ? group.members[7] : "",
            label: group !== null ? group.members[7] : ""
        })
        const [member_9, setMember_9] = useState({
            value: group !== null ? group.members[8] : "",
            label: group !== null ? group.members[8] : ""
        })
        const [member_10, setMember_10] = useState({
            value: group !== null ? group.members[9] : "",
            label: group !== null ? group.members[9] : ""
        })
        const [date1, setDate1] = useState(group !== null ? group.dates[0] : "");
        const [date2, setDate2] = useState(group !== null ? group.dates[1] : "");
        const [date3, setDate3] = useState(group !== null ? group.dates[2] : "");
        const [date4, setDate4] = useState(group !== null ? group.dates[3] : "");

        function handleGroupCreate(g_title, g_members, g_dates) {
            setLoader(true)

            const data = {
                title: g_title,
                members: g_members,
                dates: g_dates
            }

            if (g_title.length > 0 && g_members.length > 0) {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/addCleningGroup`, {
                    method: "POST",
                    headers: new Headers({
                        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }),
                    body: JSON.stringify(data),
                })
                    .then((response) => response.json())
                    .then((res) => {
                        setAllGroups(res)
                        setModalGroupsShown(false)
                        setGroup(null)
                        toastSuccess("Nová skupina na upratovanie bola úspešne vytvorená!")
                    })
                    .catch((error) => {
                        toastError("Novú skupinu sa nepodarilo vytvoriť")
                        console.log(error)
                    });
            } else {
                toastError("Neboli zadané všetky potrebné údaje pre vytvorenie skupiny!")
            }

            setLoader(false)
        }

        function handleGroupEdit(g_id, g_title, g_members, g_dates) {
            setModalGroupsShown(false)
            setLoader(true)
            const data = {
                id: g_id,
                title: g_title,
                members: g_members,
                dates: g_dates
            }

            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete upraviť skupinu "${title}"?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/editCleningGroup`, {
                        method: "POST",
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        }),
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((res) => {
                            setAllGroups(res)
                            setModalGroupsShown(false)
                            setGroup(null)
                            toastSuccess("Skupina bola úspešne upravená!")
                        })
                        .catch((error) => {
                            toastError("Skupinu sa nepodarilo upraviť! Skúste akciu zopakovať alebo kontaktujte správcu aplikácie.")
                            console.log(error)
                        });
                }
            )

            setLoader(false)
        }

        function handleGroupDelete(g_id) {
            setModalGroupsShown(false)
            setLoader(true)
            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete zmazať skupinu "${title}"?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteCleningGroupById/${g_id}`, {
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        })
                    })
                        .then((response) => response.json())
                        .then((res) => {
                            setAllGroups(res)
                            setModalGroupsShown(false)
                            setGroup(null)
                            toastSuccess("Skupina bola úspešne vymazaná!")
                        })
                        .catch((error) => {
                            toastError("Skupinu sa nepodarilo vymazať! Skúste akciu zopakovať alebo kontaktujte správcu webu.")
                            console.log(error)
                        });
                }
            )
            setLoader(false)
        }

        return (
            <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-users p-2">
                <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                    {group !== null ?
                        <>
                            <div className="fa-flex-row justify-content-end gap-2 w-100">
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleGroupDelete(groupId)}>
                                    <img
                                        src={Images.BIN_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                                <button className="admin-icon-btn mx-1"
                                        onClick={() => handleModalGroupsClose()}>
                                    <img
                                        src={Images.CLOSE_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                            </div>
                            <h3 className="font-900 color-3">{"EDITOVANIE SKUPINY"}</h3>
                            <h4 className="font-300 color-3">{`${group.title}`}</h4>
                        </>
                        :
                        <h3 className="font-900 color-3">{"VYTVORENIE SKUPINY"}</h3>
                    }

                    <div className="mt-4 mb-2">
                        <h6 className="font-400 color-3">Názov skupiny:</h6>
                        <input type="text"
                               id="title"
                               placeholder="Napr. Členovia živého ruženca - ruža č.1"
                               className="form-control admin-input-text"
                               value={title}
                               onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="my-2">
                        <h6 className="font-400 color-3">Členovia:</h6>
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_1}
                            options={cleaningOptions}
                            value={member_1}
                            onChange={(elm) => {
                                setMember_1(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_2}
                            options={cleaningOptions}
                            value={member_2}
                            onChange={(elm) => {
                                setMember_2(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_3}
                            options={cleaningOptions}
                            value={member_3}
                            onChange={(elm) => {
                                setMember_3(elm)
                            }}
                        />

                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_4}
                            options={cleaningOptions}
                            value={member_4}
                            onChange={(elm) => {
                                setMember_4(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_5}
                            options={cleaningOptions}
                            value={member_5}
                            onChange={(elm) => {
                                setMember_5(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_6}
                            options={cleaningOptions}
                            value={member_6}
                            onChange={(elm) => {
                                setMember_6(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_7}
                            options={cleaningOptions}
                            value={member_7}
                            onChange={(elm) => {
                                setMember_7(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_8}
                            options={cleaningOptions}
                            value={member_8}
                            onChange={(elm) => {
                                setMember_8(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_9}
                            options={cleaningOptions}
                            value={member_9}
                            onChange={(elm) => {
                                setMember_9(elm)
                            }}
                        />
                        <Select
                            className="admin-input-text my-1"
                            name="member-1"
                            isClearable
                            isSearchable
                            defaultValue={member_10}
                            options={cleaningOptions}
                            value={member_10}
                            onChange={(elm) => {
                                setMember_10(elm)
                            }}
                        />
                    </div>

                    <div className="mt-4 mb-2 w-75">
                        <h6 className="font-400 color-3 w-100 text-center">Dátumy upratovania</h6>
                        <div className="fa-flex-row">
                            <input type="date"
                                   id="date-1"
                                   className="form-control w-25 mx-1"
                                   value={date1}
                                   onChange={(e) => setDate1(e.target.value)}
                            />
                            <input type="date"
                                   id="date-2"
                                   className="form-control w-25 mx-1"
                                   value={date2}
                                   onChange={(e) => setDate2(e.target.value)}
                            />
                            <input type="date"
                                   id="date-3"
                                   className="form-control w-25 mx-1"
                                   value={date3}
                                   onChange={(e) => setDate3(e.target.value)}
                            />
                            <input type="date"
                                   id="date-4"
                                   className="form-control w-25 mx-1"
                                   value={date4}
                                   onChange={(e) => setDate4(e.target.value)}
                            />
                        </div>
                    </div>


                    {group !== null ?
                        <button
                            className="btn-primary mt-5"
                            disabled={!(loader === false)}
                            onClick={() => handleGroupEdit(groupId, title, [member_1?.value ?? "", member_2?.value ?? "", member_3?.value ?? "", member_4?.value ?? "", member_5?.value ?? "", member_6?.value ?? "", member_7?.value ?? "", member_8?.value ?? "", member_9?.value ?? "", member_10?.value ?? ""], [date1, date2, date3, date4])}>
                            {loader ? "Ukladám..." : "Uložiť"}
                        </button>
                        :
                        <button
                            className="btn-primary mt-5"
                            disabled={!(loader === false)}
                            onClick={() => handleGroupCreate(title,
                                [member_1?.value ?? "", member_2?.value ?? "", member_3?.value ?? "", member_4?.value ?? "", member_5?.value ?? "", member_6?.value ?? "", member_7?.value ?? "", member_8?.value ?? "", member_9?.value ?? "", member_10?.value ?? ""], [date1, date2, date3, date4])}>
                            {loader ? "Vytváram..." : "Vytvoriť"}
                        </button>
                    }
                </Modal.Body>
            </Modal>
        )
    }


    return (
        <div className="fa-flex-col" id='edit-information'>
            <ModalPeople show={modalPeopleShown} onHide={() => handleModalPeopleClose()}/>
            <ModalGroups show={modalGroupsShown} onHide={() => handleModalGroupsClose()}/>
            <SectionHeading title={"Administrácia ľudí zapojených do služieb"} actualId='edit-information'/>
            <div className="fa-flex-row justify-content-between w-100">
                {allPeople && <ElementsListPeople list={allPeople}/>}
                <button className="btn-primary align-self-start font-700"
                        onClick={() => handleModalPeopleOpen(null)}>
                    Vytvoriť osobu
                </ button>
            </div>

            <SectionHeading title={"Upratovacie skupiny"}/>
            <div className="fa-flex-row justify-content-between w-100">
                {allGroups && <ElementsListGroups list={allGroups}/>}
                <button className="btn-primary align-self-start font-700"
                        onClick={() => handleModalGroupsOpen(null)}>
                    Vytvoriť skupinu
                </ button>
            </div>

            <SectionHeading title={"Základné informácie o farnosti"}/>
            {parishInfo !== undefined ? <ParishInfoForm content={parishInfo}/> : <div/>}
        </div>
    );
};

export default AdminEditInformation;