import React, {useEffect, useState} from 'react';
import {AppContainer} from "../components/AppContainer";
import {SectionBanksAccounts} from "../components/SectionBanksAccounts";
import {Images} from "../constants/Images";
import {LoadingSpinner} from "../components/LoadingSpinner";

const Charbel = () => {

    const [parishInfo, setParishInfo] = useState()

    useEffect(() => {
        getParishInfo()
    }, []);

    function getParishInfo() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getParishInfo`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setParishInfo(data)
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const ContactRow = (props) => {

        return (
            <div className="desktop-row-phone-col align-items-center justify-content-between mt-2 w-100 gap-1">
                <div className="fa-flex-row contact-row-description w-50">
                    <h5 className="font-700 color-3 mt-2 me-3">{props.description}</h5>
                </div>
                {props.text_2.length > 0 ?
                    <div className="fa-flex-row contact-row-content w-50">
                        <div className="fa-flex-col">
                            <h5 className="font-400 color-3">{props.text_1}</h5>
                            <h5 className="font-400 color-3">{props.text_2}</h5>
                        </div>
                    </div>
                    :
                    <div className="fa-flex-row contact-row-content w-50">
                        <h5 className="font-400 color-3 ">{props.text_1}</h5>
                    </div>
                }
            </div>
        )
    }


    return (
        <AppContainer
            title={"Kontakt"}
            content={
                <div className="window-content-container">
                    {parishInfo !== undefined ?
                        <div>
                            <div className="fa-flex-col align-items-center p-3">
                                <div className="mt-4">
                                    <h3 className="font-700 color-3 text-center w-100">{parishInfo.name_of_priest}</h3>
                                    <h5 className="font-400 color-3 text-center w-100">farár</h5>
                                </div>

                                <div className="fa-flex-col w-100 my-3">
                                    {/*<ContactRow description={"Telefóne číslo"}*/}
                                    {/*            text_1={parishInfo.parish_phone_number} text_2={""}/>*/}
                                    <ContactRow description={"Mobilné číslo"}
                                                text_1={parishInfo.priest_phone_number}
                                                text_2={""}/>
                                    <ContactRow description={"Emailová adresa"}
                                                text_1={parishInfo.email} text_2={""}/>
                                    <ContactRow description={"Adresa"}
                                                text_1={parishInfo.address} text_2="Pečovská Nová Ves"/>
                                </div>
                            </div>

                            <div className="office-hours-section fa-flex-col m-2 me-4 mb-5 p-3">
                                <h3 className="font-700 color-3 text-center w-100 mt-4 mb-3">{"Úradné hodiny farského úradu"}</h3>
                                {parishInfo.office_hours.map((item, index) => (
                                    !item.visible ?
                                        <div
                                            className="footer-hours-table fa-flex-row justify-content-between mt-2 gap-2"
                                            key={index}>
                                            <h5 className="font-700 color-3 text-end w-50 pe-5">{item.day}</h5>
                                            <h5 className="font-400 color-3 text-start w-50">{`${item.start_time} - ${item.end_time}`}</h5>
                                        </div>
                                        :
                                        <div key={index}/>
                                ))}
                            </div>

                            <div className="fa-flex-col align-items-center justify-content-center mb-5">
                                <h3 className="font-700 color-3 text-center w-100 my-4">{"Čísla účtov"}</h3>
                                <SectionBanksAccounts data={parishInfo} textColor={'color-3'} icon={Images.BANK_BLUE}/>
                            </div>
                        </div>
                        :
                        <div className="app-container mt-5">
                            <LoadingSpinner/>
                        </div>
                    }
                </div>
            }
        />
    )
};

export default Charbel;