import React from 'react';
import "../assets/css/Icons.css"
import "../assets/css/App.css"

const ButtonIcon = ({
                        icon,
                        title,
                        action,
                        disableCondition,
                        backgroundIcon = 'background-color-3',
                        backgroundText = 'background-color-10',
                        border = 'btn-border'
                    }) => {
    return (
        <button className={`btn-with-icon font-700 fa-pointer p-0 ${border} ${backgroundText}`} onClick={action}
                disabled={disableCondition}>
            <div
                className={`fa-flex-row justify-content-center align-items-center ${backgroundIcon} h-100 w-25`}>
                <img src={icon} className="fa-icon-small"
                     alt="icon"/>
            </div>
            <span className="font-600 text-center w-75 ps-1">{title}</span>
        </ button>
    );
};

export default ButtonIcon;