import React, {useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/App.css";
import "../assets/css/PhotoGalleries.css";
import {AppContainer} from "../components/AppContainer";
import {toastError} from "../components/toastError";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {Images} from "../constants/Images";
import {ACCESS_TOKEN_KEY} from "../constants";

const PhotoGallery = () => {

    const [photoGalleries, setPhotoGalleries] = useState([])
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getVisiblePhotoGalleries()
    }, []);

    function getVisiblePhotoGalleries() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getVisiblePhotoGalleries`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setPhotoGalleries(data)

                let categoriesList = new Set();
                for (let i = 0; i < data.length; i++) {
                    categoriesList.add(data[i].category)
                }

                setCategories([...categoriesList])
            })
            .catch((error) => {
                toastError("Chyba pri načitávaní článkov!")
                return error;
            });
    }

    const Gallery = ({photoGallery}) => {

        function handleAlbumOpen(g_id, g_views, url) {
            const data = {
                views: g_views
            }

            fetch(`${process.env.REACT_APP_API_BASE_URL}/updatePhotoGalleryViews/${g_id}`, {
                method: "POST",
                headers: new Headers({
                    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }),
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((res) => {
                    getVisiblePhotoGalleries()
                    window.open(url, "_blank", "noreferrer");
                })
                .catch((error) => {
                    console.log(error)
                });
        }

        return (
            <div className="fa-flex-col justify-content-between photoGallery-item rounded-4 m-3 p-1"
                 onClick={() => handleAlbumOpen(photoGallery._id, photoGallery.views, photoGallery.album_url)}
            >
                <div className="photoGallery-img-container">
                    <img
                        src={photoGallery.image_url.length > 0 ? photoGallery.image_url : "https://i.ibb.co/z24Mq1P/titulna-6.jpg"}
                        className="photoGallery-image"/>
                </div>
                <div className="photoGallery-content-container mx-1 mt-2">
                    <span className="font-700 color-3 photogallery-title">{photoGallery.title}</span>
                    <div className="fa-flex-row align-items-center justify-content-end">
                        <img src={Images.EYE_BLUE} className="fa-icon-btn-sm me-2" alt="icon"/>
                        <span className="font-400-lato color-3">{`${photoGallery.views}x`}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <AppContainer
            title={"Fotogaléria"}
            content={
                <div className="window-content-container">
                    {photoGalleries.length > 0 ?
                        categories.map((category, index) => (
                            <div key={index}>
                                <h4 className="font-900-lato color-3">{category}</h4>
                                <div className="photoGallery-line w-100"/>
                                <div className="photoGallery-section">
                                    {photoGalleries.map((gallery, index) => (
                                        gallery.category === category && <Gallery key={index} photoGallery={gallery}/>
                                    ))}
                                </div>
                            </div>
                        ))
                        :
                        <div className="app-container mt-5">
                            <LoadingSpinner/>
                        </div>
                    }
                </div>
            }
        />
    )
};

export default PhotoGallery;
