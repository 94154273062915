import QRCode from "react-qr-code";
import React from "react";

export const SectionBanksAccounts = ({data, textColor, icon}) => {
    return (
        <div className="bank-account-section fa-flex-col m-2 me-4">
            <div className="fa-flex-row justify-content-between mb-4">
                <div className="fa-flex-col">
                    <h5 className={`font-700 ${textColor}`}>Pečovská Nová Ves</h5>
                    <div className="fa-flex-row align-items-center ms-1">
                        <img src={icon} className="fa-icon-small" alt="icon"/>
                        <h6 className={`font-300 ${textColor} mt-2 ms-2`}>{data.account_number_pnv}</h6>
                    </div>
                </div>
                <div className="footer-qr-code ms-3">
                    <QRCode
                        size={256}
                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                        value={data.account_number_pnv}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>

            <div className="fa-flex-row justify-content-between">
                <div className="fa-flex-col">
                    <h5 className={`font-700 ${textColor}`}>Milpoš</h5>
                    <div className="fa-flex-row align-items-center ms-1">
                        <img src={icon} className="fa-icon-small" alt="icon"/>
                        <h6 className={`font-300 ${textColor} mt-2 ms-2`}>{data.account_number_milpos}</h6>
                    </div>
                </div>
                <div className="footer-qr-code ms-3">
                    <QRCode
                        size={256}
                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                        value={data.account_number_milpos}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>
        </div>
    )
}