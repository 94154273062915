export const renderTextWithLine = (content) => {
    if (content !== null) {
        const lines = content.split('\n');
        return lines.map((line, index) => (
            line.length > 0 ?
                <div key={index}>{line}</div>
                :
                <p/>
        ));
    } else return null;
};