import React, {useEffect, useState} from 'react';
import "../assets/css/AnnouncementsMass.css"
import SectionHeading from "./SectionHeading";
import {Images} from "../constants/Images";
import ScrollIntoView from 'react-scroll-into-view'
import {confirmAlertDialog} from "./confirmAlertDialog";
import {useDispatch, useSelector} from "react-redux";
import {setAnnouncementsList} from "../store/announcementsSlice";
import {LoadingSpinner} from "./LoadingSpinner";
import CreatableSelect from "react-select/creatable";
import {toastInfo} from "./toastInfo";
import {toastError} from "./toastError";
import {toastSuccess} from "./toastSuccess";
import {getNextDate} from "./getNextDate";
import {Modal} from "react-bootstrap";
import AnnouncementsPDF from "./AnnouncementsPDF";
import {formatDate} from "./formatDate";
import {useReactToPrint} from "react-to-print";
import ButtonIcon from "./ButtonIcon";
import {ACCESS_TOKEN_KEY} from "../constants";
import Select from "react-select";
import {Tooltip} from 'react-tooltip'

const AdminMassSchedule = () => {

    const dispatch = useDispatch();
    const announcementsFromStore = useSelector((state) => state.announcementsList.value);
    const [allAnnouncements, setAllAnnouncements] = useState([])
    const [allGroupsOptions, setAllGroupsOptions] = useState([])
    const [allGroups, setAllGroups] = useState([])
    const empty_announcements = {
        title: "",
        numOfPrinted1: 0,
        numOfPrinted2: 0,
        start_date: "",
        image_url: "",
        days: [
            {
                dayname: "Pondelok",
                date: "",
                empty: false,
                mass: [
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                ]
            },
            {
                dayname: "Utorok",
                date: "",
                empty: false,
                mass: [
                    {
                        feast_day: null,
                        place: "PNV",
                        time: "17:30",
                        intention: "",
                        confession_start: "16:40",
                        confession_end: "17:20"
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                ]
            },
            {
                dayname: "Streda",
                date: "",
                empty: false,
                mass: [
                    {
                        feast_day: null,
                        place: "PNV",
                        time: "7:30",
                        intention: "",
                        confession_start: "06:40",
                        confession_end: "07:20"
                    },
                    {
                        feast_day: null,
                        place: "Milpoš",
                        time: "17:00",
                        intention: "",
                        confession_start: "16:30",
                        confession_end: "16:55"
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                ]
            },
            {
                dayname: "Štvrtok",
                date: "",
                empty: false,
                mass: [
                    {
                        feast_day: null,
                        place: "PNV",
                        time: "17:30",
                        intention: "",
                        confession_start: "16:40",
                        confession_end: "17:20"
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                ]
            },
            {
                dayname: "Piatok",
                date: "",
                empty: false,
                mass: [
                    {
                        feast_day: null,
                        place: "PNV",
                        time: "17:30",
                        intention: "",
                        confession_start: "16:40",
                        confession_end: "17:20"
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                ]
            },
            {
                dayname: "Sobota",
                date: "",
                empty: false,
                mass: [
                    {
                        feast_day: null,
                        place: "PNV",
                        time: "7:30",
                        intention: "",
                        confession_start: "06:40",
                        confession_end: "07:20"
                    },
                    {
                        feast_day: "S platnosťou na nedeľu",
                        place: "Rómska kaplnka",
                        time: "16:30",
                        intention: "",
                        confession_start: "16:00",
                        confession_end: "16:25"
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: null,
                        place: null,
                        time: null,
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                ]
            },
            {
                dayname: "Nedeľa",
                date: "",
                empty: false,
                mass: [
                    {
                        feast_day: "",
                        place: "PNV",
                        time: "7:30",
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: "",
                        place: "Milpoš",
                        time: "9:00",
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: "",
                        place: "PNV",
                        time: "10:30",
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                    {
                        feast_day: "",
                        place: "",
                        time: "",
                        intention: "",
                        confession_start: "",
                        confession_end: ""
                    },
                ]
            },
        ],
        texts: [
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""},
            {title: "", content: ""}
        ],
        cleaning: [{dayAndTime: "v sobotu - 8:00 hod.", groupId: "", groupTitle: ""}]

    };
    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null ? localStorage.getItem("theme") : "light"
    );

    useEffect(() => {
        getAllAnnouncements()
        getAllGroups()
    }, []);

    function getAllAnnouncements() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllAnnouncements`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllAnnouncements(data)
            })
            .catch((error) => {
                toastError("Chyba pri načitávaní oznamov! Skúste urobiť refresh stránky alebo kontaktujte správcu webu!")
                return error;
            });
    }

    function getAllGroups() {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAllCleningGroups`, {})
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                let groupsList = []
                for (let i = 0; i < data.length; i++) {
                    groupsList.push({
                        value: data[i]._id,
                        label: `${data[i].title} (${data[i].dates.map(date => formatDate(date, true)).join(", ")})`
                    })
                }
                setAllGroupsOptions(groupsList)
                setAllGroups(data)
            })
            .catch((error) => {
                toastError("Chyba pri načitávaní upratovacích skupín! Skúste urobiť refresh stránky alebo kontaktujte správcu webu!")
                return error;
            });
    }

    function getFeastList(date) {
        const slovakCalendar = require("../constants/liturgicky-kalendar.json")
        let feastList = []
        let editedDate = date.replace('-', '/').replace('-', '/')

        fetch(`http://calapi.inadiutorium.cz/api/v0/en/calendars/default/${editedDate}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.celebrations !== undefined) {
                    for (let i = 0; i < data.celebrations.length; i++) {
                        for (let j = 0; j < slovakCalendar.kalendar.length; j++) {
                            if (slovakCalendar.kalendar[j].english === data.celebrations[i].title) {
                                feastList.push({
                                    value: slovakCalendar.kalendar[j].slovak,
                                    label: slovakCalendar.kalendar[j].slovak
                                })
                            }
                        }
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });

        feastList.push({
                value: "Zo zajtrajšej slávnosti",
                label: "Zo zajtrajšej slávnosti",
            },
            {
                value: "S platnosťou na nedeľu",
                label: "S platnosťou na nedeľu",
            },
            {
                value: "Roráty",
                label: "Roráty",
            }
        )

        return feastList
    }

    function setVisibility(scheduleId, visibility) {
        const data = {
            id: scheduleId,
            isVisible: visibility,
        };

        confirmAlertDialog(
            "UPOZORNENIE",
            `Naozaj si prajete ${visibility ? "sprístupniť oznamy" : "zrušiť sprístupnenie oznamov"} pre zvolený týždeň na webovej stránke?`,
            theme,
            "YesNo",
            () => {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/setAnnouncementVisibility`, {
                        method: "POST",
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        }),
                        body: JSON.stringify(data),
                    }
                )
                    .then((response) => response.json())
                    .then((res) => {
                        getAllAnnouncements()
                        if (visibility)
                            toastSuccess("Zvolené oznamy sú odteraz viditeľné na webovej stránke!")
                        else
                            toastInfo("Zvolené oznamy už nie sú odteraz viditeľné na webovej stránke!")
                    })
                    .catch((error) => {
                        toastError("Chyba pri zmene viditeľnosti oznamov! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                        console.log(error);
                    });
            }
        )
    };

    function getAnnouncementsById(id) {

        fetch(`${process.env.REACT_APP_API_BASE_URL}/getAnnouncementById/${id}`, {
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            })
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                dispatch(setAnnouncementsList(data))
            })
            .catch((error) => {
                toastError("Chyba pri zmene načítání oznamov! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                return error;
            });
    }

    function getGroupByDate(dateToFind) {

        for (const group of allGroups) {
            if (group.dates.includes(dateToFind)) {
                return {
                    value: group._id,
                    label: `${group.title} (${group.dates.map(date => formatDate(date, true)).join(", ")})`
                };
            }
        }

        return {
            value: "",
            label: ""
        };
    }

    function handleEditAnnouncements(id) {

        if (document.getElementById('mass-schedule-section').style.display === "flex") {
            confirmAlertDialog(
                "UPOZORNENIE",
                "Všetky neuložené zmeny, ktoré ste urobili v inom rozpise budú stratené. Prajete si pokračovať?",
                theme,
                "YesNo",
                () => {
                    getAnnouncementsById(id)
                }
            )
        } else {
            getAnnouncementsById(id)
            document.getElementById('mass-schedule-section').style.display = "flex"
        }
    }

    function handleNewAnnouncements() {
        if (document.getElementById('mass-schedule-section').style.display === "flex") {
            confirmAlertDialog(
                "UPOZORNENIE",
                "Všetky neuložené zmeny, ktoré ste urobili v inom rozpise budú stratené. Prajete si pokračovať?",
                theme,
                "YesNo",
                () => {
                    dispatch(setAnnouncementsList(empty_announcements))
                    console.log(announcementsFromStore)
                    document.getElementById('mass-schedule-section').style.display = "flex"
                }
            )
        } else {
            dispatch(setAnnouncementsList(empty_announcements))
            document.getElementById('mass-schedule-section').style.display = "flex";
        }
    }

    const ElementsList = (props) => {

        const [modalPDFShown, setModalPDFShown] = useState(false)
        const [announToPrint, setAnnouToPrint] = useState({})
        const [printText, setPrintText] = useState("")
        const ModalPdf = (props) => {

            const handlePrint = useReactToPrint({
                content: () => document.querySelector(`#announcements-table-${announToPrint._id}`),
                documentTitle: 'farske_oznamy',
                onAfterPrint: () => toastInfo("Tlač oznamov bola ukončená!"),
                onPrintError: () => toastError("Oznami sa nepodarilo vytlačiť!")
            })


            return (
                <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-pdf p-2">
                    <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                        {announToPrint.image_url !== undefined ?
                            <AnnouncementsPDF title={announToPrint.title} data={announToPrint.days}
                                              id={announToPrint._id}
                                              image={announToPrint.image_url} texts={announToPrint.texts}
                                              cleaning={announToPrint.cleaning}
                                              printText={printText}/>
                            :
                            <AnnouncementsPDF title={announToPrint.title} data={announToPrint.days}
                                              id={announToPrint._id}
                                              image={""} texts={announToPrint.texts}
                                              cleaning={announToPrint.cleaning}
                                              printText={printText}/>
                        }
                        <div className="fa-flex-row justify-content-center mt-3">
                            <ButtonIcon icon={Images.PRINTER_WHITE} title="Vytlačiť"
                                        action={() => handlePrint()}/>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }


        return (
            <div className="el-list-section fa-flex-col scroll scroll2">
                <ModalPdf show={modalPDFShown} onHide={() => setModalPDFShown(false)}/>
                {props.list.map((item, index) => (
                    <div className="fa-flex-row justify-content-between my-1" key={index}>
                        <div
                            className={"el-list-item ps-1 " + (item.isVisible ? "background-color-7" : "background-color-1")}>
                            <h6 className="font-700 color-3 text-start mt-2 px-2">{item.title}</h6>
                            <h6 className="font-400 color-3 text-start mt-2 px-2">{`${formatDate(item.start_date, true)} (${item.numOfPrinted1}, ${item.numOfPrinted2})`}</h6>
                        </div>
                        <div className="fa-flex-row me-2">
                            <ScrollIntoView selector="#mass-schedule-section">
                                <button
                                    id="edit-announcements"
                                    className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                    onClick={() => handleEditAnnouncements(item._id)}>
                                    <img
                                        src={Images.WRITING_BLUE}
                                        className="fa-icon-btn-md"
                                        alt="icon"
                                    />
                                </ button>
                            </ScrollIntoView>
                            <Tooltip anchorSelect="#edit-announcements" place="top">Upraviť oznamy</Tooltip>
                            <button
                                id="set-visibility-announcements"
                                className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                onClick={() => setVisibility(item._id, !item.isVisible)}>
                                <img
                                    src={Images.EYE_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                            <Tooltip anchorSelect="#set-visibility-announcements" place="top">Nastaviť
                                viditeľnosť</Tooltip>
                            <button
                                id="print-with-header"
                                className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                onClick={() => {
                                    setModalPDFShown(true)
                                    setAnnouToPrint(item)
                                    setPrintText('with-header')
                                }}>
                                <img
                                    src={Images.PRINTER_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                            <Tooltip anchorSelect="#print-with-header" place="top">Tlačiť oznamy s hlavičkou</Tooltip>
                            <button
                                id="print-text"
                                className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                onClick={() => {
                                    setModalPDFShown(true)
                                    setAnnouToPrint(item)
                                    setPrintText('text')
                                }}>
                                <img
                                    src={Images.PRINTER_TEXT_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                            <Tooltip anchorSelect="#print-text" place="top">Tlačiť textové oznamy</Tooltip>
                            <button
                                id="print-table"
                                className="admin-icon-btn admin-icon-btn-shadow h-100 mx-1"
                                onClick={() => {
                                    setModalPDFShown(true)
                                    setAnnouToPrint(item)
                                    setPrintText('table')
                                }}>
                                <img
                                    src={Images.PRINTER_IMG_BLUE}
                                    className="fa-icon-btn-md"
                                    alt="icon"
                                />
                            </ button>
                            <Tooltip anchorSelect="#print-table" place="top">Tlačiť iba program sv. omší</Tooltip>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const AnnouncementsForm = (props) => {
        const [errorDateMessage, setErrorDateMessage] = useState("");
        const [title, setTitle] = useState(props.data.title);
        const [startDate, setStartDate] = useState(props.data.start_date);
        const [imageUrl, setImageUrl] = useState(props.data.image_url !== undefined ? props.data.image_url : "");
        const [endDate, setEndDate] = useState(getNextDate(props.data.start_date, 6));
        const [modalPreviewShown, setModalPreviewShown] = useState(false)

        const [mon, setMon] = useState(props.data.days[0])
        const [tue, setTue] = useState(props.data.days[1])
        const [wed, setWed] = useState(props.data.days[2])
        const [thu, setThu] = useState(props.data.days[3])
        const [fri, setFri] = useState(props.data.days[4])
        const [sat, setSat] = useState(props.data.days[5])
        const [sun, setSun] = useState(props.data.days[6])
        const [texts, setTexts] = useState(props.data.texts)
        const [cleaning, setCleaning] = useState(props.data.cleaning)

        const timesOptions = [
            {value: '7:30', label: '7:30'},
            {value: '9:00', label: '9:00'},
            {value: '10:30', label: '10:30'},
            {value: '16:30', label: '16:30'},
            {value: '17:00', label: '17:00'},
            {value: '17:30', label: '17:30'},
            {value: '18:00', label: '18:00'},
        ];
        const placeOptions = [
            {value: 'PNV', label: 'PNV'},
            {value: 'Milpoš', label: 'Milpoš'},
            {value: 'Rómska kaplnka', label: 'Rómska kaplnka'},
        ];
        const textOptions = [
            {value: 'zbierka', label: 'zbierka'},
            {value: 'milodary', label: 'milodary'},
            {value: 'ohlášky', label: 'ohlášky'},
            {value: 'prvá nedeľa', label: 'prvá nedeľa'},
            {value: 'prvý piatok', label: 'prvý piatok'},
            {value: 'Fatimská sobota', label: 'Fatimská sobota'},
            {value: 'sv. zmierenia pre chorých', label: 'sv. zmierenia pre chorých'},
            {value: 'celodenná adorácia', label: 'celodenná adorácia'},
            {value: 'sviatosť manželstva', label: 'sviatosť manželstva'},
            {value: 'sv. Šarbel', label: 'sv. Šarbel'},
            {value: 'sv. omša za účasti detí', label: 'sv. omša za účasti detí'},
            {value: 'mládežnícka sv. omša', label: 'mládežnícka sv. omša'},
            {value: 'sv. birmovania', label: 'sv. birmovania'},
            {value: 'prvé sv. prijímanie', label: 'prvé sv. prijímanie'},
            {value: 'pohreb', label: 'pohreb'},
            {value: 'zápis úmyslov', label: 'zápis úmyslov'},
        ];
        const [monFeastOptions, setMonFeastOptions] = useState(getFeastList(getNextDate(props.data.start_date, 0)))
        const [tueFeastOptions, setTueFeastOptions] = useState(getFeastList(getNextDate(props.data.start_date, 1)))
        const [wedFeastOptions, setWedFeastOptions] = useState(getFeastList(getNextDate(props.data.start_date, 2)))
        const [thuFeastOptions, setThuFeastOptions] = useState(getFeastList(getNextDate(props.data.start_date, 3)))
        const [friFeastOptions, setFriFeastOptions] = useState(getFeastList(getNextDate(props.data.start_date, 4)))
        const [satFeastOptions, setSatFeastOptions] = useState(getFeastList(getNextDate(props.data.start_date, 5)))
        const [sunFeastOptions, setSunFeastOptions] = useState(getFeastList(getNextDate(props.data.start_date, 6)))

        function handleCreateAnnouncement(title, start_date, image_url, days, texts, cleaning, numOfPrinted1, numOfPrinted2) {
            const data = {
                title: title,
                start_date: start_date,
                image_url: image_url,
                days: days,
                texts: texts,
                cleaning: cleaning,
                numOfPrinted1: numOfPrinted1,
                numOfPrinted2: numOfPrinted2
            };
            if (title.length > 0 && start_date.length > 0) {
                confirmAlertDialog(
                    "UPOZORNENIE",
                    `Naozaj si prajete vytvoriť oznamy pre týždeň ${formatDate(start_date)} - ${formatDate(getNextDate(start_date, 6))}?`,
                    theme,
                    "YesNo",
                    () => {
                        fetch(`${process.env.REACT_APP_API_BASE_URL}/newAnnouncements`, {
                            method: "POST",
                            headers: new Headers({
                                Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            }),
                            body: JSON.stringify(data),
                        })
                            .then((response) => response.json())
                            .then((res) => {
                                document.getElementById('mass-schedule-section').style.display = "none"
                                // window.location.reload();
                                getAllAnnouncements()
                                console.log(res)
                                toastSuccess("Oznamy boli úspešne vytvorené!")
                            })
                            .catch((error) => {
                                document.getElementById('mass-schedule-section').style.display = "none"
                                // window.location.reload();
                                toastError("Vytvorenie oznamov sa nepodarilo!")
                                console.log(error)
                            });
                    }
                )
            } else {
                title.length === 0 ?
                    toastError("Názov oznamov nebol vyplnený")
                    :
                    toastError("Dátum pre oznamy nebol vyplnený")
            }
        }

        function handleSaveAnnouncement(title, start_date, image_url, days, id, texts, cleaning, numOfPrinted1, numOfPrinted2) {
            const data = {
                _id: id,
                title: title,
                start_date: start_date,
                image_url: image_url,
                days: days,
                texts: texts,
                cleaning: cleaning,
                numOfPrinted1: numOfPrinted1,
                numOfPrinted2: numOfPrinted2
            };

            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete upraviť oznamy pre týždeň ${formatDate(start_date)} - ${formatDate(getNextDate(start_date, 6))}?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/editAnnouncements`, {
                        method: "POST",
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        }),
                        body: JSON.stringify(data),
                    })
                        .then((response) => response.json())
                        .then((res) => {
                            document.getElementById('mass-schedule-section').style.display = "none"
                            getAllAnnouncements()
                            toastSuccess("Oznamy boli úspešne aktualizované!")
                        })
                        .catch((error) => {
                            document.getElementById('mass-schedule-section').style.display = "none"
                            toastError("Aktualizovanie oznamov sa nepodarilo!")
                            console.log(error)
                        });
                }
            )
        }

        function handleDeleteFromDB(id, start_date) {
            confirmAlertDialog(
                "UPOZORNENIE",
                `Naozaj si prajete zmazať oznamy pre týždeň ${start_date} - ${getNextDate(start_date, 6)}?`,
                theme,
                "YesNo",
                () => {
                    fetch(`${process.env.REACT_APP_API_BASE_URL}/deleteAnnouncementById/${id}`, {
                        headers: new Headers({
                            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
                        })
                    })
                        .then((res) => {
                            return res.json();
                        })
                        .then((result) => {
                            document.getElementById('mass-schedule-section').style.display = "none"
                            // window.location.reload();
                            getAllAnnouncements()
                            toastSuccess("Oznamy boli úspešne zmazané!")
                        })
                        .catch((error) => {
                            toastError("Chyba pri zmazaní oznamov! Skúste akciu zopakovať alebo kontaktujte správcu webu!")
                            console.log(error)
                        });
                }
            )
        }

        function handleDeleteFromStore(editing) {

            confirmAlertDialog(
                "UPOZORNENIE",
                editing ? "Naozaj si prajete zahodiť urobené zmeny v rozpise?" : "Naozaj si prajete ukončiť vytváranie nového rozpisu?",
                theme,
                "YesNo",
                () => {
                    document.getElementById('mass-schedule-section').style.display = "none"
                    dispatch(setAnnouncementsList(empty_announcements))
                    toastInfo(editing ? "Urobené zmeny boli zahodené!" : "Vytváranie nového rozpisu bolo ukončené!")
                }
            )
        }

        function handlePreview() {
            setModalPreviewShown(true)
        }

        function isValidDate(inputDate) {
            const datum = new Date(inputDate);
            return datum.getDay() === 1;
        }

        const Buttons = () => {
            return (
                props.data._id === undefined ?
                    <div className="fa-flex-col">
                        <button className="btn-primary bg-success font-700 pe-3 mb-2"
                                onClick={() => handleCreateAnnouncement(title, startDate, imageUrl, [mon, tue, wed, thu, fri, sat, sun], texts, cleaning, 0, 0)}>
                            Vytvoriť
                        </ button>
                        <ScrollIntoView selector="#mass-schedule">
                            <button className="btn-primary bg-danger font-700 pe-3 mb-2"
                                    onClick={() => handleDeleteFromStore(false)}>
                                Zahodiť
                            </ button>
                        </ScrollIntoView>
                        <button className="btn-primary font-700 pe-3 mt-4"
                                onClick={() => handlePreview()}>
                            Zobraziť náhľad
                        </ button>
                    </div>
                    :
                    <div className="fa-flex-col">
                        <button className="btn-primary bg-success font-700 pe-3 mb-2"
                                onClick={() => handleSaveAnnouncement(title, startDate, imageUrl, [mon, tue, wed, thu, fri, sat, sun], announcementsFromStore._id, texts, cleaning, announcementsFromStore.numOfPrinted1, announcementsFromStore.numOfPrinted2)}>
                            Uložiť
                        </ button>
                        <button className="btn-primary bg-danger font-700 pe-3 mb-2"
                                onClick={() => handleDeleteFromStore(true)}>
                            Zahodiť zmeny
                        </ button>
                        <button className="btn-primary bg-secondary font-700 pe-3 mb-2"
                                onClick={() => handleDeleteFromDB(announcementsFromStore._id, startDate)}>
                            Zmazať
                        </ button>
                        <button className="btn-primary font-700 pe-3 mt-4"
                                onClick={() => handlePreview(title, startDate, imageUrl, [mon, tue, wed, thu, fri, sat, sun], announcementsFromStore._id)}>
                            Zobraziť náhľad
                        </ button>
                    </div>
            )
        }

        const DaySection = (props) => {
            const [empty, setEmpty] = useState(props.content.empty)
            const [feast_1, setFeast_1] = useState({
                value: props.content.mass[0].feast_day ?? "",
                label: props.content.mass[0].feast_day ?? ""
            })
            const [place_1, setPlace_1] = useState({
                value: props.content.mass[0].place ?? "",
                label: props.content.mass[0].place ?? ""
            })
            const [time_1, setTime_1] = useState({
                value: props.content.mass[0].time ?? "",
                label: props.content.mass[0].time ?? "",
            })
            const [intention_1, setIntention_1] = useState(props.content.mass[0].intention)
            const [confessionStart_1, setConfessionStart_1] = useState(props.content.mass[0].confession_start)
            const [confessionEnd_1, setConfessionEnd_1] = useState(props.content.mass[0].confession_end)

            const [feast_2, setFeast_2] = useState({
                value: props.content.mass[1].feast_day ?? "",
                label: props.content.mass[1].feast_day ?? ""
            })
            const [place_2, setPlace_2] = useState({
                value: props.content.mass[1].place ?? "",
                label: props.content.mass[1].place ?? ""
            })
            const [time_2, setTime_2] = useState({
                value: props.content.mass[1].time ?? "",
                label: props.content.mass[1].time ?? "",
            })
            const [intention_2, setIntention_2] = useState(props.content.mass[1].intention)
            const [confessionStart_2, setConfessionStart_2] = useState(props.content.mass[1].confession_start)
            const [confessionEnd_2, setConfessionEnd_2] = useState(props.content.mass[1].confession_end)

            const [feast_3, setFeast_3] = useState({
                value: props.content.mass[2].feast_day ?? "",
                label: props.content.mass[2].feast_day ?? ""
            })
            const [place_3, setPlace_3] = useState({
                value: props.content.mass[2].place ?? "",
                label: props.content.mass[2].place ?? ""
            })
            const [time_3, setTime_3] = useState({
                value: props.content.mass[2].time ?? "",
                label: props.content.mass[2].time ?? "",
            })
            const [intention_3, setIntention_3] = useState(props.content.mass[2].intention)
            const [confessionStart_3, setConfessionStart_3] = useState(props.content.mass[2].confession_start)
            const [confessionEnd_3, setConfessionEnd_3] = useState(props.content.mass[2].confession_end)

            const [feast_4, setFeast_4] = useState({
                value: props.content.mass[3].feast_day ?? "",
                label: props.content.mass[3].feast_day ?? ""
            })
            const [place_4, setPlace_4] = useState({
                value: props.content.mass[3].place ?? "",
                label: props.content.mass[3].place ?? ""
            })
            const [time_4, setTime_4] = useState({
                value: props.content.mass[3].time ?? "",
                label: props.content.mass[3].time ?? "",
            })
            const [intention_4, setIntention_4] = useState(props.content.mass[3].intention)
            const [confessionStart_4, setConfessionStart_4] = useState(props.content.mass[3].confession_start)
            const [confessionEnd_4, setConfessionEnd_4] = useState(props.content.mass[3].confession_end)

            const [changesMessage, setChangesMessage] = useState("")
            const defaultChangeMessage = `Vaše zmeny nie sú uložené! Prosím, uložte všetky zmeny pred vytvorením oznamov.`
            const feastOptions = props.feastOptions;

            const convertTimeToComparable = (time) => {
                if (time.length === 0)
                    return 1450;

                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };

            function handleSaveDay(empty, date, mass) {
                const massSorted = mass.sort((a, b) => {
                    const timeA = convertTimeToComparable(a.time);
                    const timeB = convertTimeToComparable(b.time);
                    return timeA - timeB;
                });

                switch (props.dayIdx) {
                    case 0:
                        setMon({dayname: "Pondelok", date: date, empty: empty, mass: massSorted})
                        break;
                    case 1:
                        setTue({dayname: "Utorok", date: date, empty: empty, mass: massSorted})
                        break;
                    case 2:
                        setWed({dayname: "Streda", date: date, empty: empty, mass: massSorted})
                        break;
                    case 3:
                        setThu({dayname: "Štvrtok", date: date, empty: empty, mass: massSorted})
                        break;
                    case 4:
                        setFri({dayname: "Piatok", date: date, empty: empty, mass: massSorted})
                        break;
                    case 5:
                        setSat({dayname: "Sobota", date: date, empty: empty, mass: massSorted})
                        break;
                    case 6:
                        setSun({dayname: "Nedeľa", date: date, empty: empty, mass: massSorted})
                        break;

                    default:
                        console.log("Wrong params! -> " + empty, date, massSorted)
                }
                setChangesMessage("")
                toastInfo(`Sv. omše (${props.title} - ${formatDate(date)}) boli uložené`)
            }

            return (
                <div className="my-5">
                    <h4 className="font-700 color-3">{props.title}</h4>
                    <div className="fa-flex-row align-items-center">
                        <input type="checkbox"
                               id={"empty-day-" + props.dayIdx}
                               placeholder='napr. 22. týždeň v období cez rok "C"'
                               className="form-check-input fa-input-border"
                               checked={empty}
                               onChange={(e) => {
                                   setEmpty(!empty)
                                   setChangesMessage(defaultChangeMessage)
                               }}
                        />
                        <label className="fa-form-label font-400 color-8 mt-1 ms-3"
                               htmlFor={"empty-day-" + props.dayIdx}>
                            nie je sv. omša v tento deň
                        </label>
                    </div>
                    {!empty ?
                        <div className={props.content}>
                            <div className="fa-flex-row my-3">
                                <div className="me-2">
                                    <label className="fa-form-label font-400 color-8 mb-1"
                                           htmlFor={"feast-" + props.dayIdx + "-0"}>
                                        Liturgický prehľad
                                    </label>
                                    <CreatableSelect id={"feast-" + props.dayIdx + "-0"}
                                                     name="massFeast"
                                                     className="input-feast"
                                                     isClearable
                                                     options={feastOptions}
                                                     value={feast_1}
                                                     onChange={(elm) => {
                                                         setFeast_1(elm)
                                                         feast_2?.value === "" ? setFeast_2(elm) : setFeast_2(feast_2)
                                                         feast_3?.value === "" ? setFeast_3(elm) : setFeast_3(feast_3)
                                                         feast_4?.value === "" ? setFeast_4(elm) : setFeast_4(feast_4)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}
                                    />
                                </div>
                                <div className="me-2">
                                    <label className="fa-form-label font-400 color-8 mb-1"
                                           htmlFor={"place-" + props.dayIdx + "-0"}>Miesto</label>
                                    <CreatableSelect id={"place-" + props.dayIdx + "-0"} className="input-place"
                                                     isClearable
                                                     options={placeOptions}
                                                     name="place"
                                                     value={place_1}
                                                     onChange={(elm) => {
                                                         setPlace_1(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}
                                    />
                                </div>
                                <div className="me-2">
                                    <label className="fa-form-label font-400 color-8 mb-1"
                                           htmlFor={"time-" + props.dayIdx + "-0"}>Čas</label>
                                    <CreatableSelect id={"time-" + props.dayIdx + "-0"} className="input-time"
                                                     isClearable
                                                     options={timesOptions}
                                                     name="time"
                                                     value={time_1}
                                                     onChange={(elm) => {
                                                         setTime_1(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <label className="fa-form-label font-400 color-8 mb-1"
                                           htmlFor={"intention-" + props.dayIdx + "-0"}>Úmysel</label>
                                    <input type="text"
                                           id={"intention-" + props.dayIdx + "-0"}
                                           name="intention"
                                           className="form-control input-intention"
                                           value={intention_1}
                                           onChange={(e) => {
                                               setIntention_1(e.target.value)
                                               setChangesMessage(defaultChangeMessage)
                                           }}
                                    />
                                </div>
                                <div className="me-2">
                                    <label className="fa-form-label font-400 color-8 mb-1" htmlFor="feast-1">
                                        Sviatosť zmierenia
                                    </label>
                                    <div className="fa-flex-row align-items-center">
                                        <label className="fa-form-label font-400 color-8 me-2"
                                               htmlFor={"confession-start-" + props.dayIdx + "-0"}>od</label>
                                        <input type="time"
                                               id={"confession-start-" + props.dayIdx + "-0"}
                                               name="confession_start"
                                               className="form-control input-confession"
                                               value={confessionStart_1}
                                               onChange={(e) => {
                                                   setConfessionStart_1(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                        <label className="fa-form-label font-400 color-8 mx-2"
                                               htmlFor={"confession-end-" + props.dayIdx + "-0"}>do</label>
                                        <input type="time"
                                               id={"confession-end-" + props.dayIdx + "-0"}
                                               name="confession_end"
                                               className="form-control input-confession"
                                               value={confessionEnd_1}
                                               onChange={(e) => {
                                                   setConfessionEnd_1(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="fa-flex-row my-3">
                                <div className="me-2">
                                    <CreatableSelect id={"feast-" + props.dayIdx + "-1"}
                                                     name="massFeast"
                                                     className="input-feast"
                                                     isClearable
                                                     options={feastOptions}
                                                     value={feast_2}
                                                     onChange={(elm) => {
                                                         setFeast_2(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}
                                    />
                                </div>
                                <div className="me-2">
                                    <CreatableSelect id={"place-" + props.dayIdx + "-1"} className="input-place"
                                                     isClearable
                                                     options={placeOptions}
                                                     name="place"
                                                     value={place_2}
                                                     onChange={(elm) => {
                                                         setPlace_2(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <CreatableSelect id={"time-" + props.dayIdx + "-1"} className="input-time"
                                                     isClearable
                                                     options={timesOptions}
                                                     name="time"
                                                     value={time_2}
                                                     onChange={(elm) => {
                                                         setTime_2(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <input type="text"
                                           id={"intention-" + props.dayIdx + "-1"}
                                           name="intention"
                                           className="form-control input-intention"
                                           value={intention_2}
                                           onChange={(e) => {
                                               setIntention_2(e.target.value)
                                               setChangesMessage(defaultChangeMessage)
                                           }}
                                    />
                                </div>
                                <div className="me-2">
                                    <div className="fa-flex-row align-items-center">
                                        <label className="fa-form-label font-400 color-8 me-2"
                                               htmlFor={"confession-start-" + props.dayIdx + "-1"}>od</label>
                                        <input type="time"
                                               id={"confession-start-" + props.dayIdx + "-1"}
                                               name="confession_start"
                                               className="form-control input-confession"
                                               value={confessionStart_2}
                                               onChange={(e) => {
                                                   setConfessionStart_2(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                        <label className="fa-form-label font-400 color-8 mx-2"
                                               htmlFor={"confession-end-" + props.dayIdx + "-1"}>do</label>
                                        <input type="time"
                                               id={"confession-end-" + props.dayIdx + "-1"}
                                               name="confession_end"
                                               className="form-control input-confession"
                                               value={confessionEnd_2}
                                               onChange={(e) => {
                                                   setConfessionEnd_2(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="fa-flex-row my-3">
                                <div className="me-2">
                                    <CreatableSelect id={"feast-" + props.dayIdx + "-2"}
                                                     name="massFeast"
                                                     className="input-feast"
                                                     isClearable
                                                     options={feastOptions}
                                                     value={feast_3}
                                                     onChange={(elm) => {
                                                         setFeast_3(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <CreatableSelect id={"place-" + props.dayIdx + "-2"} className="input-place"
                                                     isClearable
                                                     options={placeOptions}
                                                     name="place"
                                                     value={place_3}
                                                     onChange={(elm) => {
                                                         setPlace_3(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <CreatableSelect id={"time-" + props.dayIdx + "-2"} className="input-time"
                                                     isClearable
                                                     options={timesOptions}
                                                     name="time"
                                                     value={time_3}
                                                     onChange={(elm) => {
                                                         setTime_3(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <input type="text"
                                           id={"intention-" + props.dayIdx + "-2"}
                                           name="intention"
                                           className="form-control input-intention"
                                           value={intention_3}
                                           onChange={(e) => {
                                               setIntention_3(e.target.value)
                                               setChangesMessage(defaultChangeMessage)
                                           }}
                                    />
                                </div>
                                <div className="me-2">
                                    <div className="fa-flex-row align-items-center">
                                        <label className="fa-form-label font-400 color-8 me-2"
                                               htmlFor={"confession-start-" + props.dayIdx + "-2"}>od</label>
                                        <input type="time"
                                               id={"confession-start-" + props.dayIdx + "-2"}
                                               name="confession_start"
                                               className="form-control input-confession"
                                               value={confessionStart_3}
                                               onChange={(e) => {
                                                   setConfessionStart_3(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                        <label className="fa-form-label font-400 color-8 mx-2"
                                               htmlFor={"confession-end-" + props.dayIdx + "-2"}>do</label>
                                        <input type="time"
                                               id={"confession-end-" + props.dayIdx + "-2"}
                                               name="confession_end"
                                               className="form-control input-confession"
                                               value={confessionEnd_3}
                                               onChange={(e) => {
                                                   setConfessionEnd_3(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="fa-flex-row my-3">
                                <div className="me-2">
                                    <CreatableSelect id={"feast-" + props.dayIdx + "-3"}
                                                     name="massFeast"
                                                     className="input-feast"
                                                     isClearable
                                                     options={feastOptions}
                                                     value={feast_4}
                                                     onChange={(elm) => {
                                                         setFeast_4(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}
                                    />
                                </div>
                                <div className="me-2">
                                    <CreatableSelect id={"place-" + props.dayIdx + "-3"} className="input-place"
                                                     isClearable
                                                     options={placeOptions}
                                                     name="place"
                                                     value={place_4}
                                                     onChange={(elm) => {
                                                         setPlace_4(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <CreatableSelect id={"time-" + props.dayIdx + "-3"} className="input-time"
                                                     isClearable
                                                     options={timesOptions}
                                                     name="time"
                                                     value={time_4}
                                                     onChange={(elm) => {
                                                         setTime_4(elm)
                                                         setChangesMessage(defaultChangeMessage)
                                                     }}/>
                                </div>
                                <div className="me-2">
                                    <input type="text"
                                           id={"intention-" + props.dayIdx + "-3"}
                                           name="intention"
                                           className="form-control input-intention"
                                           value={intention_4}
                                           onChange={(e) => {
                                               setIntention_4(e.target.value)
                                               setChangesMessage(defaultChangeMessage)
                                           }}
                                    />
                                </div>
                                <div className="me-2">
                                    <div className="fa-flex-row align-items-center">
                                        <label className="fa-form-label font-400 color-8 me-2"
                                               htmlFor={"confession-start-" + props.dayIdx + "-3"}>od</label>
                                        <input type="time"
                                               id={"confession-start-" + props.dayIdx + "-3"}
                                               name="confession_start"
                                               className="form-control input-confession"
                                               value={confessionStart_4}
                                               onChange={(e) => {
                                                   setConfessionStart_4(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                        <label className="fa-form-label font-400 color-8 mx-2"
                                               htmlFor={"confession-end-" + props.dayIdx + "-3"}>do</label>
                                        <input type="time"
                                               id={"confession-end-" + props.dayIdx + "-3"}
                                               name="confession_end"
                                               className="form-control input-confession"
                                               value={confessionEnd_4}
                                               onChange={(e) => {
                                                   setConfessionEnd_4(e.target.value)
                                                   setChangesMessage(defaultChangeMessage)
                                               }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div/>
                    }
                    <div className="fa-flex-row justify-content-start mt-2">
                        {changesMessage &&
                            <>
                                <button
                                    className="btn-primary"
                                    onClick={() =>
                                        handleSaveDay(empty, getNextDate(startDate, props.dayIdx),
                                            [
                                                {
                                                    feast_day: feast_1?.value ?? "",
                                                    place: place_1?.value.replace(/-/g, '') ?? "",
                                                    time: time_1?.value.replace(/[^0-9]/g, ':') ?? "",
                                                    intention: intention_1.replace(/([,])(?![ ])/g, '$1 '),
                                                    confession_start: confessionStart_1,
                                                    confession_end: confessionEnd_1
                                                },
                                                {
                                                    feast_day: feast_2?.value ?? "",
                                                    place: place_2?.value.replace(/-/g, '') ?? "",
                                                    time: time_2?.value.replace(/[^0-9]/g, ':') ?? "",
                                                    intention: intention_2.replace(/([,])(?![ ])/g, '$1 '),
                                                    confession_start: confessionStart_2,
                                                    confession_end: confessionEnd_2
                                                },
                                                {
                                                    feast_day: feast_3?.value ?? "",
                                                    place: place_3?.value.replace(/-/g, '') ?? "",
                                                    time: time_3?.value.replace(/[^0-9]/g, ':') ?? "",
                                                    intention: intention_3.replace(/([,])(?![ ])/g, '$1 '),
                                                    confession_start: confessionStart_3,
                                                    confession_end: confessionEnd_3
                                                },
                                                {
                                                    feast_day: feast_4?.value ?? "",
                                                    place: place_4?.value.replace(/-/g, '') ?? "",
                                                    time: time_4?.value.replace(/[^0-9]/g, ':') ?? "",
                                                    intention: intention_4.replace(/([,])(?![ ])/g, '$1 '),
                                                    confession_start: confessionStart_4,
                                                    confession_end: confessionEnd_4
                                                }]
                                        )
                                    }>
                                    Uložiť
                                </ button>
                                <div className="text-danger ms-3">{changesMessage}</div>
                            </>
                        }
                    </div>
                </div>
            )
        }

        const TextsSection = (props) => {
            const [title_1, setTitle_1] = useState({
                value: props.texts[0]?.title ?? "",
                label: props.texts[0]?.title ?? ""
            })
            const [content_1, setContent_1] = useState(props.texts[0]?.content ?? "");
            const [title_2, setTitle_2] = useState({
                value: props.texts[1]?.title ?? "",
                label: props.texts[1]?.title ?? ""
            })
            const [content_2, setContent_2] = useState(props.texts[1]?.content ?? "");
            const [title_3, setTitle_3] = useState({
                value: props.texts[2]?.title ?? "",
                label: props.texts[2]?.title ?? ""
            })
            const [content_3, setContent_3] = useState(props.texts[2]?.content ?? "");
            const [title_4, setTitle_4] = useState({
                value: props.texts[3]?.title ?? "",
                label: props.texts[3]?.title ?? ""
            })
            const [content_4, setContent_4] = useState(props.texts[3]?.content ?? "");
            const [title_5, setTitle_5] = useState({
                value: props.texts[4]?.title ?? "",
                label: props.texts[4]?.title ?? ""
            })
            const [content_5, setContent_5] = useState(props.texts[4]?.content ?? "");
            const [title_6, setTitle_6] = useState({
                value: props.texts[5]?.title ?? "",
                label: props.texts[5]?.title ?? ""
            })
            const [content_6, setContent_6] = useState(props.texts[5]?.content ?? "");
            const [title_7, setTitle_7] = useState({
                value: props.texts[6]?.title ?? "",
                label: props.texts[6]?.title ?? ""
            })
            const [content_7, setContent_7] = useState(props.texts[6]?.content ?? "");
            const [title_8, setTitle_8] = useState({
                value: props.texts[7]?.title ?? "",
                label: props.texts[7]?.title ?? ""
            })
            const [content_8, setContent_8] = useState(props.texts[7]?.content ?? "");
            const [title_9, setTitle_9] = useState({
                value: props.texts[8]?.title ?? "",
                label: props.texts[8]?.title ?? ""
            })
            const [content_9, setContent_9] = useState(props.texts[8]?.content ?? "");
            const [title_10, setTitle_10] = useState({
                value: props.texts[9]?.title ?? "",
                label: props.texts[9]?.title ?? ""
            })
            const [content_10, setContent_10] = useState(props.texts[9]?.content ?? "");

            const [changesMessage, setChangesMessage] = useState("")
            const defaultChangeMessage = `Vaše zmeny nie sú uložené! Prosím, uložte všetky zmeny pred vytvorením oznamov.`

            function handleSaveTexts() {
                setTexts([
                    {title: title_1?.value ?? "", content: content_1},
                    {title: title_2?.value ?? "", content: content_2},
                    {title: title_3?.value ?? "", content: content_3},
                    {title: title_4?.value ?? "", content: content_4},
                    {title: title_5?.value ?? "", content: content_5},
                    {title: title_6?.value ?? "", content: content_6},
                    {title: title_7?.value ?? "", content: content_7},
                    {title: title_8?.value ?? "", content: content_8},
                    {title: title_9?.value ?? "", content: content_9},
                    {title: title_10?.value ?? "", content: content_10},
                ])

                setChangesMessage("")
                toastInfo(`Textové oznamy boli uložené!`)
            }

            return (
                <div className="my-5">
                    <div className="fa-flex-row">
                        <h4 className="font-700 color-3">TEXTOVÉ OZNAMY</h4>
                        {changesMessage && <div className="text-danger ms-3 mt-1">{changesMessage}</div>}
                    </div>

                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">1. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-1"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-1"
                                         value={title_1}
                                         onChange={(elm) => {
                                             setTitle_1(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-1"
                            name="content-1"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_1}
                            onChange={(e) => {
                                setContent_1(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="admin-line w-75 rounded-5"/>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">2. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-2"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-2"
                                         value={title_2}
                                         onChange={(elm) => {
                                             setTitle_2(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-2"
                            name="content-2"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_2}
                            onChange={(e) => {
                                setContent_2(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="admin-line w-75 rounded-5"/>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">3. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-3"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-3"
                                         value={title_3}
                                         onChange={(elm) => {
                                             setTitle_3(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-3"
                            name="content-3"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_3}
                            onChange={(e) => {
                                setContent_3(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="admin-line w-75 rounded-5"/>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">4. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-4"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-4"
                                         value={title_4}
                                         onChange={(elm) => {
                                             setTitle_4(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-4"
                            name="content-4"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_4}
                            onChange={(e) => {
                                setContent_4(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="admin-line w-75 rounded-5"/>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">5. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-5"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-5"
                                         value={title_5}
                                         onChange={(elm) => {
                                             setTitle_5(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-5"
                            name="content-5"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_5}
                            onChange={(e) => {
                                setContent_5(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">6. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-6"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-6"
                                         value={title_6}
                                         onChange={(elm) => {
                                             setTitle_6(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-6"
                            name="content-6"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_6}
                            onChange={(e) => {
                                setContent_6(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">7. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-7"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-7"
                                         value={title_7}
                                         onChange={(elm) => {
                                             setTitle_7(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-7"
                            name="content-7"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_7}
                            onChange={(e) => {
                                setContent_7(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">8. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-8"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-8"
                                         value={title_8}
                                         onChange={(elm) => {
                                             setTitle_8(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-8"
                            name="content-8"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_8}
                            onChange={(e) => {
                                setContent_8(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">9. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-9"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-9"
                                         value={title_9}
                                         onChange={(elm) => {
                                             setTitle_9(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-9"
                            name="content-9"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_9}
                            onChange={(e) => {
                                setContent_9(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-700 color-3">10. oznam - krátky nadpis:</h6>
                        <CreatableSelect id="text-title-10"
                                         className="w-25 mb-2"
                                         isClearable
                                         placeholder="Zadajte nadpis"
                                         options={textOptions}
                                         name="title-10"
                                         value={title_10}
                                         onChange={(elm) => {
                                             setTitle_10(elm)
                                             setChangesMessage(defaultChangeMessage)
                                         }}
                        />
                        <h6 className="font-400 color-3">Znenie oznamu:</h6>
                        <textarea
                            id="text-content-10"
                            name="content-10"
                            placeholder="Zadajte text oznamu"
                            className="form-control w-75 mb-2"
                            value={content_10}
                            onChange={(e) => {
                                setContent_10(e.target.value)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>

                    {changesMessage &&
                        <button className="btn-primary align-self-start font-700"
                                onClick={() => handleSaveTexts()}>
                            Uložiť
                        </ button>
                    }
                </div>
            )
        }

        const CleaningSection = (props) => {
            const [groupId, setGroupId] = useState({
                value: props.cleaning[0].groupId,
                label: props.cleaning[0].groupTitle
            });
            const [dayAndTime, setDayAndTime] = useState(props.cleaning[0].dayAndTime);
            const [changesMessage, setChangesMessage] = useState("")
            const defaultChangeMessage = `Vaše zmeny nie sú uložené! Prosím, uložte všetky zmeny pred vytvorením/aktualizovaním oznamov.`

            function handleSaveCleaning() {
                if (groupId.value.length !== 0 && dayAndTime.length !== 0) {
                    setCleaning([{dayAndTime: dayAndTime, groupId: groupId.value, groupTitle: groupId.label}])
                    setChangesMessage("")
                    toastInfo(`Rozpis na upratovanie bol uložený!`)
                } else {
                    toastError("Detaily upratovania neboli zadané!")
                }
            }

            return (
                <div className="my-5">
                    <div className="fa-flex-row">
                        <h4 className="font-700 color-3">UPRATOVANIE</h4>
                        {changesMessage && <div className="text-danger ms-3 mt-1">{changesMessage}</div>}
                    </div>


                    <div className="fa-flex-col my-3">
                        <h6 className="font-400 color-3">Kedy:</h6>
                        <input type="text"
                               id="text-title-5"
                               name="title-5"
                               className="form-control w-50 mb-2"
                               value={dayAndTime}
                               placeholder="Napr. v sobotu - 8:00 hod."
                               onChange={(e) => {
                                   setDayAndTime(e.target.value)
                                   setChangesMessage(defaultChangeMessage)
                               }}
                        />
                    </div>
                    <div className="fa-flex-col my-3">
                        <h6 className="font-400 color-3">Upratovacia skupina:</h6>
                        <Select
                            id={"cleaning-group"}
                            name="cleaningGroup"
                            className="w-50"
                            isClearable
                            isSearchable
                            placeholder="Vyberte jednu upratovaciu skupinu"
                            defaultValue={groupId}
                            options={allGroupsOptions}
                            value={groupId}
                            onChange={(elm) => {
                                setGroupId(elm)
                                setChangesMessage(defaultChangeMessage)
                            }}
                        />
                    </div>

                    {changesMessage &&
                        <button className="btn-primary align-self-start font-700"
                                onClick={() => handleSaveCleaning()}>
                            Uložiť
                        </ button>
                    }
                </div>
            )
        }

        const ModalPreview = (props) => {

            return (
                <Modal show={props.show} onHide={props.onHide} data-theme={theme} dialogClassName="modal-pdf p-2">
                    <Modal.Body className="fa-flex-col align-items-center background-color-1 rounded-5 my-3">
                        <AnnouncementsPDF title={title} data={[mon, tue, wed, thu, fri, sat, sun]} image={imageUrl}
                                          id={'12345'}
                                          texts={texts}
                                          printText={'with-header'}
                                          cleaning={cleaning}/>
                        <AnnouncementsPDF title={title} data={[mon, tue, wed, thu, fri, sat, sun]} image={imageUrl}
                                          id={'12345'}
                                          texts={texts}
                                          printText={'text'}
                                          cleaning={cleaning}/>
                    </Modal.Body>
                </Modal>
            )
        }

        return (
            <div className="fa-flex-col">
                <ModalPreview show={modalPreviewShown} onHide={() => setModalPreviewShown(false)}/>
                <div className="fa-flex-row justify-content-between mb-2">
                    <div className="left-section">
                        <div>
                            <label className="fa-form-label font-400 color-8 mb-1" htmlFor="announ-title">
                                Názov oznamov:
                            </label>
                            <input type="text"
                                   id="announ-title"
                                   placeholder='napr. 22. týždeň v období cez rok "C"'
                                   className="form-control"
                                   value={title}
                                   onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>

                        <div className="mt-3">
                            <label className="fa-form-label font-400 color-8 mb-1" htmlFor="announ-startdate">
                                Dátum:
                            </label>
                            <div className="fa-flex-row align-items-center">
                                <label className="fa-form-label font-400 color-8 me-2 mt-2"
                                       htmlFor="end-date">od</label>
                                <input type="date"
                                       id="announ-startdate"
                                       className="form-control w-25"
                                       value={startDate}
                                       onChange={(e) => {
                                           if (isValidDate(e.target.value)) {
                                               setStartDate(e.target.value);
                                               setMonFeastOptions(getFeastList(getNextDate(e.target.value, 0)))
                                               setTueFeastOptions(getFeastList(getNextDate(e.target.value, 1)))
                                               setWedFeastOptions(getFeastList(getNextDate(e.target.value, 2)))
                                               setThuFeastOptions(getFeastList(getNextDate(e.target.value, 3)))
                                               setFriFeastOptions(getFeastList(getNextDate(e.target.value, 4)))
                                               setSatFeastOptions(getFeastList(getNextDate(e.target.value, 5)))
                                               setSunFeastOptions(getFeastList(getNextDate(e.target.value, 6)))
                                               setEndDate(getNextDate(e.target.value, 6));
                                               setCleaning([{
                                                   dayAndTime: props.data.cleaning[0].dayAndTime,
                                                   groupId: getGroupByDate(getNextDate(e.target.value, 5)).value,
                                                   groupTitle: getGroupByDate(getNextDate(e.target.value, 5)).label
                                               }])
                                               setErrorDateMessage("")
                                           } else {
                                               setStartDate(e.target.value);
                                               setErrorDateMessage("Program sv. omší musí začínať pondelkom!");
                                           }
                                       }}
                                />
                                <label className="fa-form-label font-400 color-8 mx-2 mt-2"
                                       htmlFor="announ-enddate">do</label>
                                <input type="date"
                                       id="announ-enddate"
                                       disabled
                                       className="form-control w-25"
                                       value={endDate}
                                       onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                            {errorDateMessage && <div className="text-danger">{errorDateMessage}</div>}
                        </div>

                        <div className="mt-3">
                            <label className="fa-form-label font-400 color-8 mb-1" htmlFor="announ-title">
                                URL adresa:
                            </label>
                            <input type="text"
                                   id="announ-url"
                                   placeholder='Ak si prajete oznamy zobraziť ako obrázok, vložte sem jeho ID'
                                   className="form-control w-75"
                                   value={imageUrl}
                                   onChange={(e) => setImageUrl(e.target.value)}
                            />
                        </div>
                    </div>
                    <Buttons/>
                </div>
                {title.length !== 0 && startDate.length !== 0 && endDate.length !== 0 && !imageUrl.length ?
                    <div>
                        <DaySection dayIdx={0} content={mon} feastOptions={monFeastOptions} title="PONDELOK"/>
                        <DaySection dayIdx={1} content={tue} feastOptions={tueFeastOptions} title="UTOROK"/>
                        <DaySection dayIdx={2} content={wed} feastOptions={wedFeastOptions} title="STREDA"/>
                        <DaySection dayIdx={3} content={thu} feastOptions={thuFeastOptions} title="ŠTVRTOK"/>
                        <DaySection dayIdx={4} content={fri} feastOptions={friFeastOptions} title="PIATOK"/>
                        <DaySection dayIdx={5} content={sat} feastOptions={satFeastOptions} title="SOBOTA"/>
                        <DaySection dayIdx={6} content={sun} feastOptions={sunFeastOptions} title="NEDEĽA"/>

                        <CleaningSection cleaning={cleaning}/>
                        <TextsSection texts={texts}/>
                    </div>
                    :
                    <div/>
                }
            </div>
        )
    }


    return (
        <div className="fa-flex-col" id='mass-schedule'>
            <SectionHeading title={"Rozpis sv. omší"} actualId={'mass-schedule'}/>
            <div className="fa-flex-row justify-content-between w-100">
                <ElementsList list={allAnnouncements}/>
                <ScrollIntoView selector="#mass-schedule-section">
                    <button className="btn-primary align-self-start font-700"
                            onClick={() => handleNewAnnouncements()}>
                        Vytvoriť rozpis
                    </ button>
                </ScrollIntoView>
            </div>

            {announcementsFromStore !== undefined ?
                <div className="fa-flex-col" id='mass-schedule-section'>
                    <SectionHeading title={"Editovanie rozpisu"}/>
                    {Object.keys(announcementsFromStore).length > 0 ?
                        <AnnouncementsForm data={announcementsFromStore}/>
                        :
                        <div className="app-container mt-5">
                            <LoadingSpinner/>
                        </div>
                    }
                </div>
                :
                <div/>
            }
        </div>
    );
};

export default AdminMassSchedule;