import React, {useState} from 'react';
import {Images} from "../constants/Images";
import {confirmAlertDialog} from "./confirmAlertDialog";

const SectionHeading = (props) => {

    const [theme, setTheme] = useState(
        localStorage.getItem("theme") != null
            ? localStorage.getItem("theme")
            : "light"
    );

    function handleComeBack(id) {
        if (document.getElementById(id + '-section')) {
            if (document.getElementById(id + '-section').style.display === "flex") {
                confirmAlertDialog(
                    "UPOZORNENIE",
                    "Všetky neuložené zmeny, ktoré ste urobili v tejto sekcii budú stratené. Prajete si vrátiť sa späť?",
                    theme,
                    "YesNo",
                    () => {
                        document.getElementById(id + '-section').style.display = "none"
                        document.getElementById(id).style.display = "none"
                        document.getElementById('admin-buttons').style.display = "flex"
                    }
                )
            } else {
                document.getElementById(id + '-section').style.display = "none"
                document.getElementById(id).style.display = "none"
                document.getElementById('admin-buttons').style.display = "flex"
            }
        } else {
            document.getElementById(id).style.display = "none"
            document.getElementById('admin-buttons').style.display = "flex"
        }


        // ZAPNUT confirm alert dialog pre produkciu
        // confirmAlertDialog(
        //     "UPOZORNENIE",
        //     "Všetky neuložené zmeny, ktoré ste urobili v tejto sekcii budú stratené. Prajete si pokračovať?",
        //     theme,
        //     "YesNo",
        //     () => {
        //         document.getElementById(id).style.display = "none"
        //         document.getElementById('admin-buttons').style.display = "flex"
        //     }
        // )
    }

    return (
        <div>
            {props.actualId ?
                <button className="btn-primary text-start font-700 pe-3"
                        onClick={() => handleComeBack(props.actualId)}>
                    <img src={Images.LEFT_ARROW_WHITE} className="fa-icon-btn-sm me-2 my-1" alt="icon"/>
                    Vrátiť sa späť
                </ button>
                :
                <div/>
            }
            <div className="background-color-6 rounded-3 my-4 py-1 w-100">
                <h4 className="font-700 color-1 text-center mt-1">{props.title}</h4>
            </div>
        </div>
    );
};

export default SectionHeading;