import React from 'react';
import SectionHeading from "./SectionHeading";

const AdminPsalmistsSchedule = () => {
    return (
        <div className="fa-flex-col" id='psalmists-schedule'>
            <SectionHeading title={"Rozpis žalmistov"} actualId='psalmists-schedule'/>
        </div>
    );
};

export default AdminPsalmistsSchedule;