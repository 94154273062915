import React from 'react';
import SectionHeading from "./SectionHeading";

const AdminLecturersSchedule = () => {
    return (
        <div className="fa-flex-col" id='lecturers-schedule'>
            <SectionHeading title={"Rozpis lektorov"} actualId='lecturers-schedule'/>
        </div>
    );
};

export default AdminLecturersSchedule;