import React, {useState} from 'react';
import {Link, NavLink, useNavigate} from "react-router-dom";
import '../assets/css/Navbar.css'
import '../assets/css/Icons.css'
import {Images} from "../constants/Images";
import {ACCESS_TOKEN_KEY} from "../constants";

const Navbar = () => {

    const [isMenu, setIsMenu] = useState(false);
    const [isSubmenu, setIsSubmenu] = useState(false);
    const [classMenu, setClassMenu] = useState("main-menu menu-right");
    const [classSubmenu, setClassSubmenu] = useState("sub__menus");
    const navigate = useNavigate();
    const toggleMenu = () => {
        setIsMenu(!isMenu);
        isMenu ?
            setClassMenu("main-menu menu-right")
            :
            setClassMenu("main-menu menu-right menu-overlay")
    };
    const toggleSubmenu = () => {
        setIsSubmenu(!isSubmenu);
        isSubmenu ?
            setClassSubmenu('sub__menus')
            :
            setClassSubmenu('sub__menus sub__menus__Active')
    };

    const NavBtn = (props) => {
        return (
            <li className="menu-item">
                <NavLink exact={"true"} activeclassname='is-active' onClick={toggleMenu}
                         to={props.navigateUrl}>
                    <span className="font-800 color-3">{props.btnTitle}</span>
                </NavLink>
            </li>
        )
    }

    const NestedNavBtn = (props) => {
        return (
            <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows">
                <Link to={props.navigateUrl}><span className="font-800 color-3">{props.btnTitle}</span>
                    <img src={Images.DOWN_ARROW_BLUE} className="fa-icon-menu-down ms-2" alt="icon"/>
                </Link>
                <ul className={classSubmenu}>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/historia`}>
                        <span className="font-600 color-3">{"História"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/ondrej`}>
                        <span className="font-600 color-3">{"Sv. Ondrej"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/maria_goretti`}>
                        <span className="font-600 color-3">{"Sv. Mária Goretti"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/pomocnica_krestanov`}>
                        <span className="font-600 color-3">{"Panna Mária, Pomocnica kresťanov"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/lektori`}>
                        <span className="font-600 color-3">{"Lektori"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/zalmisti`}>
                        <span className="font-600 color-3">{"Žalmisti"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/ruzenec`}>
                        <span className="font-600 color-3">{"Ružencové bratstvo"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/ministranti`}>
                        <span className="font-600 color-3">{"Miništranti"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/farska_rada`}>
                        <span className="font-600 color-3">{"Farská rada"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/zbor`}>
                        <span className="font-600 color-3">{"Zbor"}</span>
                    </NavLink></li>
                    <li><NavLink onClick={toggleMenu} activeclassname='is-active' to={`/sarbel`}>
                        <span className="font-600 color-3">{"Sv. Šarbel"}</span>
                    </NavLink></li>
                </ul>
            </li>
        )
    }

    return (
        <div className="navbar-container w-100 px-3">
            <div className="navbar-title">
                <div className="fa-pointer fa-flex-col justify-content-center my-3" onClick={() => navigate("/")}>
                    <h2 className="font-800 color-3 text-start">Farnosť sv. Ondreja</h2>
                    <h4 className="font-400 color-3 text-start">Pečovská Nová Ves</h4>
                </div>
            </div>

            <div className="navbar-buttons">
                <nav className="main-nav ">

                    {/* Responsive Menu Button */}
                    {isMenu === true ?
                        <span className="menubar__button mt-3" style={{display: 'none'}}
                              onClick={toggleMenu}>
                            <img src={Images.CLOSE_BLUE} className="fa-icon-menu m-1" alt="icon"/>
                        </span>
                        :
                        <span className="menubar__button mt-3" style={{display: 'none'}}
                              onClick={toggleMenu}>
                            <img src={Images.MENU_BLUE} className="fa-icon-menu m-1" alt="icon"/>
                            </span>
                    }

                    <ul className={classMenu}>
                        <NavBtn navigateUrl={"/"} btnTitle={"Úvod"}/>
                        <NestedNavBtn navigateUrl={"#"} btnTitle={"Informácie"}/>
                        <NavBtn navigateUrl={"/aktuality"} btnTitle={"Aktuality"}/>
                        <NavBtn navigateUrl={"/sviatosti"} btnTitle={"Sviatosti"}/>
                        <NavBtn navigateUrl={"/fotogaleria"} btnTitle={"Fotogaléria"}/>
                        <NavBtn navigateUrl={"/kontakt"} btnTitle={"Kontakt"}/>
                        {localStorage.getItem(ACCESS_TOKEN_KEY) && <NavBtn navigateUrl={"/admin"} btnTitle={"Admin"}/>}
                    </ul>

                </nav>
            </div>
        </div>
    )
};

export default Navbar;