import { createSlice } from "@reduxjs/toolkit";

export const parishInfoSlice = createSlice({
    name: "parishInfoList",
    initialState: {
        value: [],
    },
    reducers: {
        setParishInfoList: (state, action) => {
            state.value = action.payload;
            // console.log("STORE", state.value);
        },
    },
});

export const { setParishInfoList } = parishInfoSlice.actions;

export default parishInfoSlice.reducer;
