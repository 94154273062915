import React from 'react';
import {Images} from "../constants/Images";

const ContactsTab = ({contactData, footer, massData}) => {
    const ContactItem = (props) => {

        return (
            footer ?
                <div className="fa-flex-row align-items-center justify-content-between mt-2 w-100">
                    <div className="fa-flex-row align-items-center justify-content-end">
                        <img
                            src={props.icon}
                            className="fa-icon-small"
                            alt="icon"
                        />
                    </div>
                    {props.text_2.length > 0 ?
                        <div className="">
                            <h6 className="font-300 color-1 text-end">{props.text_1}</h6>
                            <h6 className="font-300 color-1 text-end">{props.text_2}</h6>
                        </div>
                        :
                        <div className="">
                            <h6 className="font-300 color-1 text-end">{props.text_1}</h6>
                        </div>
                    }
                </div>
                :
                <div className="fa-flex-row align-items-center justify-content-start mt-2">
                    <div className="fa-flex-row align-items-center justify-content-end ms-4 me-3 w-25">
                        <img
                            src={props.icon}
                            className="fa-icon-small"
                            alt="icon"
                        />
                    </div>
                    {props.text_2.length > 0 ?
                        <div className="w-50">
                            <h6 className="font-300 color-1">{props.text_1}</h6>
                            <h6 className="font-300 color-1">{props.text_2}</h6>
                        </div>
                        :
                        <div className="w-50">
                            <h6 className="font-300 color-1">{props.text_1}</h6>
                        </div>
                    }
                </div>
        )
    }

    return (
        <div>
            <h3 className="font-800 color-1 text-center w-100">Kontakt</h3>
            <div className="mt-4">
                <h5 className="font-700 color-1 text-center w-100">{contactData.name_of_priest}</h5>
                <h6 className="font-400 color-1 text-center w-100">farár</h6>
            </div>

            {massData ?
                <div>
                    <div className="fa-flex-col my-1">
                        <ContactItem icon={Images.PHONE_WHITE}
                                     text_1={contactData.priest_phone_number}
                                     text_2={""}/>
                        <ContactItem icon={Images.EMAIL_WHITE}
                                     text_1={contactData.email} text_2={""}/>
                        <ContactItem icon={Images.LOCATION_PIN_WHITE}
                                     text_1={contactData.address} text_2="Pečovská Nová Ves"/>
                    </div>
                    <div className="fa-flex-col align-items-center mb-2">
                        <div className="mt-4 mb-2">
                            <h5 className="font-700 color-1 text-center w-100">Dnešné sv. omše</h5>
                            {/*<h6 className="font-400 color-1 text-center w-100">{massData?.dayname}</h6>*/}
                        </div>

                        <div className="fa-flex-col w-75">
                            {massData?.mass?.map((item, index) => (
                                item.place?.length > 0 && item.time?.length > 0 &&
                                <div className="fa-flex-row justify-content-between w-100">
                                    <h6 className="font-400 color-1 text-start">{item.place === 'PNV' ? "Pečovská Nová Ves" : item.place}</h6>
                                    <h6 className="font-400-lato color-1 text-end">{item.time}</h6>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                :
                <div className="fa-flex-col my-4">
                    {/*<ContactItem icon={Images.OLD_TYPICAL_PHONE_WHITE}*/}
                    {/*             text_1={contactData.parish_phone_number} text_2={""}/>*/}
                    <ContactItem icon={Images.PHONE_WHITE}
                                 text_1={contactData.priest_phone_number}
                                 text_2={""}/>
                    <ContactItem icon={Images.EMAIL_WHITE}
                                 text_1={contactData.email} text_2={""}/>
                    <ContactItem icon={Images.LOCATION_PIN_WHITE}
                                 text_1={contactData.address} text_2="Pečovská Nová Ves"/>
                </div>
            }
        </div>
    );
};

export default ContactsTab;